import React from "react";
import styled from "styled-components";
import * as Colors from '../../styles/colors';
import {
  CardContainer,
  CardBodyContainer,
  CardBottom,
  CardIconContainer,
  CardOwnerContainer,
  CardOwnerDetails,
  OwnerDetail,
  circleUserIcon,
  circleUserIconSmall
} from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { Dimensions } from "react-native-web";

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Card = ({testimonial}) => {
  return (
    <CardContainer>
      <CardBodyContainer>
        {testimonial.body}
      </CardBodyContainer>
      <CardBottom>
        <CardOwnerContainer>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <FontAwesomeIcon
              icon={faCircleUser}
              size={smallScreen ? '2x' : '4x'}
              style={smallScreen ? circleUserIconSmall : circleUserIcon}
            />
            <CardOwnerDetails>
              {testimonial.name &&
                <OwnerDetail>
                  <b>{testimonial.name}</b>
                </OwnerDetail>
              }
              {testimonial.businessName &&
                <OwnerDetail>
                  {testimonial.businessName}
                </OwnerDetail>
              }
              {(testimonial.city || testimonial.state) &&
                <OwnerDetail>
                  {`${testimonial.city} ${testimonial.state}`}
                </OwnerDetail>
              }
            </CardOwnerDetails>
          </div>
        </CardOwnerContainer>
        <CardIconContainer>
          <FontAwesomeIcon
            icon={faQuoteRight}
            size={smallScreen ? '2x' : '5x'}
            style={{color: Colors.elRed}}
          />
        </CardIconContainer>
      </CardBottom>
    </CardContainer>
  )
}

export default Card;