import React, { useContext } from 'react';
import styled from 'styled-components';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  RegularText
} from './elements';
import FeaturedAsset from './FeaturedAsset';
import NewArrivals from './NewArrivals';
import Timer from '../Timer';
import { useQuery } from '@apollo/client';
import { NEXT_SALES, FEATURED_ASSETS, NEW_ARRIVALS } from './queries';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AuthContext from 'src/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Dimensions } from 'react-native-web';
import FinanceLink from '../FinanceLink';
import background from './featuredAssetBackground.png';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 1180;


export const LeftColumn = styled.div`
  display: flex;
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  background-size: 25%;

  @media (min-width: 768px) {
    flex: 0.5;
    max-width: 50%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 14px;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;


  @media (min-width: 768px) {
    flex: 0.45;
    max-width: 45%;
    height: fit-content;
  }

  @media (min-width: 1030px) {
    background-image: url(${background});
    background-repeat: no-repeat;
  }

`;

const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: flex-end;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;

  @media (min-width: 768px) {
    width: 100%;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 0px;
    flex-direction: row;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: 100%;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 50px;
    flex-direction: row;
    margin-left: 2%;

  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:landscape) {
    /* For portrait layouts only */
    width: 100%;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 50px;
    flex-direction: row;
    margin-left: -2%;
  }
`;

const SaleCounter = styled.div`
  margin: 5px;
  min-width: 15%;
  width: 15%;
  cursor: pointer;

  @media (max-width: 768px) {
    min-width: 90%;
    max-width: 90%;
    margin-left: 10%;
    margin-right: 10%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    min-width: 30%;
    overflow: auto;
  }
`;

const SaleCounterLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  min-height: 80px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 768px) {
    max-width: 100%;
    min-height: 20px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    min-width: 100%;
    font-size: 12px;
    font-weight: 500;
    min-height: 80px;
  }
`;

const HomeHeader = () => {
  const { data } = useQuery(NEXT_SALES);
  const { data: featuredData } = useQuery(FEATURED_ASSETS);
  const { data: newArrivalsData } = useQuery(NEW_ARRIVALS);
  let sales = data?.sales;
  let featuredAssets = featuredData?.assets;
  let newArrivals = newArrivalsData?.assets;
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleRedirect = (sale) => {
    if(isLoggedIn && sale.bidding) {
      navigate(`/bidding/${sale.id}`);
    }
    else if(isLoggedIn) {
      navigate(`/sales/${sale.id}`);
    }else {
      navigate(`/login`);
    }
  }

  return (
    <Container
      style={{
        // marginBottom: featuredAssets ? '0px' : '40px',
        marginBottom: '60px',
        paddingBottom: '20px'
      }}
    >
      {newArrivals && <NewArrivals assets={newArrivals} /> }
      <ColumnContainer>
        <LeftColumn>
          <TextContainer>
            <HeaderLabel fontSize='90px' >
              Upcoming Auctions Below
            </HeaderLabel>
            <RedSeparator />
            <RegularText>
              EquipLinc Auction Group is Full Service Heavy Truck & Equipment
              Auction Provider. Our team of professionals Remarkets Commercial
              Assets Nationwide to a Worldwide Audience. With over 85 years
              of Heavy Truck & Equipment Auction Experience we hope to serve
              you in all your Commercial Asset needs!
            </RegularText>
          </TextContainer>
        </LeftColumn>
        {featuredAssets?.length ?
          <RightColumn>
            {featuredAssets && <FeaturedAsset assets={featuredAssets} /> }
          </RightColumn> :
          null
        }
      </ColumnContainer>
      {sales?.length % 2 !== 0 || sales?.length === 0 || smallScreen ?
        <FinanceLink containerMargin='10px' /> :
        null
      }
      <CounterContainer>
        {data &&
          <>
            {/* {sales?.nextSale &&
              new Date(sales.nextSale.startAt) > new Date() ?
                <RegularText style={{fontWeight: 'bold'}}>
                  Next Auction Begins In...
                  <Timer
                    startDate={
                      sales.nextSale.startAt
                    }
                  />
                </RegularText> :
                <RegularText style={{fontWeight: 'bold'}}>
                  { sales?.nextSale && sales.nextSale.bidding ?
                    <>
                      Sale Happening
                      <Link
                        to={`/bidding/${sales.nextSale.id}`}
                        style={{textDecoration: 'none', cursor: 'pointer'}}
                      >
                        <Timer startDate={'now'} />
                      </Link>
                    </> :
                    null
                  }
                </RegularText>
            }
            {sales?.week && sales?.week?.startAt &&
              new Date(sales?.week.startAt) > new Date() ?
              <RegularText style={{fontWeight: 'bold'}}>
                Upcoming This Week
                <Timer startDate={sales.week.startAt} />
              </RegularText> :
              null
            }
            {sales?.month && sales?.month?.startAt ?
              <RegularText style={{fontWeight: 'bold'}}>
                Upcoming This Month
                <Timer startDate={sales.month.startAt} />
              </RegularText> :
              null
            } */}
            {sales?.map((sale, index) => {
              return (
                <>
                  <SaleCounter
                    onClick={() => handleRedirect(sale)}
                  >
                    <SaleCounterLabel>
                      {sale.name}
                    </SaleCounterLabel>
                    <div style={{maxWidth: smallScreen ? '90%' : 'fit-content'}}>
                      <Timer startDate={sale.startAt} />
                    </div>
                  </SaleCounter>
                  {sales?.length % 2 === 0 && index === (sales.length/2 - 1) &&
                    !smallScreen ?
                    <FinanceLink containerMargin='50px' /> :
                    null
                  }
                </>
              )
            })}
          </>
        }
      </CounterContainer>

    </Container>
  )
}

export default HomeHeader;
