import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../queries/withCount';

export const TESTIMONIAL_CORE_FIELDS = `
  fragment TestimonialCoreFields on Testimonial {
    id
    name
    businessName
    city
    state
    body
  }
`;

export const TESTIMONIALS = gql`
  ${TESTIMONIAL_CORE_FIELDS}
  query Testimonials {
    testimonials {
      ...TestimonialCoreFields
    }
  }
`;

export const TESTIMONIALS_WITH_COUNT = gql`
  query (
    ${WITH_COUNT_VARIABLES}
  ) {
    testimonialsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ${TESTIMONIAL_CORE_FIELDS}
      }
      count
    }
  }
`;

export const TESTIMONIAL = gql`
  ${TESTIMONIAL_CORE_FIELDS}
  query Testimonial($id: ID!) {
    testimonial(id: $id) {
      ...TestimonialCoreFields
    }
  }
`;
