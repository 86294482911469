import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { selectIsConnectedToRoom, useHMSStore } from '@100mslive/react-sdk';

import {
  START_BIDDING_VISIT,
  END_BIDDING_VISIT,
  BIDDING_VISIT_CHECK_IN,
} from './queries';

const Visit = ({ saleId, page }) => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [visitId, setVisitId] = useState();

  const [startBiddingVisit] = useMutation(START_BIDDING_VISIT, {
    variables: { saleId, page },
    onCompleted: data =>  setVisitId(data.startBiddingVisit.biddingVisit.id),
  });

  const [endBiddingVisit] = useMutation(END_BIDDING_VISIT, {
    variables: { id: visitId },
    onCompleted: () => setVisitId(null),
  });

  const [biddingVisitCheckIn] = useMutation(BIDDING_VISIT_CHECK_IN, {
    variables: { id: visitId, audio: isConnected }
  });

  useEffect(() => {
    const timer = setTimeout(startBiddingVisit, 2000);
    return () => {
      window.clearTimeout(timer);
      if (visitId) { endBiddingVisit(); }
    }
  }, []);

  useEffect(() => {
    if (visitId) {
      const interval = setInterval(biddingVisitCheckIn, 15000);
      return () => window.clearInterval(interval);
    }
  }, [visitId]);

  useEffect(() => {
    if (visitId) { biddingVisitCheckIn(); }
  }, [isConnected])

  useEffect(() => {
    if (visitId) {
      window.onunload = () => {
        if (visitId) { endBiddingVisit(); }
      };
    }
  }, [visitId]);

  return null;
};

export default React.memo(Visit);
