import React, { useState, useCallback, useEffect, ReactDOM } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { elGreen } from "src/styles/colors";

const Recaptcha = ({validated, setValidated}) => {
  const [checked, setChecked] = useState(false);
  const RecaptchaComponent = () => {

    let { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {

      if (!executeRecaptcha) {
        console.log('Recaptcha has not been loaded');
        return;
      }

      let token = undefined;

      if(!checked) {
        token = await executeRecaptcha();
        if(token) {
          setValidated(true);
        }
      }else {
        setValidated(false);
      }
      setChecked(!checked);
    }, [checked, executeRecaptcha]);

    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {validated ?
          <FontAwesomeIcon
            icon={faCheck}
            size='lg'
            style={{color: elGreen}}
          /> :
          <input
            value={validated}
            type="checkbox"
            checked={checked}
            onChange={() => handleReCaptchaVerify()}
          />
        }
        <div>I am not a robot</div>
      </div>
    )
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <RecaptchaComponent />
    </GoogleReCaptchaProvider>
  )
};

export default Recaptcha;