import styled from "styled-components";
import * as Colors from '../../styles/colors';

export const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-bottom: 10%;
  background-color: ${Colors.headerGray};
  margin-bottom: 60px;
`;

export const TestimonialGridContainer = styled.div`
  max-width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

export const TestimonialHeader = styled.div`
  font-size: 18px;
  font-weight: 900;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 1200px) {
    font-size: 70px;
  }
`;

export const RegularText = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const CardContainer = styled.div`
  padding: 5%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -10px 14px 34px 0px rgba(0, 0, 0, 0.07);
  min-width: 50%;
`;

export const CardBodyContainer = styled.div`
  margin-bottom: 5px;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const CardBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardOwnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardOwnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const CardIconContainer = styled.div`

`;

export const OwnerDetail = styled.div`
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
`;

export const circleUserIcon = {
  color: Colors.elRed,
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.5)',
  borderRadius: '50%/50%'
}

export const circleUserIconSmall = {
  color: Colors.elRed,
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.5)',
  borderRadius: '50%/50%',
  marginTop: '5px'
}