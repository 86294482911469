import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import currencyFormatter from 'src/utils/currencyFormatter';
import formatAddress from 'src/utils/addressFormatter';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCheck, faImage } from '@fortawesome/free-solid-svg-icons';
import { HashLink } from 'react-router-hash-link';
import { DateTime } from 'luxon';
import { preBidSale, activeSale } from 'src/utils/upcomingSale';
import TireDisplay from './TireDisplay';
import BrakeDisplay from './BrakeDisplay';
import ConditionDisplay from './ConditionDisplay';
import AssetHeader from '../Headers/AssetHeader';
import { Dimensions } from 'react-native-web';
import { Loading } from 'src/components/DataState';
import { numberWithDelimiter as delimited } from 'src/utils/stringHelpers';
import PreBid from './PreBid';
import VideoPlayer from './VideoPlayer';
import Watch from './Watch';
import Navigator from './Navigator';
import AuthContext from 'src/contexts/AuthContext';
import { REGISTER_FOR_SALE } from '../Sales/queries';
import FinanceLink from '../FinanceLink';

import { ASSET } from './queries';
import {
  AssetContainer,
  AssetContent,
  AssetDetails,
  AssetImage,
  AssetTop,
  AssetBottom,
  AssetAuctionDate,
  AssetBuyNowPrice,
  AssetButtonContainer,
  AssetLinkContainer,
  AssetLink,
  AssetSidebarDetails,
  AssetDetailRow,
  AssetDetailCloseRow,
  AssetDetailLabel,
  AssetDetail,
  AssetSpecifications,
  TireSection,
  BrakeSection
} from './elements';
import {
  NarrowButton,
  BoldTitle,
  Label,
  SectionHeader,
  SectionSubHeader,
  Section,
  RightColumn,
  LeftColumn,
  SectionLabel,
  SectionDetail,
  SectionDetails,
  FeaturesSection,
  Feature,
  UnevenSectionDetails
} from '../../elements';
import { elGray, elLightGray, elRed, iconGray } from 'src/styles/colors';
import { Link } from 'react-router-dom';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Carousel = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  max-height: 100px;
  margin-top: 10px;
  gap: 10px;

  @media(max-width: 768px) {
    gap: 5px;
    overflow-x: scroll;
    max-width: 90%;
  }

  @media(min-width: 768px) {
    flex-direction: row;
    overflow-x: scroll;
  }
`;

const MainPhoto = styled.div`
  align-items: center;
  text-align: center;

`;

const unSelectedPhoto = {
  flex: 1,
  maxWidth: smallScreen ? 25 : 100,
  maxHeight: smallScreen ? '75%' : '100%',
  marginTop: '10px',
  marginBottom: '10px'
}

const selectedPhoto = {
  ...unSelectedPhoto,
  marginTop: '5px',
  border: `5px solid ${elRed}`
}

const AssetName = styled.div`
  font-size: 20px;
  font-weight: 600;

  @media(min-width: 768px) {
    font-size: 50px;
    line-height: 69px;
  }
`;

const NullImage = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const unselectedVideoStyle = {
  flex: 1,
  maxWidth: smallScreen ? 25 : 100,
  maxHeight: smallScreen ? '75%' : '100%',
  marginTop: '10px',
  marginBottom: '10px',
  fontWeight: 'bold',
  padding: '27px 20px 27px 20px'
}

const selectedVideoStyle = {
  ...unselectedVideoStyle,
  border: `5px solid ${elRed}`,
  marginTop: '5px',
}

const NavigatorContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const Asset = ({props}) => {
  let {
    isLoggedIn, registeredSales, refetchCurrentUser, updateRegisteredSales
  } = useContext(AuthContext);
  const { id, soarrId, buyNow, saleId } = useParams();

  const { data, refetch } = useQuery(ASSET, {
    variables: { id, soarrId }
  });
  let currentSaleId = saleId;

  const [mediaIndex, setMediaIndex] = useState(0);
  const [engineSpecs, setEngineSpecs] = useState([]);
  const [suspensionSpecs, setSuspensionSpecs] = useState([]);
  const [drivetrainSpecs, setDrivetrainSpecs] = useState([]);
  const [wheelsSpecs, setWheelsSpecs] = useState([]);
  const [sleeperSpecs, setSleeperSpecs] = useState([]);
  const [boxSpecs, setBoxSpecs] = useState([]);
  const [otherSpecs, setOtherSpecs] = useState([]);
  const [showPreBid, setShowPreBid] = useState(false);
  const [showWatch, setShowWatch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [register] = useMutation(REGISTER_FOR_SALE, {
    onCompleted: data => {
      refetchCurrentUser();
      registeredSales = updateRegisteredSales();
    }
  });

  useEffect(() => {
    setEngineSpecs([]);
    setSuspensionSpecs([]);
    setDrivetrainSpecs([]);
    setWheelsSpecs([]);
    setSleeperSpecs([]);
    setBoxSpecs([]);
    setOtherSpecs([]);
  }, [location])

  if (!data) { return <Loading />; }
  if (!data.asset) {
    navigate('/inventory');
    return <Loading />;
  }

  const { asset } = {...data};
  const activeSaleAsset = asset.activeSaleAsset;
  const activeSaleId = parseInt(activeSaleAsset?.sale.id);
  let allMedia = [...asset?.photos, ...asset?.soarrPhotoUrls];

  // Don't display past sale info
  let saleOn = activeSaleAsset?.sale?.saleOn ?
    DateTime.fromISO(activeSaleAsset.sale.saleOn) : null;

  if (saleOn && saleOn < DateTime.now().minus({ days: 2 })) {
    saleOn = null;
  }

  const goToNextMedia = () => {
    if (mediaIndex >= allMedia.length - 1) {
      setMediaIndex(0);
    } else {
      setMediaIndex(mediaIndex + 1);
    }
  };

  if(asset?.soarrVideoUrls?.length > 0) {
    allMedia = [...allMedia, ...asset?.soarrVideoUrls]
  }

  const media = allMedia[mediaIndex];

  const generalDetails = {...asset.generalDetails}
  const details = {...asset.generalDetails?.unit};
  const tires = asset.tireDetails;
  const brakes = asset.brakeDetails;
  const condition = asset.conditionDetails;

  const mediaUrl = photo => {
    if ('object' === typeof photo) {
      return photo.url;
    } else {
      return photo;
    }
  };

  const videoOrPhoto = () => {
    if('object' === typeof media &&
      media['type'] && ['youtube'].includes(media['type'])
    ) {
      return <VideoPlayer video={media} />
    } else if(mediaUrl(media)){
      return (
        <MainPhoto onClick={goToNextMedia}>
          <img
            src={mediaUrl(media)}
            alt={asset?.name}
            style={{maxWidth: smallScreen ? '75%' : '100%'}}
          />
        </MainPhoto>
      )
    }else {
      return (
        <NullImage>
          <FontAwesomeIcon
            icon={faImage}
            size='2xl'
            style={{color: elRed}}
          />
        </NullImage>
      )
    }
  }

  return (
    <>
      <AssetHeader />
      <AssetContainer>
        <NavigatorContainer>
          <Navigator
            saleId={currentSaleId}
          />
        </NavigatorContainer>
        <FinanceLink containerMargin='10px' />
        <AssetContent>
          <AssetTop>
            <AssetImage>
              {videoOrPhoto()}
              <Carousel>
                {allMedia?.map((media, index) => (
                  <div
                    key={index}
                    onClick={() => setMediaIndex(index)}
                  >
                    {'object' === typeof media &&
                      media['type'] && ['youtube'].includes(media['type']) ?
                      <div
                        style={mediaIndex === index ?
                          selectedVideoStyle : unselectedVideoStyle
                        }
                      >
                        Video
                      </div> :
                      <img
                        src={mediaUrl(media)}
                        alt={asset?.name}
                        style={mediaIndex === index ? selectedPhoto : unSelectedPhoto}
                      />
                    }
                  </div>
                ))}
              </Carousel>
            </AssetImage>
            <AssetDetails>
              <AssetName>{asset?.name}</AssetName>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
              <AssetAuctionDate>
                <span
                  style={{
                    color: 'red',
                    marginLeft: smallScreen ? '0px' : '5px',
                    fontSize: smallScreen ? '18px' : '30px',
                    marginTop: smallScreen ? '0px' : '-4px',
                    fontWeight: 400
                  }}
                >
                  {getSaleInfo()}
                </span>
                </AssetAuctionDate>
                {buyNow === 'true' ?
                  <AssetBuyNowPrice>
                    <span
                      style={{
                        color: 'red',
                        fontSize: smallScreen ? '18px' : '30px',
                        marginTop: smallScreen ? '0px' : '-4px'
                      }}
                    >
                      {`BUY NOW PRICE: ${
                        currencyFormatter(parseFloat(asset.listPrice))
                      }`}
                    </span>
                  </AssetBuyNowPrice> :
                  null
                }
              </div>
              <AssetButtonContainer>
                <NarrowButton
                  to={buyNow ? `/contact/assetId=${asset.id}` : '/contact'}
                  contact={'true'}
                >
                  Contact Us
                </NarrowButton>
                {preBidSale(activeSaleAsset?.sale) && (
                  <NarrowButton
                    to={isLoggedIn ?
                      `/bidding/${activeSaleAsset.sale.id}` : "/login"
                    }
                    target={isLoggedIn ? "_self" : "_blank"}
                    onClick={e => {
                      if(isLoggedIn) {
                        e.preventDefault();
                        e.stopPropagation();

                        if(!registeredSales?.includes(activeSaleId)) {
                          register({variables: { id: activeSaleId }})
                        }

                        setShowPreBid(true);
                      }
                    }}
                  >
                    {`${!isLoggedIn || !registeredSales?.includes(activeSaleId) ?
                      'Click to Register to ' : ''} Enter Pre-Bid`
                    }
                  </NarrowButton>
                )}
                {activeSale(activeSaleAsset?.sale) && (
                  <NarrowButton
                    to={isLoggedIn ? `/bidding/${activeSaleAsset.sale.id}` : "/login"}
                    target={isLoggedIn ? "_self" : "_blank"}
                  >
                    {`${isLoggedIn ? '' : 'Click to Register to '} Enter Auction`}
                  </NarrowButton>
                )}
                {buyNow &&
                  <NarrowButton
                    to={`/contact/assetId=${asset.id}`}
                  >
                    Buy Now
                  </NarrowButton>
                }
                <NarrowButton
                  to={isLoggedIn ? "/inventory" : "/login"}
                  target="_blank"
                  style={asset.buyerUserAssetView?.watching ?
                    { backgroundColor: 'green', color: 'white' } :
                    {}
                  }
                  onClick={e => {
                    if(isLoggedIn) {
                      e.preventDefault();
                      e.stopPropagation();

                      if(!registeredSales?.includes(activeSaleId)) {
                        register({variables: { id: activeSaleId }})
                      }

                      setShowWatch(true);
                    }
                  }}
                >
                  {`${!isLoggedIn || !registeredSales?.includes(activeSaleId) ?
                    'Click to Register to ' : ''}${asset.buyerUserAssetView?.watching ?
                      'Watching' : 'Watch'
                    }`
                  }

                </NarrowButton>
              </AssetButtonContainer>
              {showPreBid && preBidSale(asset.activeSaleAsset?.sale) && (
                <PreBid
                  asset={asset}
                  setShow={setShowPreBid}
                  refetch={refetch}
                />
              )}
              {showWatch && (
                <Watch
                  asset={asset}
                  setShow={setShowWatch}
                  refetch={refetch}
                />
              )}
              <AssetLinkContainer>
                <BoldTitle>OVERVIEW</BoldTitle>
              </AssetLinkContainer>
              <AssetSidebarDetails>
                <AssetDetailRow>
                  <AssetDetailLabel>
                    {details ?
                      details.segment?.name || 'Type' :
                      `${asset.segment ? asset.segment : asset.category + ' '}Type`
                    }
                  </AssetDetailLabel>
                  <AssetDetail>
                    {asset.subCategory}
                  </AssetDetail>
                </AssetDetailRow>
                <AssetDetailRow>
                  <AssetDetailLabel>Stock#</AssetDetailLabel>
                  <AssetDetail>
                    {details ?
                      details['stockNumber'] :
                      asset.stockNumber
                    }
                  </AssetDetail>
                </AssetDetailRow>
                {asset.miles !== 0 && asset.miles && (
                  <AssetDetailRow>
                    <AssetDetailLabel>Odometer</AssetDetailLabel>
                    <AssetDetail>
                      {asset.miles ? `${delimited(asset.miles)} mi` : ''}
                    </AssetDetail>
                  </AssetDetailRow>
                )}
                {details && details['odometer'] && details['odometer']['ecm'] &&
                  Object.keys(details['odometer']['ecm']).length ?
                  <AssetDetailRow>
                    <AssetDetailLabel>ECM Reading</AssetDetailLabel>
                    <AssetDetail>
                      {details && details['odometer']['ecm']}
                    </AssetDetail>
                  </AssetDetailRow> :
                  null
                }
                {asset.vin ?
                  <AssetDetailRow>
                    <AssetDetailLabel>VIN</AssetDetailLabel>
                    <AssetDetail>
                      {asset.vin}
                    </AssetDetail>
                  </AssetDetailRow> :
                  null
                }
                {asset?.inspectionUrl ?
                  <AssetDetailRow>
                    <AssetDetailLabel>
                      Inspection:
                    </AssetDetailLabel>
                    <AssetDetail>
                      <a
                        href={asset?.inspectionUrl}
                        target="_blank"
                        rel="nofollow"
                      >
                        {asset?.inspectionUrl}
                      </a>
                    </AssetDetail>
                  </AssetDetailRow> :
                  null
                }
                {asset?.nisInspectionUrl ?
                  <AssetDetailRow>
                    <AssetDetailLabel>
                      Additional Inspections:
                    </AssetDetailLabel>
                    <AssetDetail>
                      <a
                        href={asset?.nisInspectionUrl}
                        target="_blank"
                        rel="nofollow"
                      >
                        View Additional Inspection
                      </a>
                    </AssetDetail>
                  </AssetDetailRow> :
                  null
                }
              </AssetSidebarDetails>
              <AssetLinkContainer>
                <BoldTitle>LOCATION DETAILS</BoldTitle>
                {/* <AssetLink
                  onClick={() => {}}
                >
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size='sm'
                    style={{color: iconGray, marginLeft: '5px'}}
                    onClick={() => {}}
                  />
                  Directions
                </AssetLink> */}
              </AssetLinkContainer>
              <AssetSidebarDetails>
                {(details || asset.location) &&
                  <>
                    <AssetDetailLabel>
                      {details && details['dealer'] ?
                        details['dealer']['name'] :
                        asset.location?.name
                      }
                    </AssetDetailLabel>
                    {
                      details ?
                        `This unit is located in ${details['physicalLocation']}` :
                        `This unit is located in ${asset.location?.city}, ${asset.location?.state}`
                    }
                    {asset.location?.phoneNumber || (details && details['dealer'] && details['dealer']['phone']) ?
                      <AssetDetailCloseRow style={{marginTop: '20px'}}>
                        Phone:
                        <span style={{marginLeft: '5px'}}>
                          {details && details['dealer'] ?
                            details['dealer']['phone'] :
                            asset.location?.phoneNumber?.formatted
                          }
                        </span>
                      </AssetDetailCloseRow> :
                      null
                    }
                    {asset.location ?
                      <AssetDetailCloseRow style={{marginTop: '5px'}}>
                        Directions:
                        <Link
                          to={`/locations`}
                          style={{textDecoration: 'none', cursor: 'pointer',
                            color: '#000', marginLeft: '5px'
                          }}
                        >
                          View Locations
                        </Link>
                      </AssetDetailCloseRow> :
                      null
                    }
                  </>
                }
                {(!details && !asset.location) &&
                  (asset.city && asset.state) &&
                  <AssetDetailRow>
                    {`This unit is located in ${[asset.city, asset.state].filter(Boolean).join(', ')}`}
                  </AssetDetailRow>
                }
              </AssetSidebarDetails>
            </AssetDetails>
          </AssetTop>
          <AssetBottom>
            <AssetSpecifications id="specs">
              {details && details['attributes'] && Object.keys(details['attributes']).length ?
                <>
                  <SectionHeader>
                    Specifications
                  </SectionHeader>
                  {checkInObject('Engine') &&
                    getSpecs('Engine')
                  }
                  {checkInObject('Suspension') &&
                    getSpecs('Suspension')
                  }
                  {checkInObject('Drivetrain') &&
                    getSpecs('Drivetrain')
                  }
                  {checkInObject('Wheels & Tires') &&
                    getSpecs('Wheels & Tires')
                  }
                  {checkInObject('Sleeper') &&
                    getSpecs('Sleeper')
                  }
                  {checkInObject('Box') &&
                    getSpecs('Box')
                  }
                  {checkInObject('Other') &&
                    getSpecs('Other')
                  }
                </> :
                null
              }
            </AssetSpecifications>
            <div
              style={{
                display: 'flex',
                flexDirection: smallScreen ? 'column' : 'row',
                flex: 1
              }}
            >
              <LeftColumn
                style={{minWidth: '50%', paddingLeft: '2%'}}
              >
                <SectionHeader>
                  Additional Features
                </SectionHeader>
                <FeaturesSection>
                  {generalDetails && generalDetails['unit'] && Object.keys(generalDetails['unit']['options']).length ?
                    generalDetails['unit']['options'].map(option => {
                      return (
                        <Feature key={option}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            size='sm'
                            style={{color: elRed, marginLeft: '5px',
                              marginRight:'10px'
                            }}
                            onClick={() => {}}
                          />
                          {option}
                        </Feature>
                      )
                    }) :
                    null
                  }
                </FeaturesSection>
              </LeftColumn>
              <RightColumn
                style={{
                  borderLeft: smallScreen ? 'none' : '1px solid black',
                  paddingLeft: '2%'
                }}
              >
                <SectionHeader>TIRES</SectionHeader>
                <TireSection>
                  <TireDisplay tires={tires} />
                </TireSection>
              </RightColumn>
            </div>
            <UnevenSectionDetails>
              <LeftColumn
                style={{lineHeight: '44px', backgroundColor: elLightGray,
                  marginTop: '50px', paddingLeft: '2%'
                }}
              >
                {asset.notes &&
                  <>
                    <SectionHeader>Additional Description</SectionHeader>
                    <Section
                      style={{ whiteSpace: 'pre-line', lineHeight: '32px' }}
                    >
                      {asset.notes}
                    </Section>
                  </>
                }
                {/* Video Section */}
                <Section>

                </Section>
              </LeftColumn>
              <RightColumn
                style={{lineHeight: '44px', backgroundColor: elLightGray,
                  marginTop: '50px', paddingLeft: '2%'
                }}
              >
                <SectionHeader>Brakes</SectionHeader>
                <BrakeSection>
                  <div className="brakes grid-x small-up-2">
                    <BrakeDisplay brakes={brakes} />
                  </div>
                </BrakeSection>
              </RightColumn>
            </UnevenSectionDetails>
            <SectionHeader>CONDITION INFORMATION</SectionHeader>
            <ConditionDisplay condition={condition} />
          </AssetBottom>
        </AssetContent>
      </AssetContainer>
    </>
  );

  function getSaleInfo() {
    if(saleOn) {
      const saleInfo = `SALE DATE ${saleOn.toFormat('D')}`;
      if (asset.lotNumber) {
        return `${saleInfo}  Run # ${asset.lotNumber}`;
      }
    }else {
      return 'SALE DATE TBA'
    }
  }

  function checkInObject(attribute) {
    if(!details || !details['attributes']) return false;
    let inObject = false;
    let keys = Object.keys(details['attributes']);
    switch(attribute) {
      case 'Engine':
        inObject = keys.includes('Engine Make' || 'Engine Model' ||
          'Engine Serial' || 'Engine HP' || 'Fuel Type' || 'Engine Brake' ||
          'Tank #1 Capacity' || 'Tank #2 Capacity'
        )
        if(inObject && !engineSpecs.length) {
          setSpecs(
            attribute,
            ['Engine Make', 'Engine Model', 'Engine Serial', 'Engine HP',
              'Fuel Type', 'Engine Brake','Tank #1 Capacity', 'Tank #2 Capacity']
          )
        }
        break;
      case 'Suspension':
        inObject = keys.includes('Suspension Type' || 'FA Capacity' ||
          'RA Capacity' || 'Suspension Make'
        )
        if(inObject && !suspensionSpecs.length) {
          setSpecs(
            attribute,
            ['Suspension Type', 'FA Capacity', 'RA Capacity', 'Suspension Make']
          )
        }
        break;
      case 'Drivetrain':
        inObject = keys.includes('Trans Make' || 'Trans Model' ||
          'Trans Speed' || 'Axle Configuration' || 'Rear End Ratio' ||
          'Wheelbase'
        )
        if(inObject && !drivetrainSpecs.length) {
          setSpecs(
            attribute,
            ['Trans Make', 'Trans Model', 'Trans Speed', 'Axle Configuration',
              'Rear End Ratio', 'Wheelbase']
          )
        }
        break;
      case 'Wheels & Tires':
        inObject = keys.includes('Front Tire Size' || 'Rear Tire Size' ||
          'Front Wheels' || 'Rear Wheels' || 'Brakes'
        )
        if(inObject && !wheelsSpecs.length) {
          setSpecs(
            attribute,
            ['Front Tire Size', 'Rear Tire Size', 'Front Wheels', 'Rear Wheels',
              'Brakes']
          )
        }
        break;
      case 'Box':
        inObject = keys.includes('Box Make' || 'Box Length' || 'Box Width' ||
          'Box Height' || 'Lift Gate' || 'Door Opening' || 'Floor Material')
        if(inObject && !boxSpecs.length) {
          setSpecs(
            attribute,
            ['Box Make', 'Box Length', 'Box Width', 'Box Height', 'Lift Gate',
              'Door Opening', 'Floor Material']
          )
        }
        break;
      case 'Sleeper':
        inObject = keys.includes('Sleeper Type' || 'Sleeper Size');
        if(inObject && !sleeperSpecs.length) {
          setSpecs(
            attribute,
            ['Sleeper Type', 'Sleeper Size']
          )
        }
        break;
      case 'Other':
        inObject = keys.includes('5th Wheel' || 'GVWR' || 'Fairings' ||
          'Frame Material' || 'Frame Type' || 'Color' || 'Seats' ||
          'Interior' || 'Interior Color' || 'Trans Type' || 'Crane Make' ||
          'Lift Gate Capacity' || 'Reefer Make' || 'Reefer Model' ||
          'Reefer Hours' || 'Reefer Serial' || '# Axles' || 'APU' || 'Length' ||
          'Width' || 'Paint Code' || 'Body Make' || 'Body Model' ||
          'Body Material' || 'Under CDL' || 'Engine Year' || 'Rear Doors'
        )
        if(inObject && !otherSpecs.length) {
          setSpecs(
            attribute,
            ['5th Wheel', 'GVWR', 'Fairings', 'Frame Material', 'Frame Type',
              'Color', 'Seats', 'Interior', 'Interior Color', 'Trans Type',
              'Crane Make', 'Lift Gate Capacity', 'Reefer Make', 'Reefer Model',
              'Reefer Hours', 'Reefer Serial', '# Axles', 'APU', 'Length',
              'Width', 'Paint Code', 'Body Make', 'Body Model', 'Body Material',
              'Under CDL', 'Engine Year', 'Rear Doors']
          )
        }
        break;
      default:
        break;
    }
    return inObject;
  }

  function setSpecs(attribute, specArray) {
    let specs = [];
    specArray.map(key => {
      if(key in details['attributes']) {
        specs.push({key: key, value: details['attributes'][key]})
      }
    });
    switch(attribute) {
      case 'Engine':
        setEngineSpecs(specs);
        break;
      case 'Suspension':
        setSuspensionSpecs(specs);
        break;
      case 'Drivetrain':
        setDrivetrainSpecs(specs);
        break;
      case 'Wheels & Tires':
        setWheelsSpecs(specs);
        break;
      case 'Box':
        setBoxSpecs(specs);
        break;
      case 'Sleeper':
        setSleeperSpecs(specs);
        break;
      case 'Other':
        setOtherSpecs(specs);
        break;
      default:
        break;
    }
  }

  function getSpecs(attribute) {
    let specs;
    switch(attribute) {
      case 'Engine':
        specs = engineSpecs;
        break;
      case 'Suspension':
        specs = suspensionSpecs;
        break;
      case 'Drivetrain':
        specs = drivetrainSpecs;
        break;
      case 'Wheels & Tires':
        specs = wheelsSpecs;
        break;
      case 'Box':
        specs = boxSpecs;
        break;
      case 'Sleeper':
        specs = sleeperSpecs;
        break;
      case 'Other':
        specs = otherSpecs;
        break;
      default:
        break;
    }

    return (
      <>
        <SectionSubHeader>
          {attribute}
        </SectionSubHeader>
        <Section>
          {specs.map(spec => {
            return (
              <SectionDetails key={spec.key}>
                <SectionLabel>{spec.key}</SectionLabel>
                <SectionDetail>
                  {spec.value}
                </SectionDetail>
              </SectionDetails>
            )
          })}
        </Section>
      </>
    )
  }
};

export default Asset;
