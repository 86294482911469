import React, { useContext, useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { HMSRoomProvider } from "@100mslive/react-sdk";


import AccountStatus from 'src/components/Account/Status';
import DataState from 'src/components/DataState';
import AuthContext from 'src/contexts/AuthContext';
import * as colors from 'src/styles/colors';

import {
  Container,
  ContainerWithFlash,
  BorderedContainer,
  Column,
  SmallColumn,
  Title,
  Subtitle,
  NoticeContainer,
  NoticeText,
  Split,
  BlackBackground
} from './elements';
import NewStream from './NewStream';
import Bids from './Bids';
import Flash from './Flash';
import SaleAssets from './SaleAssets';
import SaleAssetDetail from './SaleAssetDetail';
import Visit from './Visit';
import { SALE } from './queries';
import useSubscriptions from './useSubscriptions';
import { Dimensions } from 'react-native-web';
import Accept from '../Sales/Announcements/Accept';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1024;

const BiddingSale = () => {
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();
  useSubscriptions({ id });

  const [announcements, setAnnouncements] = useState(false);
  const [announcementAssetDetails, setAnnouncementAssetDetails] = useState(false);

  const { data } = useQuery(SALE, { variables: { id } });

  useEffect(() => {
    if(data && currentUser) {
      let newAnnouncements = [];
      if(data.sale.biddingSaleAsset && data.sale.biddingSaleAsset.asset.announcements) {
        let saleAsset = data.sale.biddingSaleAsset
        saleAsset.asset.announcements?.forEach(announcement => {
          const acceptances = announcement.acceptances?.map(acceptance => acceptance.buyerUser.id);
          if(!acceptances?.includes(currentUser.id)) {
            newAnnouncements = [...newAnnouncements, announcement];
          }
        });
        if(newAnnouncements?.length > 0) {
          setAnnouncementAssetDetails(`${saleAsset.lotNumber} | ${saleAsset.asset.name}`)
          setAnnouncements(newAnnouncements);
        }else {
          setAnnouncements(false);
        }
      }
    }

  }, [data, currentUser])

  if (!data) { return <DataState.Loading />; }

  const largeScreen = !(smallScreen || mediumScreen);

  return (
    <>

      <BlackBackground>
        <Flash sale={data.sale} />
        <Container
          style={{maxHeight: smallScreen || mediumScreen ? 'fit-content' : '640px'}}
        >
          <Column>
            <BorderedContainer
              style={{minHeight: smallScreen ? 'fit-content' : '340px'}}
            >
              <AccountStatus />
              <div
                style={{
                  display: 'flex',
                  flexDirection: smallScreen || mediumScreen ? 'column' : 'row',
                  gap: smallScreen || mediumScreen ? '20px' : '10px',
                }}
              >
                <Title>{data.sale.name}</Title>
                {data.sale.biddingNotice &&
                  <NoticeContainer
                    style={{
                      width: '50%',
                      minWidth: 300,
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      marginBottom: 20,
                      borderRadius: 20,
                    }}
                  >
                    <NoticeText>{data.sale.biddingNotice || ' '}</NoticeText>
                  </NoticeContainer>
                }
              </div>
              <View
                style={{
                  flexDirection: smallScreen || mediumScreen ? 'column' : 'row',
                  gap: 20,
                  justifyContent: smallScreen || mediumScreen ? null : 'space-between',
                  alignItems: 'baseline',
                }}
              >
                {data.sale.bidding ? (
                  <Subtitle
                    style={{
                      backgroundColor: `${colors.elGreen}`, color: '#fff',
                      paddingLeft: '5px', paddingRight: '5px'
                    }}
                  >
                    Sale is Active
                  </Subtitle>
                ) : (
                  <Subtitle
                    style={{
                      backgroundColor: `${colors.elRed}`, color: '#fff',
                      paddingLeft: '5px', paddingRight: '5px'
                    }}
                  >
                    Sale is Not Active
                  </Subtitle>
                )}

                <Subtitle>
                  {data.sale.lotsRemainingLabel}
                </Subtitle>

                <HMSRoomProvider>
                  <Visit saleId={id} page="Bidder" />
                  {data.sale.bidding && (
                    <NewStream
                      userName={
                        currentUser ?
                          `Buyer-${currentUser.id} - ${currentUser.fullName}` :
                          'Buyer - Guest'
                      }
                      roomCode={data.sale.viewerRoomCode}
                      activeStream={data.sale.activeStream}
                      sale={data.sale}
                    />
                  )}
                </HMSRoomProvider>
              </View>
              <Split />
              {announcements ?
                <Accept
                  announcements={announcements}
                  buyerUserId={currentUser.id}
                  setShowModal={setAnnouncements}
                /> :
                <Bids
                  saleAsset={data.sale.bidding && data.sale.biddingSaleAsset}
                  sale={data.sale}
                  smallScreen={smallScreen}
                />
              }
            </BorderedContainer>
            <BorderedContainer style={{marginTop: '10px'}}>
              {data.sale.biddingSaleAsset ? (
                <SaleAssetDetail
                  saleAsset={data.sale.biddingSaleAsset}
                  groupSaleAssets={data.sale.bidGroupSaleAssets}
                />
              ) : (
                // <BorderedContainer>
                  <Title>No Assets Current Bidding</Title>
                // </BorderedContainer>
              )}
            </BorderedContainer>
          </Column>
          <SmallColumn>
            <SaleAssets
              sale={data.sale}
            />
          </SmallColumn>
        </Container>
      </BlackBackground>
    </>
  );
};

export default BiddingSale;
