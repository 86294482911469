import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import { useMutation } from '@apollo/client';
import { Button, ButtonGroup, ButtonContainer } from 'src/elements/buttons';
import { ACCEPT } from './queries';
import { useForm } from 'react-hook-form';
import { elRed } from 'src/styles/colors';
import { Pressable } from 'react-native-web';
import Flash from './Flash';

const AnnouncementInputContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AnnouncementContainer = styled(View)`
  max-width: 65%;
`;

const Accept = ({ announcements, buyerUserId, setShowModal }) => {
  const [submitting, setSubmitting] = useState();

  const [acceptAnnouncement] = useMutation(ACCEPT, {
    onCompleted: data => {
      setShowModal(false);
    }
  });

  const onSubmit = useCallback(input => {
    setSubmitting(true);
    acceptAnnouncement({ variables: input });
  });

  const {
    control, register, handleSubmit, watch, formState: { errors }
  } = useForm({
    defaultValues: {
      announcementIds: announcements.map(announcement => announcement.id),
      buyerUserId: buyerUserId
    }
  });

  return (
    <View
      style={{
        border: `4px solid ${elRed}`,
        borderRadius: '5px',
        padding: '10px',
        width: '100%'
      }}
    >
      <Text style={{color: 'red', fontWeight: 'bold'}}>
        *Acknowledgement of this Announcement is required to bid on this lot.
      </Text>
      <AnnouncementInputContainer>
        <AnnouncementContainer>
          {
            announcements?.map(announcement => {
              return (
                <View
                  style={{
                    margin: '10px',
                  }}
                  key={`announcement-${announcement.id}-view`}
                >
                  <Text
                    key={`announcement-${announcement.id}`}
                    style={{
                      flexWrap: 'wrap',
                      maxWidth: '100%'
                    }}
                  >
                    {announcement.body}
                  </Text>
                </View>
              )
            })
          }
        </AnnouncementContainer>
        <ButtonContainer>
          <ButtonGroup>
            <Pressable
              onPress={handleSubmit(onSubmit)}
              style={{
                padding: '5px',
                backgroundColor: elRed,
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: '5px',
                width: 'fit-content',
                marginBottom: '10px',
                alignSelf: 'flex-end'
              }}
            >
              <Flash
                message='Acknowledge Announcement'
              />
            </Pressable>
          </ButtonGroup>
        </ButtonContainer>
      </AnnouncementInputContainer>
    </View>
  )
}

export default Accept;