import styled from "styled-components";
import * as Colors from '../../styles/colors';

export const ContactContainer = styled.div`
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 30px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

export const LeftColumn = styled.div`
  padding: 10px;
`;

export const Label = styled.div`
  font-weight: 600;
`;

export const Field = styled.div`
  margin-top: 10px;
  margin-left: -5px;
  input {
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  padding: 10px;
`;

export const ContactHeader = styled.h2`

`;

export const ContactSubHeader = styled.h3`

`;

export const HR = styled.div`
  height: 2px;
  background-color: ${Colors.elRed};
  margin-bottom: 30px;
`;

export const RedDetail = styled.div`
  color: ${Colors.elRed};
`;

export const AssetDetails = styled.div`
  float: right;
`;

