import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

export const BUYER_CORE_FIELDS = gql`
  fragment BuyerCoreFields on Buyer {
    id
    name
    companyName
    street
    city
    state
    postalCode { formatted, value }
    phoneNumber { formatted, value }
    email
    status
    isApproved
  }
`;

export const BUYER_USER_CORE_FIELDS = gql`
  fragment BuyerUserCoreFields on BuyerUser {
    id
    email
    firstName
    lastName
    fullName
    role
    paddleNumber
    registeredSales
  }
`;

export const BUYER_USER_FIELDS = gql`
  ${BUYER_CORE_FIELDS}
  ${BUYER_USER_CORE_FIELDS}
  fragment BuyerUserFields on BuyerUser {
    ...BuyerUserCoreFields
    policies {
      model
      action
    }
    buyer {
      ...BuyerCoreFields
    }
  }
`;

export const CURRENT_USER = gql`
  ${BUYER_USER_FIELDS}
  query CurrentUser {
    currentBuyerUser {
      ...BuyerUserFields
    }
  }
`;

const CREDENTIALS = `
  credentials {
    accessToken
    client
    expiry
    tokenType
    uid
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation BuyerUserUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $invitationToken: String!
  ) {
    buyerUserAcceptInvitation(
      password: $password
      passwordConfirmation: $passwordConfirmation
      invitationToken: $invitationToken
    ) {
      ${CREDENTIALS}
    }
  }
`;

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $password: String!
  ) {
    buyerUserLogin(
      email: $email
      password: $password
    ) {
      ${CREDENTIALS}
    }
  }
`;

export const SEND_PASSWORD_RESET = gql`
  mutation BuyerUserSendPasswordResetWithToken(
    $email: String!
  ) {
    buyerUserSendPasswordResetWithToken(
      email: $email
      redirectUrl: "https://www.equiplincauctions.net"
    ) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation BuyerUserUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    buyerUserUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      ${CREDENTIALS}
    }
  }
`;

export const CREATE_BUYER_USER = gql`
  mutation CreateBuyerUserWeb (
    $email: String!
    $firstName: String!
    $lastName: String!
    $companyName: String
  ) {
    createBuyerUserWeb (
      email: $email
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
    ) {
      buyerUser {
        id
      }
      ${ERRORS}
    }
  }
`;

export const BUYER_USER_STATUS = gql`
  query BuyerUserStatus(
    $email: String!
  ) {
    buyerUserStatus(
      email: $email
    )
  }
`;
