import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/native';

import AuthContext from 'src/contexts/AuthContext';
import { Loading } from 'src/components/DataState';
import { Text } from 'src/styles';
import { Button, Notice } from 'src/elements';
import { FormContainer, Fields, defaultValues } from 'src/elements/forms';

import AccountStatus from './Status';
import { UPDATE_CURRENT_BUYER_USER } from './queries';

export const FIELDS = {
  firstName: { rules: { required: true } },
  lastName: { rules: { required: true } },
};

const StaticField = styled(Text)`
  margin-bottom: 10px;
  font-weight: 600;
`;

const Profile = () => {
  const navigate = useNavigate();

  const {
    currentUser, isLoggedIn, checkedCredentials
  } = useContext(AuthContext);
  const [formStatus, setFormStatus] = useState();

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  useEffect(() => {
    if (currentUser) {
      reset(defaultValues({ fields: FIELDS, resource: currentUser }));
    }
  }, [currentUser]);

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_BUYER_USER, {
    onCompleted: () => setFormStatus('success')
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    updateCurrentUser({ variables: input });
  };

  if (!checkedCredentials) { return <Loading />; }

  if (!isLoggedIn) {
    navigate('/login');
    return null;
  }

  return (
    <View>
      <AccountStatus />
      <FormContainer onEnter={handleSubmit(onSubmit)} style={{width: '100%'}}>
        {'success' === formStatus && (
          <Notice type="success">Your Profile has been updated.</Notice>
        )}
        <Text>Profile</Text>
        <StaticField>Email: {currentUser?.email}</StaticField>
        {currentUser?.buyer.isApproved && (
          <StaticField>Paddle Number: {currentUser?.paddleNumber}</StaticField>
        )}
        <Fields fields={FIELDS} control={control} errors={errors} />
        {'submitting' === formStatus ? (
          <Loading />
        ) : (
          <Button
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        )}
      </FormContainer>
    </View>
  );
};

export default Profile;
