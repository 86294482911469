import { useContext } from 'react';
import { useSubscription } from '@apollo/client';
import isEqual from 'react-fast-compare';

import AuthContext from 'src/contexts/AuthContext';

import {
  BIDDING_SALE_ASSETS,
  BIDDING_SALE_ASSET_UPDATED,
  BIDDING_UPDATED,
  SALE,
  SALE_UPDATED,
} from './queries';

const useSubscriptions = ({ id }) => {
  const { apolloClient: client } = useContext(AuthContext);

  useSubscription(SALE_UPDATED, {
    variables: { id },
    onData: ({ data: { data } }) => {
      if (!data || !data.saleUpdated) return;

      client.writeQuery({
        query: SALE,
        variables: { id },
        data: { sale: data.saleUpdated.sale },
      });
    }
  });

  useSubscription(BIDDING_UPDATED, {
    variables: { saleId: id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.biddingUpdated) return;

      const updates = data.biddingUpdated;

      const { sale } = client.readQuery({ query: SALE, variables: { id } });
      if (!sale) { return; }

      if (updates.timestamp && sale.biddingUpdatedTimestamp) {
        if (updates.timestamp < sale.biddingUpdatedTimestamp) {
          return;
        }
      }

      const { biddingSaleAsset } = sale;
      const updatedSale = { ...sale }

      if (biddingSaleAsset && biddingSaleAsset.id === updates.saleAssetId) {
        const updatedSaleAsset = { ...biddingSaleAsset };

        if (updates.biddingNextUpdated) {
          updatedSaleAsset.biddingNext = updates.biddingNext;
          updatedSaleAsset.biddingNextManual = updates.biddingNextManual;
          updatedSaleAsset.biddingStep = updates.biddingStep;
        }

        if (updates.bidsUpdated) {
          updatedSaleAsset.bids = updates.bids;
          updatedSaleAsset.nextRemovedBid = updates.nextRemovedBid;
          updatedSaleAsset.highBid = updates.highBid;
        }

        if (updates.biddingStatusUpdated) {
          updatedSaleAsset.biddingStatus = updates.biddingStatus;
        }

        updatedSale.biddingSaleAsset = updatedSaleAsset;
      }

      if (updates.biddingFlashUpdated) {
        updatedSale.biddingFlash = updates.biddingFlash;
        updatedSale.biddingFlashBackgroundColor =
          updates.biddingFlashBackgroundColor;
        updatedSale.biddingFlashForegroundColor =
          updates.biddingFlashForegroundColor;
        updatedSale.biddingBuyerBackgroundColor =
          updates.biddingBuyerBackgroundColor;
        updatedSale.biddingBuyerForegroundColor =
          updates.biddingBuyerForegroundColor;
        updatedSale.biddingFlashFades = updates.biddingFlashFades;
        updatedSale.biddingFlashType = updates.biddingFlashType;
      }

      if (updates.biddingNoticeUpdated) {
        updatedSale.biddingNotice = updates.biddingNotice;
      }

      if (updates.audioStreamUpdated) {
        updatedSale.activeStream = updates.activeStream;
      }

      if (updates.timestamp) {
        updatedSale.biddingUpdatedTimestamp = updates.timestamp;
      }

      client.writeQuery({
        query: SALE,
        variables: { id },
        data: { sale: updatedSale },
      });
    }
  });

  useSubscription(BIDDING_SALE_ASSET_UPDATED, {
    variables: { saleId: id },
    onData: ({ data: { data } }) => {
      if (!data || !data.saleAssetUpdated) return;

      const updatedSaleAsset = data.saleAssetUpdated.saleAsset;
      const { saleAssets } = client.readQuery({
        query: BIDDING_SALE_ASSETS, variables: { saleId: id }
      });

      const updatedIndex = saleAssets.findIndex(saleAsset => (
        updatedSaleAsset.id === saleAsset.id
      ));

      if (updatedIndex) {
        if (isEqual(saleAssets[updatedIndex], updatedSaleAsset)) {
          return;
        }
      }

      let updatedSaleAssets = [
        ...saleAssets.filter(saleAsset => (
          updatedSaleAsset.id !== saleAsset.id
        ))
      ];

      if (!updatedSaleAsset.destroyed) {
        if (updatedIndex || 0 === updatedIndex) {
          updatedSaleAssets.splice(updatedIndex, 0, updatedSaleAsset);
        } else {
          updatedSaleAssets = [...updatedSaleAssets, updatedSaleAsset];
        }
      };

      client.writeQuery({
        query: BIDDING_SALE_ASSETS,
        variables: { saleId: id },
        data: { saleAssets: updatedSaleAssets },
      });
    }
  });
};

export default useSubscriptions;
