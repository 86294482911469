import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const CONTACT_SUBMISSION_FIELDS = `
  id,
  name,
  email,
  phoneNumber
  body
`

const CONTACT_SUBMISSION_FIELDS_AND_ERRORS = `
  contactSubmission {
    ${CONTACT_SUBMISSION_FIELDS}
  }
  ${ERRORS}
`;

export const CONTACT_SUBMISSION = gql`
  mutation CreateContactSubmission(
    $name: String
    $email: String!
    $phoneNumber: String
    $body: String
    $assetId: ID
    $sellerId: ID
  ) {
    createContactSubmission(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      body: $body
      assetId: $assetId
      sellerId: $sellerId
    ) {
      ${CONTACT_SUBMISSION_FIELDS_AND_ERRORS}
    }
  }
`;