import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import webStyled from 'styled-components';

import { Text } from 'src/styles';
import { Dimensions } from 'react-native-web';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1024;

const EmptyContainer = webStyled.div`
  display: flex;
  height: 40px;
`;

const Container = webStyled(EmptyContainer)`
  justify-content: center;
  align-items: center;
  background-color: ${props => (
    props.biddingBuyerBackgroundColor ||
    props.biddingFlashBackgroundColor
  )};

  @keyframes blink-then-fade {
    8% { opacity: 1; }
    16% { opacity: 0; }
    24% { opacity: 1; }
    ${props => props.biddingFlashFades ? '84% { opacity: 1; }' : ''}
    ${props => props.biddingFlashFades ? '100% { opacity: 0; }' : ''}
  }

  animation-duration: 6s;
  animation-name: blink-then-fade;

  opacity: ${props => props.biddingFlashFades ? '0' : '1'};
`;

const Message = styled(Text)`
  font-size: ${smallScreen ? '20px' : '26px'};
  color: ${props => props.color};
  font-weight: 600;
`;

const BiddingFlash = ({ sale }) => {
  if (!sale.biddingFlash) {
    return <EmptyContainer />;
  }

  return (
    <Container key={sale.biddingFlash} {...sale}>
      <Message
        color={(
          sale.biddingBuyerForegroundColor || sale.biddingFlashForegroundColor
        )}
      >
        {sale.biddingFlash}
      </Message>
    </Container>
  );
};

export default BiddingFlash;
