import React, { useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Loading } from 'src/components/DataState';
import { Text } from 'src/styles';
import { Button, Notice } from 'src/elements';
import { FormContainer, Fields, defaultValues } from 'src/elements/forms';

import { CREATE_SELLER } from './queries';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: stretch;
  column-gap: 20px;
`;

export const NAME_FIELDS = {
  firstName: {
    label: 'First Name',
    rules: { required: true },
    style: { minWidth: 300 },
  },
  lastName: {
    label: 'Last Name',
    rules: { required: true },
    style: { minWidth: 300 },
  }
};

const ADDRESS_FIELDS = {
  companyName: { label: 'Company Name (optional)' },
  street: { rules: { required: true } },
};

const CITY_STATE_FIELDS = {
  city: {
    rules: { required: true },
    style: { flex: 2, minWidth: 300 },
  },
  state: {
    rules: {
      required: true,
      maxLength: { value: 2, message: 'Use 2-character abbreviation' }
    },
    style: { flex: 1, minWidth: 100 },
  },
  postalCode: {
    rules: { required: true },
    style: { flex: 1, minWidth: 200 },
  },
};

const CONTACT_FIELDS = {
  phoneNumber: { rules: { required: true } },
  email: { label: 'Email', rules: { required: true } },
  signupComments: {
    type: 'textarea',
    label: 'Any Additional Information',
    hint: 'For example, what are you looking to sell?',
    inputProps: { width: '100%' }
  },
};

const FIELDS = {
  ...NAME_FIELDS, ...ADDRESS_FIELDS, ...CITY_STATE_FIELDS,
  ...CONTACT_FIELDS
};

const Sell = () => {
  const [formStatus, setFormStatus] = useState();

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const [createSeller] = useMutation(CREATE_SELLER, {
    onCompleted: data => {
      if (data.createSeller.seller) {
        setFormStatus('success');
      }
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    createSeller({ variables: input });
  };

  if ('success' === formStatus) {
    return (
      <View style={{ marginTop: 50, marginBottom: 50 }}>
        <Notice type="success">
          Thank you. We look forward to contacting you soon.
        </Notice>
      </View>
    );
  }

  return (
    <View>
      <FormContainer onEnter={handleSubmit(onSubmit)} style={{width: '100%'}}>
        <Text style={{ marginBottom: 20 }}>
          Want to sell your equipment? Fill out this form to get started!
        </Text>

        <Row>
          <Fields fields={NAME_FIELDS} control={control} errors={errors} />
        </Row>

        <Fields fields={ADDRESS_FIELDS} control={control} errors={errors} />

        <Row>
          <Fields
            fields={CITY_STATE_FIELDS}
            control={control}
            errors={errors}
          />
        </Row>

        <Fields fields={CONTACT_FIELDS} control={control} errors={errors} />

        {'submitting' === formStatus ? (
          <Loading />
        ) : (
          <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
        )}
      </FormContainer>
    </View>
  );
};

export default Sell;
