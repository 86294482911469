import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import Filter from 'src/elements/Filter';
import ReactPaginate from "react-paginate";
import styled from 'styled-components';
import '../../styles/styles.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Loading } from 'src/components/DataState';
import {
  CardsContainer,
} from './elements';
import Card from './Card';
import * as Colors from '../../styles/colors';
import InventoryHeader from '../Headers/InventoryHeader';
import ResourcesContext from 'src/contexts/ResourcesContext';
import FinanceLink from '../FinanceLink';

import { ASSETS, ASSETS_WITH_COUNT } from './queries';

const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  flex-wrap: nowrap;
  font-size: smaller;
  color: black;
`;

const CountContainer = styled.div`
  font-weight: 600;
  margin-left: 5%;
  margin-top: 20px;
`;

const InventoryLabel = styled.div`
  font-weight: 600;
  font-size: 30px;

  @media(min-width: 768px) {
    font-size: 70px;
    min-width: 50%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 40px;
  }
`;

const ButtonContainer = styled(Link)`
  background-color: #fff;
  width: fit-content;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 20px 50px 20px 50px;
  border-radius: 10px;
  border: 1px solid black;
  margin-top: 20px;
`;

const HomeIndexContainer = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media(min-width: 768) {
    flex-direction: row;
    margin-top: 60px;
    gap: 40px;
  }
`;

const Inventory = ({ buyNow, homeIndex, saleId, watching }) => {
  const {
    setCurrentAssets,
    clearAssets,
    inventoryFilterValues,
    setInventoryFilterValues,
    currentFilterPage,
    setCurrentFilterPage,
    currentAssets,
    currentItemOffset,
    setCurrentItemOffset
  } = useContext(ResourcesContext);
  const getScopes = () => {
    if(buyNow) {
      return ['list_priced', 'show_on_web', 'ordered_by_year'];
    }else if(watching) {
      return ['watching'];
    }else if(homeIndex) {
      return ['first_six'];
    }else if(saleId) {
      return ['show_on_web', 'order_by_lot_number'];
    }else {
      return ['show_on_web', 'ordered_by_year'];
    }
  }
  const location = useLocation();
  const [itemOffset, setItemOffset] = useState(
    currentItemOffset !== -1 ? currentItemOffset : 0
  );
  const [currentPage, setCurrentPage] = useState(
    currentFilterPage !== -1 ? currentFilterPage : 0
  );
  const [queryStarted, setQueryStarted] = useState(false);
  const [initialLoad, setIntialLoad] = useState(true);
  const [update, setUpdate] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [queryVariables, setQueryVariables] = useState(
    inventoryFilterValues !== {} ?
      inventoryFilterValues :
      {
        saleId: saleId,
        filterColumns: [],
        filterNames: [],
        scopes: getScopes(),
      }
  );

  if(!queryVariables['saleId'] && saleId) {
    queryVariables['saleId'] = saleId;
  }

  useEffect(() => {
    setQueryVariables({
      ...queryVariables,
      scopes: getScopes(),
    })
  }, [buyNow]);

  useEffect(() => {
    if(Object.keys(inventoryFilterValues).length > 0) {
      setQueryVariables(inventoryFilterValues);
    }
    if(currentFilterPage !== -1) {
      setCurrentPage(currentFilterPage);
    }
    if(currentItemOffset !== -1) {
      setItemOffset(currentItemOffset);
    }

  }, [location.pathname])

  const { data, loading, refetch } = useQuery(ASSETS_WITH_COUNT,
    {variables: queryVariables}
  );

  useEffect(() => {
    clearAssets();
    setIntialLoad(false);
    if(data) {
      setCurrentAssets(data?.assetsWithCount.entries);
    }
  }, [data])

  const items = data?.assetsWithCount.entries || [];

  const endOffset = itemOffset + 24;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / 24);

  const handlePageClick = (event) => {
    const newOffset = items?.length ? (event.selected * 24) % items.length : 24;
    setCurrentPage(items.length ? event.selected : 0);
    setItemOffset(newOffset);
    setCurrentItemOffset(newOffset);
    setCurrentFilterPage(items.length ? event.selected : 0);
  };

  useEffect(() => {
    if(filterChange) {
      setFilterChange(false);
      setItemOffset(0);
      setCurrentPage(0);
    }
  }, [queryVariables])

  return (
    <div style={{marginTop: homeIndex ? '-50px' : ''}}>
      {!saleId &&
        <InventoryHeader homeIndex={homeIndex} />
      }
      {!homeIndex &&
        <>
          <FinanceLink containerMargin='10px' />
          <div
            style={{marginLeft: '5%', marginTop: '30px', marginBottom: '10px'}}
          >
            Inventory Search
          </div>
        </>
      }
      <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: '#fff',
            padding: '10px',
            marginLeft: '5%',
            marginRight: '5%',
            paddingTop: '25px',
            borderRadius: '5px',
            boxShadow: '-1px 0px 15px -5px dimgray',
          }}
        >
          <Filter
            filterType={'Asset'}
            queryVariables={queryVariables}
            setQueryVariables={setQueryVariables}
            initialLoad={initialLoad}
            update={update}
            setUpdate={setUpdate}
            queryStarted={queryStarted}
            loading={loading}
            setFilterChange={setFilterChange}
            setInventoryFilterValues={setInventoryFilterValues}
          />
        </div>
      </div>
      {homeIndex &&
        <HomeIndexContainer>
          <InventoryLabel>
            Welcome To EquipLinc Auction Group
          </InventoryLabel>
          <div
            style={{
              display: 'flex', flexDirection: 'column', marginTop: '20px',
              fontWeight: '18px', lineHeight: '24px'
            }}
          >
            With over 85 years of experience by our management team in the
            Heavy Truck & Equipment Industry our primary function is to serve
            you, the customer in all aspects.
            <ButtonContainer
              to='/inventory'
              style={{cursor: 'pointer', textDecoration: 'none'}}
            >
              View More
            </ButtonContainer>
          </div>
        </HomeIndexContainer>
      }
      {data ? (
        <>
          <CountContainer
            style={{marginBottom: data.assetsWithCount.count > 0 ? 0 :'50px'}}
          >
            {`${data.assetsWithCount.count} Matches | Page ${currentPage + 1} of ${pageCount}`}
          </CountContainer>
          <CardsContainer>
            {currentItems.map(asset => {
              return (
                <div key={asset.id}>
                  <Card asset={asset} buyNow={buyNow} saleId={saleId} />
                </div>
              )
            })}
          </CardsContainer>
        </>
      ) : (
        <Loading />
      )}
      {!homeIndex &&
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <StyledReactPaginate
            breakLabel="..."
            nextLabel=""
            forcePage={currentPage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={4}
            pageCount={pageCount}
            previousLabel=""
            renderOnZeroPageCount={null}
            className="pagination-links"
            containerClassName = {"page-links-container"}
            activeLinkClassName='active-page'
          />
        </div>
      }
    </div>
  );
};

export default Inventory;
