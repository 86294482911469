import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { SELLER, UPDATE_SELLER } from './queries';
import { Loading } from '../DataState';
import moment from 'moment';
import currencyFormatter from 'src/utils/currencyFormatter';
import formatAddress from 'src/utils/addressFormatter';
import { Button } from 'src/elements/buttons';
import styled from 'styled-components';
import { elGreen, elRed } from 'src/styles/colors';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 20px 5% 20px 5%;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px 10px 0px;
`;

const DetailLabel = styled.div`
  font-weight: bold;
`;

const Detail = styled.div`
  margin-left: 20px;
`;

const SellerFee = styled.div`
  margin-top: 40px;
`;

const Banner = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
`;

const SellerActivated = styled(Banner)`
  background-color: ${elGreen};
`;

const Expired = styled(Banner)`
  background-color: ${elRed};
`;

const ContactLink = styled(Link)`
  text-decoration: none;
  color: #000;
  cursor: pointer;
  &:hover: { background-color: none };
`;


const FormatFee = ({seller}) => {
  return (
    <SellerFee>
      <DetailLabel style={{fontSize: '30px'}}>
        Seller Fee:
      </DetailLabel>
      <DetailContainer>
        <DetailLabel>{`${seller.feeType}:`}</DetailLabel>
        <Detail>
          {
            `${seller.feeType === 'Percent' ?
              parseFloat(seller.feeAmount).toFixed(0) + "%" :
              currencyFormatter(seller.feeAmount)
            }`
          }
        </Detail>
      </DetailContainer>
    </SellerFee>
  )
}

const Details = ({ seller }) => {
  const contact = seller.contacts[0];
  return (
    <>
      <DetailLabel style={{fontSize: '30px', marginTop: '20px', marginBottom: '20px'}}>
        Seller Details:
      </DetailLabel>
      <DetailContainer>
        <DetailLabel>
          Name:
        </DetailLabel>
        <Detail>
          {seller.name}
        </Detail>
      </DetailContainer>
      <DetailContainer>
        <DetailLabel>
          Company Name:
        </DetailLabel>
        <Detail>
          {seller.companyName}
        </Detail>
      </DetailContainer>
      <DetailContainer>
        <DetailLabel>
          Address:
        </DetailLabel>
        <Detail>
          {
            formatAddress(
              seller.street,
              seller.city,
              seller.state,
              seller.postalCode.formatted
            )
          }
        </Detail>
      </DetailContainer>
      <br />
      <DetailContainer>
        <DetailLabel>
          Contact:
        </DetailLabel>
      </DetailContainer>
      <DetailContainer>
        <DetailLabel>
          Contact Name:
        </DetailLabel>
        <Detail>
          {`${contact.firstName} ${contact.lastName}`}
        </Detail>
      </DetailContainer>
      <DetailContainer>
        <DetailLabel>
          Email:
        </DetailLabel>
        <Detail>
          {contact.email}
        </Detail>
      </DetailContainer>
      <DetailContainer>
        <DetailLabel>
          Phone Number:
        </DetailLabel>
        <Detail>
          {contact.phoneNumber.formatted}
        </Detail>
      </DetailContainer>
    </>
  )
}

const ActiveConfirmation = ({ seller, navigate }) => {
  const [updateSeller] = useMutation(UPDATE_SELLER, {
    onCompleted: () => {

    }
  });
  return (
    <>
      <Details seller={seller} />
      {seller.feeAmount && seller.feeAmount > 0 ?
        <FormatFee seller={seller} /> :
        null
      }
      {seller.status !== 'Active' ?
        <Button
          label="Confirm"
          style={{
            width: '200px',
            color: '#fff',
            backgroundColor: elGreen,
            borderRadius: '5px',
            marginTop: '20px',
            padding: '20px',
            textAlign: 'center',
          }}
          onPress={() => updateSeller({
            variables: {
              id: seller.id,
              status: 'Active'
            }
          })}
        /> :
        null
      }
    </>
  )
}

const ExpiredConfirmation = () => {
  return (
    <Expired>
      This approval has expired. Please contact us for assistance
    </Expired>
  )
}

const SellerActive = () => {
  return <SellerActivated>This account has been activated.</SellerActivated>
}

const ConfirmSeller = () => {
  const navigate = useNavigate()
  const { sellerId } = useParams();
  const { data } = useQuery(SELLER, { variables: { id: sellerId } });

  if(!data) return <Loading />
  const { seller } = data;
  return (
    <Container>
      {
        seller.status === 'Active' ?
          <SellerActive /> :
          null
      }
      {
        seller.confirmationExpiration > moment().unix() ?
          <ActiveConfirmation seller={seller} navigate={navigate} /> :
          <ExpiredConfirmation />
      }
      {/* <br />
      <b>Questions?</b>
      <ContactLink
        to="/contact"
        sellerId={sellerId}
        className='menuLink'
      >
        Click Here to Contact Us
      </ContactLink> */}
    </Container>
  )
}

export default ConfirmSeller;