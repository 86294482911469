import React from 'react';
import styled from 'styled-components';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  ImageContainer,
} from './elements';

const LeftColumn = styled.div`
  display: flex;

  @media(min-width: 768px) {
    background-size: 21%;
    width: 50%;
    background-image: url(${dotGrid});
    background-repeat: no-repeat;
  }
`;

const InventoryHeader = () => {
  return (
    <Container>
      <ColumnContainer>
        <LeftColumn>
          <TextContainer>
            <HeaderLabel>
              Inventory Detail
            </HeaderLabel>
            <RedSeparator />
          </TextContainer>
        </LeftColumn>
        <ImageContainer />
      </ColumnContainer>
    </Container>
  )
}

export default InventoryHeader;