import React from "react";
import AboutUsHeader from "../Headers/AboutUsHeader";
import styled from "styled-components";
import dotGrid from '../Headers/dot-grid.png';
import about from './about.png';
import Testimonials from "../Testimonials";
import CTA from "../CTA";
import { Dimensions } from "react-native-web";

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Container = styled.div`

`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-image: url(${dotGrid});
  background-size: 20%;
  background-repeat: no-repeat;

  @media(max-width: 768px) {
    padding-left: 2%;
    padding-right: 2%;
  }

  @media(min-width: 768px) {
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 60px;
    gap: 2.5%;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  @media(min-width: 768px) {
    justify-content: flex-end;
    padding-top: 50px;
    flex: 0.5;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  padding-right: 40px;
`;

const StyledHeader = styled.h2`
  font-size: 22px;

  @media(min-width: 768px) {
    font-size: 50px;
  }
`;

const RegularText = styled.div`
  @media(max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ULContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-top: -25px;
`;

const ULLeft = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`;

const ULRight = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`;

const About = () => {
  return (
    <Container>
      <AboutUsHeader />
      <ContentContainer>
        <LeftColumn>
         {/*
          <img src={about} alt='Equiplinc Locations'
            style={{
              maxWidth: smallScreen ? '50%' : '75%',
              maxHeight: '500px',
              width: 'auto',
              width: 'auto',
              objectFit: 'cover'
            }}
          />
          */}
        </LeftColumn>
        <RightColumn>
          <StyledHeader>About EquipLinc Auction Group</StyledHeader>
          <RegularText>
            EquipLinc Auction Group is a leading provider of remarketing
            services for heavy truck, equipment, and commercial asset businesses.
            With decades of experience in the Heavy Truck & Equipment Industry,
            our management team is dedicated to serving you, the customer, in
            all aspects.

            Our services include full remarketing of your commercial assets,
            transportation, storage, thorough inspection, title, mechanical,
            and reconditioning services, as well as asset acquisition and
            liquidation. We are committed to providing exceptional service and
            support to our customers, and we look forward to working with you.
          </RegularText>
          <StyledHeader>Services Included</StyledHeader>
          <RegularText>
            <ULContainer>
              <ULLeft>
                <ul className="about-us-ul">
                  <li>Full remarketing of your commercial assets</li>
                  <li>Transportation of commercial assets</li>
                  <li>Storage of your commercial assets</li>
                  <li>Thorough inspection services of your commercial assets</li>
                </ul>
              </ULLeft>
              <ULRight>
                <ul className="about-us-ul">
                  <li>Title services</li>
                  <li>Mechanical services</li>
                  <li>Reconditioning services</li>
                  <li>Asset acquisition</li>
                  <li>Asset liquidation</li>
                </ul>
              </ULRight>
            </ULContainer>
          </RegularText>
        </RightColumn>
      </ContentContainer>
      <Testimonials />
      <CTA />
    </Container>
  )
}

export default About;
