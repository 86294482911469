import React, { useState } from 'react';
import {
  BrakesContainer,
  BrakeContainer,
  Brake,
  AxleContainer
} from './elements';

const BrakeDisplay = ({ brakes }) => {
  if(!brakes || !brakes.length) {
    return 'There is no brake information currently available'
  };

  const axleGroups = brakes.reduce((axleGroups, item) => {
    const group = (axleGroups[item.axle] || []);
    group.push(item);
    axleGroups[item.axle] = group;
    return axleGroups;
  }, {});

  return (
    <BrakesContainer>
      {Object.keys(axleGroups).map(axleNumber => {
        return (
          <AxleContainer>
            <BrakeContainer>
              {axleGroups[parseInt(axleNumber)].map(brake => {
                return (
                    <Brake>
                      {brake.thickness}
                    </Brake>
                )
              })}
            </BrakeContainer>
          </AxleContainer>
        )
      })}
    </BrakesContainer>
  )
}

export default BrakeDisplay;