import React, { createContext, useState, useEffect, useContext } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../components/queries/withCount';
import AuthContext from './AuthContext';

const USER_FIELDS = `
  id
  fullName
  email
  title
`;

export const USERS_WITH_COUNT = gql`
  query (
    ${WITH_COUNT_VARIABLES}
  ) {
    usersWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ${USER_FIELDS}
      }
      count
    }
  }
`;

const ResourcesContext = createContext();

export const ResourcesProvider = ({ children }) => {
  const [webContacts, setWebContacts] = useState([]);
  const [currentAssets, setCurrentAssets] = useState([]);
  const [currentViewedAssetId, setCurrentViewedAssetId] = useState();
  const [inventoryFilterValues, setInventoryFilterValues] = useState({});
  const [currentFilterPage, setCurrentFilterPage] = useState(-1);
  const [currentItemOffset, setCurrentItemOffset] = useState(-1);

  const slug = asset => (
    asset.name.replace(/[^0-9a-z]+/gi, '-')
  );

  const getPreviousAsset = () => {
    if(!currentAssets.length) return null;

    const currentIndex = currentAssets.findIndex(
      asset => parseInt(asset.id) === parseInt(currentViewedAssetId)
    );
    let previousIndex = currentIndex === 0 ?
      currentAssets.length - 1 : currentIndex - 1;

    const previousAsset = currentAssets[previousIndex];

    if(previousAsset) {
      setCurrentViewedAssetId(previousAsset.id);
      return `${previousAsset.id}/${slug(previousAsset)}`
    }
  }

  const getNextAsset = () => {
    if(!currentAssets.length) return null;

    const currentIndex = currentAssets.findIndex(
      asset => parseInt(asset.id) === parseInt(currentViewedAssetId)
    );
    let nextIndex = currentIndex === currentAssets.length - 1 ?
      0 : currentIndex + 1;

    const nextAsset = currentAssets[nextIndex];

    if(nextAsset) {
      setCurrentViewedAssetId(nextAsset.id);
      return `${nextAsset.id}/${slug(nextAsset)}`
    }
  }

  const clearAssets = () => {
    setCurrentAssets([]);
    setCurrentViewedAssetId();
  }

  const value = {
    webContacts: webContacts,
    currentAssets: currentAssets,
    setCurrentAssets: setCurrentAssets,
    currentViewedAssetId: currentViewedAssetId,
    setCurrentViewedAssetId: setCurrentViewedAssetId,
    getPreviousAsset: getPreviousAsset,
    getNextAsset: getNextAsset,
    clearAssets: clearAssets,
    inventoryFilterValues: inventoryFilterValues,
    setInventoryFilterValues: setInventoryFilterValues,
    currentFilterPage: currentFilterPage,
    setCurrentFilterPage: setCurrentFilterPage,
    currentItemOffset: currentItemOffset,
    setCurrentItemOffset: setCurrentItemOffset,
  };

  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  );
};

export default ResourcesContext;
