import React from 'react';
import HomeHeader from '../Headers/HomeHeader';
import CTA from '../CTA';
import Testimonials from '../Testimonials';
import HomeAbout from './HomeAbout';
import Inventory from '../Inventory';

import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 100%;
`

const Home = () => {
  return (
    <Container>
      <HomeHeader />
      <Inventory homeIndex={true} />
      <HomeAbout />
      <Testimonials />
      <CTA />
    </Container>
  );
};

export default Home;
