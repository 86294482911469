import React, { useState, useContext, useEffect } from 'react';
import ResourcesContext from 'src/contexts/ResourcesContext';
import {
  NavigatorButtonContainer, NavigatorButton, SeparatorDiv
} from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faCircleLeft} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { SALE } from '../Sales/queries';
import { ASSETS_WITH_COUNT } from './queries';

const Navigator = ({ }) => {
  const navigate = useNavigate();
  const {
    currentAssets,
    setCurrentAssets,
    currentViewedAssetId,
    setCurrentViewedAssetId,
    getPreviousAsset,
    getNextAsset,
  } = useContext(ResourcesContext);
  const { saleId, id } = useParams();

  const getScopes = () => {
    if(saleId) {
      return ['show_on_web', 'order_by_lot_number'];
    }else {
      return ['show_on_web', 'ordered_by_year'];
    }
  }

  const [queryVariables, setQueryVariables] = useState(
    {
      saleId: saleId,
      filterColumns: [],
      filterNames: [],
      scopes: getScopes(),
    }
  );

  const [loadAssets, { data }] = useLazyQuery(ASSETS_WITH_COUNT,
    {variables: queryVariables}
  );
  useEffect(() => {
    if(!currentViewedAssetId) {
      setCurrentViewedAssetId(parseInt(id))
    }
    if(!currentAssets?.length) {
      loadAssets().then((response) => {
        setCurrentAssets(response?.data?.assetsWithCount?.entries);
      })
    }
  }, [])

  return (
    <NavigatorButtonContainer>
      <NavigatorButton
        onClick={() => {
          const relativePath = getPreviousAsset();
          if(relativePath) {
            navigate(`${saleId ? '/sales/' + saleId : ''}/inventory/${relativePath}`)
          }
        }}
      >
        <FontAwesomeIcon
          icon={faCircleLeft}
          title="Stop Audio"
          color="white"
          style={{marginRight: '15px'}}
        />
        Previous Asset
      </NavigatorButton>
      <SeparatorDiv />
      <NavigatorButton
        onClick={() => {
          const path = saleId ?
          `/sales/${saleId}` :
          `/inventory`
          navigate(path)
        }}
      >
        All Inventory
      </NavigatorButton>
      <SeparatorDiv />
      <NavigatorButton
        onClick={() => {
          const relativePath = getNextAsset();
          if(relativePath) {
            navigate(`${saleId ? '/sales/' + saleId : ''}/inventory/${relativePath}`)
          }
        }}
      >
        Next Asset
        <FontAwesomeIcon
          icon={faCircleRight}
          title="Stop Audio"
          color="white"
          style={{marginLeft: '15px'}}
        />
      </NavigatorButton>
    </NavigatorButtonContainer>
  )
}

export default Navigator;