import React, {useState} from 'react';
import { Dimensions, View, ScrollView, Switch, Pressable,
  SafeAreaView
} from 'react-native';
import {
  DefaultModal,
  CenteredView,
  ModalView,
  ModalContainer,
  ModalHeader,
  HeaderText,
  ModalContent,
  CancelX,
} from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const Modal = props => {
  const {
    showModal,
    setShowModal,
    modalType,
    children,
    objectName,
  } = props;
  const screenHeight = Dimensions.get('window').height - 100

  return (
    <ModalContainer
      // onPress={() => setShowModal(false)}
    >
      <DefaultModal
        visible={showModal}
        transparent={true}
      >
        <ModalView
          onClick={() => false}
        >
          <ModalHeader>
            <HeaderText>
              {`${modalType}`}{`${objectName ? ' - ' + objectName : ''}`}
            </HeaderText>
            <Pressable>
              <CancelX>
                <FontAwesomeIcon
                  icon={faSquareXmark}
                  size='lg'
                  style={{color: '#fff'}}
                  onClick={() => setShowModal(false)}
                />
              </CancelX>
            </Pressable>
          </ModalHeader>
          <ModalContent>
            <View style={{ height: "auto", maxHeight: screenHeight, flex: 1 }}>
              { children }
            </View>
          </ModalContent>
        </ModalView>
      </DefaultModal>
    </ModalContainer>
  )
}

export const WebContactsModal = props => {
  const {
    showModal,
    setShowModal,
    modalType,
    children,
    objectName,
  } = props;
  const screenHeight = Dimensions.get('window').height - 100

  const WebContactModal = styled.div`
    ...DefaultModal;
    width: 50%;
    align-self: center;
  `;

  return (
    <ModalContainer
      // onPress={() => setShowModal(false)}
    >
      <WebContactModal
        visible={showModal}
        transparent={true}
      >
        <ModalView
          onClick={() => false}
        >
          <ModalHeader>
            <HeaderText>
              {`${modalType}`}{`${objectName ? ' - ' + objectName : ''}`}
            </HeaderText>
            <Pressable>
              <CancelX>
                <FontAwesomeIcon
                  icon={faSquareXmark}
                  size='lg'
                  style={{color: '#fff'}}
                  onClick={() => setShowModal(false)}
                />
              </CancelX>
            </Pressable>
          </ModalHeader>
          <ModalContent>
            <View style={{ height: "auto", maxHeight: screenHeight, flex: 1 }}>
              { children }
            </View>
          </ModalContent>
        </ModalView>
      </WebContactModal>
    </ModalContainer>
  )
}

export default Modal;