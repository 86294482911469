import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { Dimensions, View, ScrollView, Switch } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faExclamationTriangle,
  faNote,
  faSms,
} from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';

import DataState from '../../components/DataState';
// import { useParams, Link } from 'app/src/utils/routing';
import { useParams } from 'react-router-dom';
import {
  Text,
  BoldText,
  ScrollSection,
  Toggles,
  ToggleText,
} from '../../styles';
import colors from '../../styles/colors';
import { RECORD_USER_ACTIVITY } from '../../hooks/useSubscriptions/queries';
import MessageInputContext from '../../contexts/MessageInputContext';
import useSubscriptions from './useSubscriptions';

import { CONVERSATION, CREATE_CONVERSATION } from './queries';
// import Header from './Header';
import Compose from './Compose';
import UserActivity from './UserActivity';

import ReactMarkdown from 'react-markdown';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Container = styled.div`
  flex-direction: ${smallScreen ? 'column' : 'row'};
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 10px;
  @media (min-width: 768px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  flex-direction: ${smallScreen ? 'column' : 'row'};
  width: 70%;

  @media (min-width: 768px) {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const Messages = styled.div`
  padding: 8px;
  padding-bottom: 16px;
  max-height: 150px;
  overflow: auto;
  width: 70%;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

const IncomingBody = styled.div`
  background-color: #e4e4f0;
  padding: 8px;
  width: 50%;
  border-radius: 6px;
`;

const OutgoingBody = styled(IncomingBody)`
  margin-right: 0px;
  margin-left: auto;
`;

const BodyLinks = styled(View)`
  flex-direction: row;
  margin-top: 8px;
`;

const IncomingInfo = styled(View)`
  margin-top: 10px;
  margin-bottom: 2px;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

const OutgoingInfo = styled(IncomingInfo)`
  justify-content: flex-end;
`;

const From = styled(Text)`
`;

const Time = styled(Text)`
`;

const DividerContainer = styled(View)`
  margin-top: 10px;
  flex-direction: row;
  column-gap: 10px;
`;

const Line = styled(View)`
  flex-grow: 1;
  margin-top: 8px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: gray;
`;

const Divider = ({ text }) => {
  return (
    <DividerContainer>
      <Line />
      <Text>{text}</Text>
      <Line />
    </DividerContainer>
  );
};

const Message = ({ conversation, message }) => {
  const formattedTime = DateTime.fromISO(message.createdAt).
    toLocaleString(DateTime.TIME_WITH_SECONDS);
  const bodyRef = useRef();

  let Info = IncomingInfo;
  let Body = IncomingBody;
  let fromName = message.userName ? message.userName : 'User';
  let backgroundColor = colors.elRed;
  let iconColor = backgroundColor;
  let color = '#ffffff';
  let icon = faCircle;
  let fontWeight = 'bold';

  if (message.isIncoming) {
    Info = OutgoingInfo;
    Body = OutgoingBody;
    fromName = message.userName ? message.userName : 'User';
    backgroundColor = colors.elGray;
    iconColor = '#e4e4f0';
    color = '#000000';
    fontWeight = 'normal';
  } else if ('note' === message.source) {
    color = '#ffffff';
    backgroundColor = '#d15454';
    icon = faNote;
    iconColor = colors.darkText;
  }

  return (
    <View>
      <Body style={{ backgroundColor }} ref={bodyRef}>
        <Text style={{ color, fontWeight }}>
          {getMessageBody(message)}
        </Text>
      </Body>
      <Info
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: message.isIncoming ? 'flex-end' : 'flex-start'
        }}
      >
        <From>{fromName}</From>
        <span style={{width: '5px'}}></span>
        <Time>{formattedTime}</Time>
      </Info>
    </View>
  );

  function getMessageBody(message) {
    return message.body
  }
};

const Conversation = () => {
  const conversationContext = useContext(MessageInputContext);
  const [id, setId] = useState(conversationContext?.currentConversationId);
  const [createCalled, setCreateCalled] = useState(false);
  useSubscriptions({id});

  const [getConversation, {data, refetch, error}] = useLazyQuery(
    CONVERSATION, { variables: { id } }
  );

  if (error && 'Conversation not found' === error.message) {
    localStorage.removeItem('conversationId');
    window.location.reload();
  }

  const [recordUserActivity] = useMutation(RECORD_USER_ACTIVITY);

  const [createConversation] = useMutation(CREATE_CONVERSATION, {
    onCompleted: (response) => {
      let conversationId = response.createConversation.conversation.id;
      localStorage.setItem('conversationId', conversationId)
      conversationContext.setCurrentConversationId(conversationId);
      setId(conversationId)
    }
  });

  useEffect(() => {
    if(id) {
      getConversation({variables: { id }})
      recordUserActivity({
        variables: { conversationId: id, activity: 'open' }
      });

      return () => {
        recordUserActivity({
          variables: { conversationId: id, activity: 'close' }
        });
      }
    }
    else {
      if(!createCalled) {
        setCreateCalled(true);
        createConversation();
      }
    }
  }, [id]);

  let activeDate;

  const isActive = (message) => (
    message.id === data?.conversation?.messages[data?.conversation?.messages.length - 1].id
  );

  const activeRef = useCallback(element => {
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  return (
    <Container>
      {!data?.conversation ?
        <DataState.Loading /> :
        <View style={{flex: 1}}>
          <ContentContainer smallScreen>
            <ChatContainer>
              <Messages>
                {data?.conversation.messages.map(message => {
                  const messageDate = DateTime.fromISO(message.createdAt).
                    toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

                  const newMessageDate = messageDate !== activeDate
                  activeDate = messageDate;

                  return (
                    <React.Fragment key={message.id}>
                      {newMessageDate && <Divider text={messageDate} />}
                      <Message
                        conversation={data?.conversation}
                        message={message}
                      />
                      <div ref={isActive(message) ?  activeRef : null}></div>
                    </React.Fragment>
                  );
                })}
              </Messages>
            </ChatContainer>
            <UserActivity conversation={data?.conversation} />
            <Compose
              conversation={data?.conversation}
              refetch={refetch}
              minimal
            />
          </ContentContainer>
        </View>
      }
    </Container>
  );
};

export default Conversation;
