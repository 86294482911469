import React from "react";
import styled from "styled-components";
import * as Colors from '../../styles/colors';
import {
  MainContainer,
  Container,
  Container1,
  Container2,
  Container3,
  Container4,
  RegularText,
  SocialIcons,
  ContainerLabel,
  LinkContainer,
  BottomContainer
} from "./elements";
import logo from './logo-white.png';
import facebook from './facebook.png';
import instagram from './instagram.png';
import twitter from './twitter.png';
import { Link } from 'react-router-dom';
import moment from "moment";

const imageStyle = {
  backgroundColor: '#000',
  marginBottom: '30px',
  maxWidth: '219px'
}

const linkStyle = {
  textDecoration: 'none',
  color: '#fff',
  marginTop: '10px',
  marginBottom: '10px'
}

const whiteText = {
  color: '#fff'
}

const redText = {
  color: Colors.elRed,
  marginLeft: '10px'
}

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
  marginBottom: '10px'
}

const Footer = () => {
  return (
    <MainContainer>
      <Container>
        <Container1>
          <img src={logo} style={imageStyle} />
          <RegularText>
            EquipLinc Auction Group offers a comprehensive range of remarketing
            services in the heavy truck, equipment, and commercial asset
            industries.
          </RegularText>
          <SocialIcons>
            <img src={facebook} />
            {/* <img src={instagram} />
            <img src={twitter} /> */}
          </SocialIcons>
        </Container1>
        <Container2>
          <ContainerLabel>
            Quick Links
          </ContainerLabel>
          <LinkContainer>
            <Link to="/about" style={linkStyle}>
              About
            </Link>
            <Link to="/auctions" style={linkStyle}>
              Auctions
            </Link>
            <Link to="/inventory" style={linkStyle}>
              Inventory
            </Link>
            <Link to="/locations" style={linkStyle}>
              Locations
            </Link>
          </LinkContainer>
        </Container2>
        <Container3>
          <ContainerLabel>
            Useful
          </ContainerLabel>
          <LinkContainer>
            <Link to="/" style={linkStyle}>
              Privacy Policy
            </Link>
            <Link to="/" style={linkStyle}>
              Terms & Conditions
            </Link>
          </LinkContainer>
        </Container3>
        <Container4>
          <ContainerLabel>
            Contact
          </ContainerLabel>
          <LinkContainer>
            <div style={containerStyle}>
              <span style={whiteText}>P:</span>
              <span style={redText}>(678) 788-0965 </span>
            </div>
            <div style={containerStyle}>
              <span style={whiteText}>E:</span>
              <span style={redText}>jbrinkley@equiplincauctions.com</span>
            </div>
            <div style={containerStyle}>
              <span style={whiteText}>L:</span>
              <span style={redText}>
                235 Millwork Industrial Blvd<br />Roanoke, AL 36274
              </span>
            </div>
          </LinkContainer>
        </Container4>
      </Container>
      <BottomContainer>
        {`© Copyright ${moment().year()} EquipLinc Auctions All Rights Reserved.`}
      </BottomContainer>
    </MainContainer>
  )
}

export default Footer;
