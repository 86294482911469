import React, { useContext, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Text, View, Pressable } from 'react-native';
import styled from 'styled-components/native';

import AuthContext from 'src/contexts/AuthContext';
import Routes from 'src/routes';
import Modal from './elements/Modal';
import Conversation from './components/Conversation';
import { CollapsedChat, ChatContainer, ChatModal } from './elements/Modal/elements';
import Header from './components/Headers/Header';
import Footer from './components/Footer';

const NewVersion = styled(View)`
  background-color: yellow;
`;

const NewVersionText = styled(Text)`
  color: black;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const NewVersionButtons = styled(View)`
  flex-direction: row;
  gap: 40px;
  justify-content: center;
`;

const NewVersionButton = styled(View)`
  border-width: 2px;
  border-style: solid;
  border-color: red;
  padding: 6px;
  background-color: white;
`;

const NewVersionLabel = styled(Text)`
  color: black;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const Main = () => {
  const [showConversationModal, setShowConversationModal] = useState(false);
  const {
    apolloClient,
    showNewVersion,
    setIgnoreVersion,
  } = useContext(AuthContext);

  const reloadPage = () => {
    window.location.href =
      window.location.href + `?reload=${new Date().getTime()}`;
  };

  return (
    <Router>
      <ApolloProvider client={apolloClient}>
        {showNewVersion && (
          <NewVersion>
            <NewVersionText>
              New updates are available.
            </NewVersionText>
            <NewVersionButtons>
              <Pressable onPress={reloadPage}>
                <NewVersionButton>
                  <NewVersionLabel>Reload Page</NewVersionLabel>
                </NewVersionButton>
              </Pressable>
              <Pressable
                onPress={() => setIgnoreVersion(new Date().getTime())}
              >
                <NewVersionButton>
                  <NewVersionLabel>Ignore</NewVersionLabel>
                </NewVersionButton>
              </Pressable>
            </NewVersionButtons>
          </NewVersion>
        )}
        <Header />
        <Routes />
        <Footer />
        <ChatContainer id='chat-container'>
          {!showConversationModal &&
            <CollapsedChat
              onClick={() => setShowConversationModal(true)}
            >
              Chat
            </CollapsedChat>
          }
          {showConversationModal &&
            <div id='chat-box'>
              <Modal
                showModal={showConversationModal}
                setShowModal={setShowConversationModal}
                modalType={'Chat'}
              >
                <Conversation
                  setShowModal={setShowConversationModal}
                />
              </Modal>
            </div>
          }
        </ChatContainer>
      </ApolloProvider>
    </Router>
  );
};

export default Main;
