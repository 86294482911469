import { gql } from '@apollo/client';

export const CREATE_SELLER = gql`
  mutation CreateSeller(
    $firstName: String!
    $lastName: String!
    $companyName: String
    $street: String!
    $city: String!
    $state: String!
    $postalCode: String!
    $phoneNumber: String!
    $email: String!
    $signupComments: String!
  ) {
    createSeller(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      street: $street
      city: $city
      state: $state
      postalCode: $postalCode
      phoneNumber: $phoneNumber
      email: $email
      signupComments: $signupComments
    ) {
      seller { id }
      errors { path, message }
    }
  }
`;
