import styled from "styled-components";
import { elRed, headerGray } from "src/styles/colors";

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  background-color: ${headerGray};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const RightColumn = styled.div`

`

export const DotGrid = styled.div`


`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12%;
  margin-top: 10px;
`;

export const RedSeparator = styled.div`
  width: 30%;
  max-width: 75px;
  height: 10px;
  border-radius: 5px;
  background-color: ${elRed};
  margin-top: -20px;
  margin-bottom: 40px;
`;

export const RegularText = styled.div`
  max-width: 100%;
  margin-right: 20px;
`;

export const HeaderLabel = styled.h1`
  font-size: 30px;
  font-weight: 900;

  @media (min-width: 768px) {
    font-size: ${props => props.fontSize ? props.fontSize : '60px' };
    width: 100%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 50px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:landscape) {
    /* For portrait layouts only */
    font-size: 50px !important;
  }
`;

export const ImageContainer = styled.div`

  @media(min-width: 768px) {
    width: 50%;
    float: right;
    position: relative;
  }
`;