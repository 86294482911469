export const elRed = '#EB1C22';
export const elGreen = '#006B00';
export const elGray = '#D9D9D9';
export const iconGray = '#828282';
export const elLightGray = '#F0F0F0';
export const elLightLightGray = '#F9F9F9';
export const mentionHighlight = '#d5eff7';
export const headerGray = '#F6F6F6';
export const backgroundGray = '#F1F1F1';

export const buttonBackground = elRed;
export const titleColor = elRed;
export const titleBackground = elGreen;
export const informationText = elRed;
export const textGray = elGray;
export const disabled = iconGray;

const colors = {
  elRed,
  elGreen,
  elGray,
  iconGray,
  elLightGray,
  elLightLightGray,
  headerGray,
  backgroundGray,
  mentionHighlight,
  buttonBackground,
  titleColor,
  titleBackground,
  informationText,
  textGray,
  disabled,
  mediumText: '#818181', // gray
  menuBackground: '#454545', // dark gray
  highlight: '#3b9bd7', // light blue
  mediumHighlight: '#3379a5', // medium blue
  darkHighlight: '#054974', // dark blue
  pageBackground: '#f5f5f5', // light gray
  receivedBackground: '#fafafa', // light gray
  darkText: '#3b3b3b', // dark gray
  lightText: '#b9b9b9', // light gray
};

colors.track = {
  false: colors.lightText, true: colors.lightText
};

export default colors;
