import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import AuthHeader from './AuthHeader';
import DataState from '../DataState';
import { formatAuthErrors } from '../../utils/errorHelpers';

import { RESET_PASSWORD } from './queries';
import { passwordSchema } from './schemas';
import {
  Container,
  InnerContainer,
  Title,
  Label,
  Input,
  Button,
  Instructions,
  ErrorMessage
} from './elements';

const ResetPassword = ({ token }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => window.location.replace('/'),
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormSubmitting(false);
    }
  });

  const handleSubmit = values => {
    setFormSubmitting(true);
    resetPassword({ variables: values });
  };

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Container>
        <AuthHeader />
        <InnerContainer>
          <Title>Reset Password</Title>
          <Instructions>
            Password must be a minimum of 8 characters
          </Instructions>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          {formSubmitting ? <DataState.Loading /> : (
            <Formik
              initialValues={{
                resetPasswordToken: token,
                password: '',
                passwordConfirmation: ''
              }}
              validationSchema={passwordSchema}
              onSubmit={handleSubmit}
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <Label>Enter new password</Label>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                  />

                  <Label>Confirm password</Label>
                  <Input
                    type="password"
                    name="passwordConfirmation"
                    placeholder="Confirm Password"
                  />

                  <Button type="submit">
                    Reset Password
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </InnerContainer>
      </Container>
    </>
  );
};

export default ResetPassword;
