import * as yup from 'yup';

export const loginSchema = yup.object({
  email: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

export const passwordSchema = yup.object({
  password: yup
    .string()
    .required('Required')
    .min(8, 'Password must have 8 or more characters'),
  passwordConfirmation: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});
