import Storage from 'react-native-storage';

const storage = new Storage({
  defaultExpires: null, // never
  enableCache: true,
  sync: {
    auth: () => ({ }),
    'remember-me': () => ({ email: '' }),
  },
  storageBackend: window.localStorage,
});

export default storage;
