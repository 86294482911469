import React, { useContext } from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components/native';
import webStyled from 'styled-components';
import { DateTime } from 'luxon';

import AccountStatus from 'src/components/Account/Status';
import { Loading } from 'src/components/DataState';
import { Text } from 'src/styles';
import { upcomingSale } from 'src/utils/upcomingSale';
import SalesHeader from '../Headers/SalesHeader';
import AuthContext from 'src/contexts/AuthContext';

import { SALES, REGISTER_FOR_SALE } from './queries';
import { elRed } from 'src/styles/colors';

const Container = styled(View)`
  width: 100%;
  height: 100vh;
`;

const SalesContainer = styled(View)`
  padding: 20px 5% 20px 5%;
`;

const SaleGroupHeader = styled(View)`
  font-size: 50px;
  margin-bottom: 30px;
  margin-top: 50px;
`;

const SaleGroup = styled(View)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 50px;
  flex-basis: 100%;
  max-width: 100%;
  flex-wrap: wrap;
`;

const SaleContainer = styled(View)`
  border-width: 1px;
  border-style: solid;
  border-color: black;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.22);
  min-height: 200px;
  display: flex;
  justify-content: space-around;
`;

const ButtonsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonContainer = styled(View)`
  display: flex;
  flex: 0.45;
  gap: 10px;
  text-align: center;
  padding: 10px 30px 10px 30px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 10px;
`;

const SaleButton = webStyled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: #000;
`;

const isUpcoming = sale => upcomingSale(sale);

const Sale = ({ sale }) => {
  let {
    isLoggedIn, registeredSales, refetchCurrentUser, updateRegisteredSales
  } = useContext(AuthContext);
  const [register] = useMutation(REGISTER_FOR_SALE, {
    onCompleted: data => {
      refetchCurrentUser();
      registeredSales = updateRegisteredSales();
    }
  });

  const buttonLabel = () => {
    let label = '';
    if(isLoggedIn && registeredSales?.includes(parseInt(sale.id))) {
      label += `Enter ${sale.name} Sale`;
    }else if(isLoggedIn && !registeredSales?.includes(parseInt(sale.id))) {
      label += `Register to Bid for the ${sale.name} Sale`;
    }else {
      label += 'Click to Register for Auction'
    }

    return label;
  }

  return (
    <SaleContainer>
      <h3>{sale.name}</h3>

      {isUpcoming(sale) ? (
        <View style={{flexDirection: 'column'}}>
          <Text style={{fontWeight: 600}}>
            LIVE bidding begins{' '}
            {DateTime.fromISO(sale.startAt).toFormat('LLL dd, h:mma')}
          </Text>
          <Text style={{fontWeight: 600, marginBottom: '10px'}}>
            Pre bidding available now.
          </Text>
          <ButtonsContainer>
            <ButtonContainer
              className='view-auction-button'
              style={{backgroundColor: elRed, color: '#fff'}}
            >
              <SaleButton
                to={isLoggedIn ? `/bidding/${sale.id}` : "/login"}
                target={isLoggedIn ? "_self" : "_blank"}
                style={{color: '#fff'}}
                onClick={() => register({variables: { id: sale.id }})}
              >
                {buttonLabel()}
                {/* {
                } */}
              </SaleButton>
            </ButtonContainer>
            <ButtonContainer>
              <SaleButton to={`/sales/${sale.id}`}>View Lots</SaleButton>
            </ButtonContainer>
          </ButtonsContainer>
        </View>
      ) : (
        <Text>Closed {sale.endAt}.</Text>
      )}
    </SaleContainer>
  );
};

const Sales = ({homeIndex}) => {
  const { data } = useQuery(SALES);

  if (!data) { return <Loading />; }

  const upcoming = data.sales.filter(sale => isUpcoming(sale));
  const complete = data.sales.filter(sale => !isUpcoming(sale));

  return (
    <>
      <SalesHeader />
      <SalesContainer>
        <SaleGroupHeader>Upcoming Auctions</SaleGroupHeader>
          {!homeIndex &&
            <AccountStatus />
          }
          <SaleGroup>
            {upcoming.map(sale => <Sale key={sale.id} sale={sale} />)}
          </SaleGroup>

          {/* {!homeIndex &&
            <>
              <SaleGroupHeader>Completed Auctions</SaleGroupHeader>
              <SaleGroup>
                {complete.map(sale => <Sale key={sale.id} sale={sale} />)}
              </SaleGroup>
            </>
          } */}
      </SalesContainer>
    </>
  );
};

export default Sales;
