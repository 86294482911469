import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import styled from 'styled-components/native';

import { Text } from 'src/styles';
import * as colors from 'src/styles/colors';
import { dollars } from 'src/utils/stringHelpers';
import AuthContext from 'src/contexts/AuthContext';

import {
  Title,
  Subtitle,
  Field,
  Label,
  Value,
  Split,
  StyledTextInput,
  Button,
} from './elements';
import { CREATE_BID } from './queries';

const SuggestionsContainer = styled(View)`
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 10px;
  gap: 10px;
`;

// font-weight: ${props => props.sameBuyer ? '700' : '400'};
const Bid = styled(Value)`
  background-color: ${props => props.latest ? colors.elRed : '#fff'};
  color: ${props => props.latest ? '#fff' : '#000'};
  padding: 12.5px;
  width: ${props => props.smallScreen ? '140px' : 'fit-content'};
  min-width: ${props => props.smallScreen ? '140px' : '220px'};
  border-radius: 7.5px;
  text-align: center;
  line-height: 18px;
  border: ${props => props.latest ? 'none' : '1px solid black'};
`;

const buyerDetails = ({ bid, masked }) => {
  const buyer = bid.buyer;

  if (buyer) {
    let paddleNumber = bid.paddleNumber;

    if (masked) {
      paddleNumber = bid.paddleNumber[0] + '***' +
        bid.paddleNumber[bid.paddleNumber.length - 1];
    }

    let details = paddleNumber;

    if (buyer.state) {
      details = `${details} - ${buyer.state.toUpperCase()}`;
    }

    return details;
  } else if (bid.paddleService) {
    return bid.paddleService.code;
  } else {
    return 'Floor';
  }
};

const Bids = ({ saleAsset, sale, smallScreen }) => {
  const { currentUser, isLoggedIn } = useContext(AuthContext);
  const [price, setPrice] = useState('');
  // const [note, setNote] = useState('');

  const [createBid] = useMutation(CREATE_BID, {
    onCompleted: () => {
      setPrice('');
      // setNote('');
    }
  });

  if (!saleAsset) {
    return <Text>No Active Bidding</Text>;
  }

  const enterBid = newPrice => {
    createBid({
      variables: { price: (newPrice || price), saleAssetId: saleAsset.id }
    });
  };

  const handleKeyPress = e => {
    if ('Enter' === e.nativeEvent.key) {
      if (price && parseFloat(price) > parseFloat(saleAsset.highBid)) {
        enterBid();
      }
    }
  };

  const bids = [...saleAsset.bids].filter(bid => !bid.removedAt).reverse();

  const highBid = bids[0]?.buyer && bids[0].buyer.id === currentUser?.buyer?.id;

  const sameBuyer = bid => (
    currentUser && bid.buyer?.id === currentUser?.buyer?.id
  );

  return (
    <View>
      {sale.bidGroupChoiceId && (
        <>
          <Title>Buyer Making Selections</Title>
          <Split />
        </>
      )}
      {isLoggedIn && currentUser?.buyer?.isApproved &&
        !sale.bidGroupChoiceId && (
        <>
          <Field>
            <Subtitle style={{alignSelf: 'center', marginRight: 100}}>
              {highBid ? 'You Have Current High Bid' : 'Enter Bid'}
            </Subtitle>
            <Button
              disabled={highBid}
              title={dollars(saleAsset.biddingNext)}
              onPress={() => enterBid(saleAsset.biddingNext)}
            />
          </Field>
          <Split />
        </>
      )}

      <Subtitle>Bids</Subtitle>
      <div
        style={{
          display: 'flex', flexDirection: 'row', gap: '3px', overflow: 'scroll'
        }}
      >
        {bids.map(bid => (
          <Bid
            key={bid.id}
            sameBuyer={sameBuyer(bid)}
            latest={bid === bids[0]}
            smallScreen={smallScreen}
          >
            <span style={{fontWeight: 700}}>
              {dollars(bid.price)}<br/>
            </span>
            <span style={{fontWeight: 400, display: 'flex', flexDirection: 'column'}}>
              {bid.note && `${bid.note}`}
              {buyerDetails({ bid, masked: !sameBuyer(bid) })}
            </span>
          </Bid>
        ))}
      </div>
    </View>
  );
};

export default Bids;
