import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { StockNumber } from '../Inventory/elements';
// import background from './featuredAssetBackground.png';
import currencyFormatter from 'src/utils/currencyFormatter';
import odometer from '../Inventory/odometer.png';
import engine from '../Inventory/engine.png';
import truck from '../Inventory/truck.png';
import DataState from 'src/components/DataState';
import * as Colors from 'src/styles/colors';
import { Link } from 'react-router-dom';
import { Dimensions } from 'react-native-web';
import moment from 'moment';
import { Text, View } from 'react-native';
import ResourcesContext from 'src/contexts/ResourcesContext';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1180;

const Container = styled.div`
  width: fit-content;
  color: #fff;
  padding: 5%;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    background-color: ${Colors.elRed};
    back
    margin-top: 15px;
    padding-bottom: 0;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 14px;
    background-color: ${Colors.elRed};

  }

`;

const HeaderLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;

  @media (min-width: 1030px) {
    font-size: 30px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 20px;

  @media(max-width: 768px) {
    margin-bottom: 20px;
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 14px;
    flex: 0.5;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex: 1;
  max-height: 14vmax;
  justify-content: center;

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 14px;
    flex: 0.5;
  }
`;

const AssetName = styled.div`
  font-weight: bold;
  font-size: 20px;

  @media (min-width: 768px) {
    font-size: 25px;
    padding-bottom: 5px;
  }
`;

const SalePrice = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: center;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 12px;
    width: 90%;
  }
`;

export const SpecsContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;

`;

export const SpecLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
`;

const iconStyle = {
  width: '30px',
  height: '20px',
  color: '#fff'
};

const imageStyle = {
  // height: smallScreen ? '175px' : '350px',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '20px'
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0.8;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 40px;
  gap: 20px;
  font-weight: bold;

  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-right: 20px;
  }
`;

const DetailsButton = styled(Link)`
  flex: 1;
  padding-top: 17.5px;
  padding-bottom: 17.5px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #fff;
`;

const BuyButton = styled(Link)`
  flex: 1;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  background-color: #fff;
  color: ${Colors.elRed};
  text-align: center;
  border-radius: 10px;

  @media (max-device-width: 430px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }
`;

const FeaturedAsset = ({assets}) => {
  const { clearAssets } = useContext(ResourcesContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  let asset = assets[currentIndex];

  useEffect(() => {
    if(assets) {
      let intervalId = setInterval(() => {
        setCurrentIndex(currentIndex < assets.length - 1 ? currentIndex + 1 : 0);
      }, 7500);

      return () => clearInterval(intervalId);
    }
  }, [currentIndex]);

  if(!asset) return null

  const slug = asset => (
    asset.name.replace(/[^0-9a-z]+/gi, '-')
  );

  return (
    <Container>
      <HeaderLabel>Featured Products</HeaderLabel>
      <ColumnContainer>
        <LeftColumn>
          <AssetName>
            {asset?.name}
          </AssetName>
          <StockNumber>
            {`STOCK# ${asset?.stockNumber}`}
          </StockNumber>
          {!smallScreen & !mediumScreen ?
            <>
              {asset?.showListPrice && asset.listPrice && asset.listPrice > 0 &&
                <SalePrice>
                  {asset?.listPrice && asset?.listPrice > 0 ?
                    `SALE PRICE: ${currencyFormatter(asset?.listPrice)}` :
                    null
                  }
                  <div style={{marginTop: '10px'}}>
                    {`CALL (678) 788-0965`}
                  </div>
                </SalePrice>
              }

              <SpecsContainer>
                {asset?.miles ?
                  <SpecLine>
                    <img src={odometer} style={iconStyle} />
                    <br />
                    Odometer:
                    <br />
                    <b>{`${asset?.miles}mi`}</b>
                  </SpecLine> :
                  null
                }
                {asset?.horsepower ?
                  <SpecLine>
                    <img src={engine} style={iconStyle} />
                    <br />
                    Engine HP:
                    <br />
                    <b>{asset?.horsepower}</b>
                  </SpecLine> :
                  null
                }
                {(asset?.segement || asset?.subCategory) && asset?.category ?
                  <SpecLine>
                    <img src={truck} style={iconStyle} />
                    <br />
                    Type:
                    <br />
                    <b>{asset?.category}</b>
                  </SpecLine> :
                  null
                }
              </SpecsContainer>
            </> : null
          }
        </LeftColumn>
        <RightColumn>
          {getImage()}
        </RightColumn>
      </ColumnContainer>
      {smallScreen || mediumScreen ?
        <>
          {asset?.showListPrice && asset.listPrice && asset.listPrice > 0 &&
            <SalePrice>
              {asset?.listPrice && asset?.listPrice > 0 ?
                `SALE PRICE: ${currencyFormatter(asset?.listPrice)}` :
                null
              }
              <div style={{marginTop: '10px'}}>
                {`CALL (678) 788-0965`}
              </div>
            </SalePrice>
          }
          <SpecsContainer>
            {asset?.miles ?
              <SpecLine>
                <img src={odometer} style={iconStyle} />
                <br />
                Odometer:
                <br />
                <b>{`${asset?.miles}mi`}</b>
              </SpecLine> :
              null
            }
            {asset?.horsepower ?
              <SpecLine>
                <img src={engine} style={iconStyle} />
                <br />
                Engine HP:
                <br />
                <b>{asset?.horsepower}</b>
              </SpecLine> :
              null
            }
            {(asset?.segement || asset?.subCategory) && asset?.category ?
              <SpecLine>
                <img src={truck} style={iconStyle} />
                <br />
                Type:
                <br />
                <b>{asset?.category}</b>
              </SpecLine> :
              null
            }
          </SpecsContainer>
        </> :
        null
      }
      <ButtonContainer>
        <DetailsButton
          // to={`/inventory/${asset?.id}/${slug(asset)}/false`}
          to={asset.activeSaleAsset ?
            `/sales/${asset.activeSaleAsset.sale.id}/inventory/${asset.id}/${slug(asset)}` :
            `/inventory/${asset.id}/${slug(asset)}`
          }
          style={{textDecoration: 'none', cursor: 'pointer'}}
          onClick={() => clearAssets()}
        >
          View Details
        </DetailsButton>
        {getBuyButton()}
      </ButtonContainer>
    </Container>
  )

  function getOptions() {
    let to = '';
    let label = '';
    let style = {textDecoration: 'none', cursor: 'pointer', border: '1px solid #fff', padding: '10px'}
    if(asset?.showListPrice) {
      to = `/contact/assetId=${asset.id}`;
      label = 'Buy Now';
    }else if(asset?.lotNumber) {
      let bidding = asset.activeSaleAsset?.bidding;
      to = `/${bidding ? 'bidding' : 'sales'}/${asset.activeSaleAsset.sale.id}`;
      label = [
        `Lot #: ${asset?.lotNumber}`,
        `Auction Date: ${moment(asset?.activeSaleAsset?.sale.saleOn).format('MM-DD-Y')}`
      ];
      style = {...style, fontSize: smallScreen || mediumScreen ? '12px' : '18px'}
    }else {
      to = '';
      label = 'Coming to a Future Auction';
      style = {textDecoration: 'none', cursor: 'auto'}
    }

    return {to: to, label: label, style: style}
  }

  function getImage() {
    let options = getOptions();
    return (
      <Link
        to={`/inventory/${asset?.id}/${slug(asset)}/${asset.showListPrice}`}
      >
        <img src={asset?.primaryPhotoUrl} style={imageStyle} />
      </Link>
    )
  }

  function getBuyButton() {
    let options = getOptions();
    return (
      <BuyButton
        to={options.to}
        style={options.style}
      >
        {Array.isArray(options.label) ?
          options.label.map(string => {
            return (
              <View key={`${string}-label`}>
                <Text style={{fontWeight: 'bold', textAlign: 'center'}}>
                  {string}
                </Text>
              </View>
            )
          }) :
          <View
            key={`${options.label}-label`}
            style={{paddingTop: '7.5px', paddingBottom: '7.5px'}}
          >
            <Text style={{fontWeight: 'bold', textAlign: 'center'}}>
              {options.label}
            </Text>
          </View>
        }
      </BuyButton>
    )
  }
}

export default FeaturedAsset;
