import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import styled from 'styled-components/native';
import { elRed } from 'src/styles/colors';

import { ErrorMessage } from './ErrorBoundary';

const ActivityContainer = styled(View)`
  margin-left: auto;
  margin-right: auto;
  padding-top: 6px;
`;

export const Loading = () => {
  return (
    <ActivityContainer>
      <ActivityIndicator size="large" color={elRed} />
    </ActivityContainer>
  )
};

export const Error = ({ error }) => {
  const message = error?.message || 'Unknown Error';

  return <ErrorMessage message={message} />;
};

const exports = { Loading, Error };
export default exports;
