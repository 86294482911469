import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import colors from 'src/styles/colors';

export const DefaultModal = styled.div`

`

export const CenteredView = styled.div`
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,0.5);
`

export const ModalView = styled.div`
width: 100%;
height: fit-content;
max-height: 500px;
background-color: white;
border-radius: 5;
border: 2px solid black;
elevation: 5;
cursor: default;
max-width: 100%;
shadow-color: #000;
box-shadow: 2px 2px 8px #000;

shadow-opacity: 0.5;
shadow-radius: 10;
z-index: 100;
display: flex !important;
flex-direction: column;
align-self: flex-end;
margin-bottom: 10px;
margin-left: -20px;
`;

export const ModalHeader = styled.div`
display: flex;
height: 25px;
flex-direction: row;
background-color: red;
padding-left: 15px;
padding-right: 10px;
margin-bottom: 20px;
justify-content: space-between;
`;

export const ModalContent = styled.div`
margin-left: 20px;
margin-right: 20px;
min-width: 600px;
`;

export const CancelX = styled.div`
font-weight: bold;
font-size: 20px;
color: white;
`;

export const BoldText = styled.div`
font-weight: bold;
`;

export const HeaderText = styled.div`
color: white;
font-size: 20px;
font-weight: bold;
`;

export const ModalContainer = styled.div`

`;

export const ChatContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 90%;

  @media (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: 60%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:landscape) {
    /* For portrait layouts only */
    width: 40%;
  }
`;

export const CollapsedChat = styled.div`
  width: 150px;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  shadow-color: #000;
  elevation: 5;
  shadow-offset: {
    width: 0,
    height: 2,
  }
  shadow-opacity: 0.75;
  shadow-radius: 5;
  box-shadow: 0px 1px 8px #000;
  background-color: ${colors.elRed};
  padding: 5px;
  bottom: 0;
  float: right;
  z-index: 1000 !important;
  margin-right: 20px;
  border: 1px solid black;
`;

export const ChatModal = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 500px;
  background-color: white;
  border-radius: 5;
  border: 2px solid black;
  elevation: 5;
  cursor: default;
  max-width: 100%;
  shadow-color: #000;
  box-shadow: 5px 5px 5px #000;
  shadow-offset: {
    width: 0,
    height: 2,
  }
  shadow-opacity: 0.75;
  shadow-radius: 5;
  z-index: 100;
  display: flex !important;
  align-self: flex-end;
  bottom: 10px;
  right: 20px;
`;