import React, { useContext } from 'react';
import {
  CardDate, CardTitle, CardContentContainer, MainPhotoContainer
} from './elements';
import moment from 'moment';
import { Tag, BlogButton } from './elements';
import { View, Text, Pressable } from 'react-native';
import BlogContext from 'src/contexts/BlogContext';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const slug = blog => {
  return (
    blog.title.replace(/[^0-9a-z]+/gi, '-')
  );
}

const Card = ({blog, refetch, fromShow}) => {
  const { setCurrentBlogTag, featuredLabel } = useContext(BlogContext);
  const navigate = useNavigate();

  const handleTagPress = (name) => {
    setCurrentBlogTag(name);
    navigate('/blog');
  }

  return (
    <>
      <MainPhotoContainer fromShow={fromShow}>
        {blog?.mainPhoto?.url ?
          <img src={blog.mainPhoto?.url}
            style={{
              width: '100%',
              maxHeight: '50vh',
              objectFit: 'contain'
            }}
          /> :
          null
        }
        {blog?.mainVideo?.url ?
          <video controls src={blog.mainVideo?.url}
            style={{
              width: '100%',
              maxHeight: '50vh',
              objectFit: 'contain'
            }}
          /> :
          null
        }
      </MainPhotoContainer>
      {fromShow ?
        <View style={{display: 'flex', flexDirection: 'row'}}>
          {blog?.tags?.map(tag => {
            return (
              <Pressable onPress={() => handleTagPress(tag.name)}>
                <Tag>
                  {tag.name}
                </Tag>
              </Pressable>
            )
          })}
        </View> :
        null
      }
      <View style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
        <CardTitle>{blog?.title}</CardTitle>
      </View>
      {/* <CardDate>{moment(blog?.createdAt).format('MMMM Do YYYY')}</CardDate> */}
      <CardContentContainer fromShow={fromShow}>
        <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
      </CardContentContainer>
      {!fromShow &&
        <Pressable onPress={() => navigate(`/blog/${blog?.id}/${slug(blog)}`)}>
          <BlogButton>Read More</BlogButton>
        </Pressable>
      }
    </>
  )
}

export default Card;