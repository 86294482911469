import React, { forwardRef, useState, useEffect } from 'react';
import { Switch } from 'react-native';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { Text, Hint } from '../styles';
import { elRed } from 'src/styles/colors';
import { abbreviations, statesList } from './states';
import moment from 'moment';
import Select from "react-select";
import colors from 'src/styles/colors';

export const StyledTextInput = styled.input`
  border-width: 1px;
  border-color: black;
  padding: 6px;
  background-color: #fff;
  height: 17px;
`;

export const StyledPicker = styled.select`
  border-color: black;
  padding: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
`;

const ErrorText = styled.div`
  padding-left: 10px;
  color: red;
`;

export const Field = styled.div`
  margin-bottom: 10px;
`;

export const SwitchField = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
`;

const SwitchLabel = styled.span`
  margin-left: 10px;
`;

const labelStyle = {
  fontSize: '12px',
}

export const FieldLabel = ({ name, label, hint, errors }) => {
  let errorMessage;

  if (errors?.[name]) {
    errorMessage = errors[name].message || 'Required';
  }

  return (
    <>
      <div className="field-label"
        style={{flexDirection: 'row', marginBottom: '5px', marginLeft: '5px'}}
      >
        <Text style={{color: elRed, fontWeight: 600}}>{label}</Text>
        {errorMessage && (
          <ErrorText className="field-error">{errorMessage}.</ErrorText>
        )}
      </div>
      {hint && <Hint>{hint}</Hint>}
    </>
  );
};

export const TextInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    placeholder,
    type,
    style,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field" style={style}>
      <div className="form-field-inner" style={{display: 'flex', flexDirection: 'column'}}>
        <FieldLabel name={props.name} label={label} hint={hint} errors={errors} style={labelStyle} />
        <Controller
          {...controllerProps}
          control={control}
          render={({ field }) => (
            <StyledTextInput
              {...field}
              ref={ref}
              type={type || 'text'}
              placeholder={placeholder}
              style={{marginLeft: '5px', marginTop: '-6px'}}
            />
          )}
        />
      </div>
    </Field>
  );
});

export const TextAreaInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    placeholder,
    secureTextEntry,
    rows,
    width,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <textarea
            {...field}
            rows={rows || 4}
            style={{width: width, fontFamily: 'sans-serif'}}
          />
        )}
      />
    </Field>
  );
});

export const CheckboxInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    checked,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
        <Controller
          {...controllerProps}
          control={control}
          render={({ field }) => (
            <input
              type='checkbox'
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </Field>
  );
});

export const PickerInput = props => {
  const {
    label,
    hint,
    control,
    errors,
    options,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <div style={{display: 'flex', flexDirection: 'column', color: elRed}}>
        <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
        <Controller
          {...controllerProps}
          control={control}
          render={({ field }) => (
            <StyledPicker
              {...field}
              style={{marginLeft: '5px', marginTop: '-6px'}}
            >
              <option
                key='picker-default'
                value=""
                label='Select'
              />
              {options?.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                  label={option.label}
                >
                  {option.label}
                </option>
              ))}
            </StyledPicker>
          )}
        />
      </div>
    </Field>
  );
};

export const StatePickerInput = props => {
  const {
    label,
    hint,
    control,
    errors,
    options,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <StyledPicker
            {...field}
          >
            <option
              value=""
              label="Select"
            >
              {'Select'}
            </option>
            {abbreviations.map(option => (
              <option
                key={option}
                value={option}
                label={option}
              >
                {option}
              </option>
            ))}
          </StyledPicker>
        )}
      />
    </Field>
  );
};

export const SwitchInput = props => {
  const {
    label,
    control,
    ...controllerProps
  } = props;

  return (
    <SwitchField>
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <Switch
            {...field}
            activeTrackColor={colors.lightButton}
            activeThumbColor={colors.button}
            onValueChange={field.onChange}
          />
        )}
      />
      <SwitchLabel>{label}</SwitchLabel>
    </SwitchField>
  );
};

export const FormattedPhoneInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    placeholder,
    secureTextEntry,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <input
            {...field}
            ref={ref}
            secureTextEntry={secureTextEntry}
            placeholder={placeholder}
            onChangeText={field.onChange}
          />
        )}
      />
    </Field>
    // const standardizedPhone = value => {
    //   if (value) {
    //     return '+1' + value.replace(/[-()/\s+/]/g, '');
    //   }

    //   return null;
    // };
  )
})

export const YearPickerInput = props => {
  const {
    label,
    hint,
    control,
    errors,
    options,
    watchMin,
    watchMax,
    ...controllerProps
  } = props;

  let currentYear = moment().format('YYYY');
  let years = ['Any'];
  // for(let x = 0; x < 100; x++) {
  [...Array(100).keys()].forEach(n => {
    if(!(watchMin && currentYear - n < watchMin) && !(watchMax && currentYear - n > watchMax)) {
      years.push(currentYear - n);
    }
  })

  return (
    <Field className="form-field">
      <div style={{display: 'flex', flexDirection: 'column', color: elRed}}>
        <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
        <Controller
          {...controllerProps}
          control={control}
          render={({ field }) => (
            <StyledPicker
              {...field}
              style={{marginLeft: '5px', marginTop: '-6px'}}
            >
              {years.map(option => (
                <option
                  key={`year${option}`}
                  value={'Any' === option ? '' : parseInt(option)}
                  label={option}
                >
                  {option}
                </option>
              ))}
            </StyledPicker>
          )}
        />
      </div>
    </Field>
  );
};

export const CurrencyInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    placeholder,
    secureTextEntry,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <div style={{marginTop: -6}}>
            <Text>$</Text>
            <StyledTextInput
              {...field}
              ref={ref}
              type='number'
              secureTextEntry={secureTextEntry}
              placeholder={placeholder}
            />
          </div>
        )}
      />
    </Field>
  );
});

export const NumberInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    placeholder,
    secureTextEntry,
    ...controllerProps
  } = props;

  return (
    <Field className="form-field">
      <FieldLabel name={props.name} label={label} hint={hint} errors={errors} />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <input
            {...field}
            ref={ref}
            type='number'
            secureTextEntry={secureTextEntry}
            placeholder={placeholder}
            onChangeText={field.onChange}
          />
        )}
      />
    </Field>
  );
});

const createGroup = (groupName, options, setLocalValue) => {
  return (
    {
      label: (() => {
        return (
          <div
            style={{fontWeight: 'bold', textAlign: 'center', fontSize: '18px'}}
            onClick={() =>
              setLocalValue(value =>
                value.concat(options.filter(grpOpt => !value.includes(grpOpt)))
              )
            }
          >
            {`---${groupName}---`}
          </div>
        )
      })(),
      options: options
    }
  );
};

export const StateMultiSelectInput = forwardRef((props, ref) => {
  const {
    label,
    hint,
    control,
    errors,
    placeholder,
    secureTextEntry,
    value,
    setValue,
    defaultValues,
    ...controllerProps
  } = props;

  const [localValue, setLocalValue] = useState([]);

  useEffect(() => {

    if(localValue.length > 0) {
      setValue('state', localValue.map(values => values.value))
    }
  }, [localValue])

  const mapped = Object.keys(statesList).map(key => {
    return {
      label: statesList[key]['label'],
      value: key,
      region: statesList[key]['region']
    }
  })

  useEffect(() => {
      let defaultSelected = []
      if(defaultValues?.length) {
        defaultSelected = defaultValues.map(key => {
          return {
            label: statesList[key]['label'],
            value: key,
            region: statesList[key]['region']
          }
        })
      }
    setLocalValue(defaultSelected);
  }, [])


  // const groupedOptions = Object.groupBy(mapped, ({
  //   region
  // }) => region);

  const groupedOptions = mapped.reduce((groups, state) => {
    const region = state.region;
    if (!groups[region]) {
      groups[region] = [];
    }
    groups[region].push(state);
    return groups;
  }, {});

  const keys = Object.keys(groupedOptions);

  let options = [
    createGroup(keys[0], groupedOptions[keys[0]], setLocalValue),
    createGroup(keys[1], groupedOptions[keys[1]], setLocalValue),
    createGroup(keys[2], groupedOptions[keys[2]], setLocalValue),
    createGroup(keys[3], groupedOptions[keys[3]], setLocalValue),
  ]

  return (
    <Field className="form-field">
      <FieldLabel name={props.name} label={label} hint={hint} errors={errors} style={labelStyle} />
      <Controller
        {...controllerProps}
        control={control}
        render={({ field }) => (
          <Select
            placeholder={`Select multiple states or leave blank to search all. You can click on a region to add all the states for that region.`}
            options={options}
            // value={value || []}
            value={localValue}
            onChange={value => {
              if(!value.length) setValue('state', [])
              return setLocalValue(value)
            }}
            isMulti
            closeMenuOnSelect={false}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: 'black',
                minHeight: '31px',
                height: '31px',
                borderRadius: 0,
                marginTop: '-6px',
                marginLeft: '5px',
                overflowX: 'auto',
                overflowY: 'auto'
              }),

              valueContainer: (provided, state) => ({
                ...provided,
                height: '31px',
                padding: '0 px',
                overflowX: 'unset',
                overflowY: 'unset'
              }),

              input: (provided, state) => ({
                ...provided,
                margin: '0px',
              }),
              indicatorSeparator: state => ({
                display: 'none',
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '31px',
                overflowX: 'auto',
                overflowY: 'auto'
              }),
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
          />
        )}
      />
    </Field>
  );
});

const Input = {
  Text: TextInput,
  TextArea: TextAreaInput,
  Picker: PickerInput,
  StatePicker: StatePickerInput,
  Switch: SwitchInput,
  Phone: FormattedPhoneInput,
  YearPicker: YearPickerInput,
  Checkbox: CheckboxInput,
  Currency: CurrencyInput,
  Number: NumberInput,
  StateMultiSelect: StateMultiSelectInput
};

export default Input;
