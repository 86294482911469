import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import logo from 'src/logo.avif';

const HeaderContainer = styled(View)`
  height: 78px;
  padding: 10px;
  background-color: #D9D9D9;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding-left: 5%;
    padding-right: 5%;

    > img {
      width: 90%;
    }
  }
`;

const LogoContainer = styled(View)`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const logoStyle = {
  height: '100%'
}

const AuthHeader = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={logo} alt="Equiplinc Auctions" style={logoStyle} />
      </LogoContainer>
    </HeaderContainer>
  )
}

export default AuthHeader;
