import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from './inputs';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { elRed } from 'src/styles/colors';

export const SELECT_OPTIONS = gql`
  query SelectOptions {
    selectOptions {
      assetCategories
      assetMakes
      assetModels
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex: 1;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const UpperRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 768px) {
    flex: 0.9;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    flex: 1;
    font-size: 12px;
  }
`;

const LowerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 20%;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: 100%;
    align-self: center;
  }
`

const MultiSelectInputContainer = styled.div`
  min-width: 50%;
  max-width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    overflow: noscroll;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: 100%;
    max-width: 100%;
  }
`

const Instructions = styled.div`
  color: ${elRed};
`;

const Filter = ({
  filterType, queryVariables, setQueryVariables, setFilterChange,
  setInventoryFilterValues
}) => {
  const [errorMessage, setErrorMessage] = useState();

  const { data } = useQuery(SELECT_OPTIONS);

  const {
    control, watch, setValue, formState: { errors }
  } = useForm({
    defaultValues: defaultValues()
  });


  watch(data => { updateFilter(data); });
  const watchState = watch('state');
  const watchYearMin = watch('yearMin');
  const watchYearMax = watch('yearMax');

  return (
    <>
      {getInputs(filterType)}
    </>
  )

  function getDefaultValues(column) {
    let columns = queryVariables?.filterColumns || [];
    let names = queryVariables?.filterNames || [];
    let colIndex = columns.indexOf(column);
    let value = undefined;
    if(colIndex >= -1) {
      value = names[colIndex];
    }

    if(column === 'category' && value === 'Tractor') {
      value = 'Semi-Truck'
    }
    if(column === 'state' && value?.length) {
      value = [...value];
    }
    if(column === 'category' && !value) {
      colIndex = columns.indexOf('subCategory');
      if(colIndex !== -1) {
        value = ` - ${names[colIndex] === 'Non-Sleeper' ? 'Day Cab' : names[colIndex]}`;
      }
    }

    return value !== undefined ? value : (column === 'state' ? [] : '')
  }

  function defaultValues() {
    if(filterType === 'Asset') {
      return (
        {
          category: getDefaultValues('category'),
          yearMin: getDefaultValues('yearMin'),
          yearMax: getDefaultValues('yearMax'),
          make: getDefaultValues('make'),
          model: getDefaultValues('model'),
          stockNumber: getDefaultValues('stockNumber'),
          subCategory: getDefaultValues('subCategory'),
          state: getDefaultValues('state')
        }
      )
    }
  }

  function getInputs() {
    let assetCategories = data?.selectOptions.assetCategories || [];
    assetCategories = assetCategories.filter(category => (
      !['TRACTOR', 'TRAILER'].includes(category.toUpperCase())
    ));

    assetCategories = [
      'Semi-Truck', ' - Day Cab', ' - Sleeper', 'Trailer', ...assetCategories
    ];

    if(filterType === 'Asset') {
      return (
        <FilterContainer id="filter-container">
          <RowContainer id="row-container">
            <UpperRow id="upper-row">
              <InputContainer>
                <Input.Picker
                  label="Category:"
                  name="category"
                  // defaultValue={getDefualtValue('category')}
                  control={control}
                  errors={errors}
                  options={assetCategories.map(option => {
                    return {value: option, label: option}
                  })}
                />
              </InputContainer>
              <InputContainer>
                <Input.YearPicker
                  label="Min Year:"
                  name="yearMin"
                  control={control}
                  errors={errors}
                  watchMax={watchYearMax}
                />
              </InputContainer>
              <InputContainer>
                <Input.YearPicker
                  label="Max Year:"
                  name="yearMax"
                  control={control}
                  errors={errors}
                  watchMin={watchYearMin}
                />
              </InputContainer>
              <InputContainer>
                <Input.Picker
                  label="Make:"
                  name="make"
                  control={control}
                  errors={errors}
                  options={data?.selectOptions.assetMakes.map(option => {
                    return {value: option, label: option}
                  })}
                />
              </InputContainer>
              <InputContainer>
                <Input.Picker
                  label="Model:"
                  name="model"
                  control={control}
                  errors={errors}
                  options={data?.selectOptions.assetModels.map(option => {
                    return {value: option, label: option}
                  })}
                />
              </InputContainer>
            </UpperRow>
            <LowerRow id="lower-row">
              <MultiSelectInputContainer>
                <Input.StateMultiSelect
                  label="State:"
                  name="state"
                  value={watchState}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  defaultValues={getDefaultValues('state')}
                />
              </MultiSelectInputContainer>
              <InputContainer>
                <Input.Text
                  label="Stock Number:"
                  name="stockNumber"
                  control={control}
                  errors={errors}
                />
              </InputContainer>
            </LowerRow>
          </RowContainer>
        </FilterContainer>
      )
    }
  }

  function updateFilter(watchAllFields) {
    if(filterType === 'Asset') {
      let filterColumns = [];
      let filterNames = [];
      let keys = Object.keys(watchAllFields);

      keys.map(key => {
        if ('category' === key && watchAllFields[key] !== "") {
          if ('Semi-Truck' === watchAllFields[key]) {
            filterColumns.push(key);
            filterNames.push('Tractor')
          } else if (' - Day Cab' === watchAllFields[key]) {
            filterColumns.push('subCategory');
            filterNames.push('Non-Sleeper');
          } else if (' - Sleeper' === watchAllFields[key]) {
            filterColumns.push('subCategory');
            filterNames.push('Sleeper');
          } else {
            filterColumns.push(key);
            filterNames.push(watchAllFields[key])
          }
        }else {
          if(watchAllFields[key] !== "") {
            filterColumns.push(key);
            filterNames.push(watchAllFields[key])
          }
        }
      })

      let newQueryVariables = {
        ...queryVariables,
        filterColumns: filterColumns,
        filterNames: filterNames
      }
      setFilterChange(true);
      setInventoryFilterValues(newQueryVariables);
      setQueryVariables(newQueryVariables);
    }
  }
}

export default Filter;
