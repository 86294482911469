import { gql } from '@apollo/client';

export const WITH_COUNT_VARIABLES = `
  $offset: Int
  $limit: Int
  $sort: String
  $sortDirection: String
  $search: String
  $filterColumns: [String!],
  $filterNames: [JSON!],
  $scopes: [String!]
`;

export const WITH_COUNT_ARGUMENTS = `
  offset: $offset
  limit: $limit
  sort: $sort
  sortDirection: $sortDirection
  search: $search
  filterColumns: $filterColumns
  filterNames: $filterNames
  scopes: $scopes
`;

const ERRORS = `errors { path, message }`;

const BLOG_CORE_FIELDS = gql`
  fragment BlogCoreFields on Blog {
    id
    title
    content
    createdAt
    createdByName
    featured
    mainPhoto {
      filename
      url
    }
    mainVideo {
      filename
      url
    }
    images {
      id
      filename
      url
    }
    tags {
      id
      name
    }
  }
`;

export const BLOG_FIELDS = gql`
  ${BLOG_CORE_FIELDS}
  fragment BlogFields on Blog {
    ...BlogCoreFields
  }
`;

const TAG_CORE_FIELDS = gql`
  fragment TagCoreFields on Tag {
    id
    name
  }
`;

export const TAG_FIELDS = gql`
  ${TAG_CORE_FIELDS}
  fragment TagFields on Tag {
    ...TagCoreFields
  }
`;

export const BLOG = gql`
  ${BLOG_FIELDS}
  query Blog($id: ID!) {
    blog(id: $id) {
      ...BlogFields
    }
  }
`;

export const BLOGS = gql`
  ${BLOG_FIELDS}
  query Blogs {
    blogs {
      ...BlogFields
    }
  }
`;

const BLOG_ARGUMENTS = `
  $title: String!
  $mainPhoto: Upload!
  $content: String!
  $tagIds: [ID!]
  $featured: Boolean
`;

const BLOG_VARIABLES = `
  title: $title
  mainPhoto: $mainPhoto
  content: $content
  tagIds: $tagIds
  featured: $featured
`;

export const CREATE_BLOG = gql`
  ${BLOG_CORE_FIELDS}
  mutation CreateBlog (
    ${BLOG_ARGUMENTS}
  ) {
    createBlog (
      ${BLOG_VARIABLES}
    ) {
      blog {
        ...BlogCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_BLOG = gql`
  ${BLOG_CORE_FIELDS}
  mutation UpdateBlog (
    $id: ID!
    ${BLOG_ARGUMENTS}
  ) {
    updateBlog (
      id: $id
      ${BLOG_VARIABLES}
    ) {
      blog {
        ...BlogCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const BLOGS_WITH_COUNT = gql`
  ${BLOG_FIELDS}
  query (
    ${WITH_COUNT_VARIABLES}
  ) {
    blogsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...BlogFields
      }
      count
      featuredLabel
      tags { name }
    }
  }
`;

export const UPLOAD_BLOG_IMAGE = gql`
  mutation($image: Upload!) {
    uploadBlogImage(image: $image) {
      url
      errors
    }
  }
`;



export const TAGS_WITH_COUNT = gql`
  ${TAG_FIELDS}
  query (
    ${WITH_COUNT_VARIABLES}
  ) {
    tagsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ...TagFields
      }
      count
    }
  }
`;

const TAG_ARGUMENTS = `
  $name: String!
`;

const TAG_VARIABLES = `
  name: $name
`;

export const CREATE_TAG = gql`
  ${TAG_CORE_FIELDS}
  mutation CreateTag (
    ${TAG_ARGUMENTS}
  ) {
    createTag (
      ${TAG_VARIABLES}
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_TAG = gql`
  ${TAG_CORE_FIELDS}
  mutation UpdateTag (
    $id: ID!
    ${TAG_ARGUMENTS}
  ) {
    updateTag (
      id: $id
      ${TAG_VARIABLES}
    ) {
      tag {
        ...TagCoreFields
      }
      ${ERRORS}
    }
  }
`;



