import React, { useState, useContext } from 'react';
import BlogContext from 'src/contexts/BlogContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { View, Text, Pressable } from 'react-native';
import { elRed } from 'src/styles/colors';

const SearchInput = ({}) => {
  const {setCurrentTerm} = useContext(BlogContext);
  const [term, setTerm] = useState();
  return (
    <View
      style={{
        padding: '20px', display:'flex', flexDirection: 'row', justifyContent: 'center'
      }}
    >
      <Text
        style={{fontWeight: '700', fontSize: '20px', marginTop: '5px'}}
      >
        Search:
      </Text>
      <View
        style={{
          flexDirection: 'row', justifyContent: 'space-between', width: '75%'
        }}
      >
        <input
          onChange={(e) => setTerm(e.target.value)}
          value={term}
          style={{width: '100%', marginLeft: '20px'}}
        />
        <Pressable
          onPress={() => setCurrentTerm(term)}
          style={{marginLeft: '5px', padding: '10px',
            backgroundColor: elRed, borderRadius: '5px'
          }}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Pressable>
      </View>
    </View>
  )
}

export default SearchInput;