import { Text as BaseText } from 'react-native';
import styled from 'styled-components/native';
import webStyled from 'styled-components';
import * as Colors from './colors';
// import colors from './colors';
import fonts from './fonts';

export const Text = styled(BaseText)`
  font-family: '${fonts.body}';
  font-size: 16px;
`;

export const Hint = styled(Text)`
  color: ${Colors.disabled};
`;

export const BoldText = webStyled.div`
  color: ${Colors.elGray}
  font-size: 14px;
  font-weight: 700;
`;

export const Toggles = webStyled.div`
  flex-direction: row;
  margin-left: 20px;
`;

export const ToggleText = webStyled.div`
  margin-left: 10px;
  margin-right: 50px;
`;
