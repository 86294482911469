import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../queries/withCount';

export const LOCATION_CORE_FIELDS = `
  fragment LocationCoreFields on Location {
    id
    stateId
    name
    street
    city
    state
    postalCode { formatted, value}
    phoneNumber { formatted, value}
    equiplincContact {
      id
      fullName
    }
    latitude
    longitude
  }
`;

export const LOCATIONS = gql`
  ${LOCATION_CORE_FIELDS}
  query Locations($scopes: [String!]) {
    locations(scopes: $scopes) {
      ...LocationCoreFields
    }
  }
`;

export const LOCATIONS_WITH_COUNT = gql`
  query (
    ${WITH_COUNT_VARIABLES}
  ) {
    locationsWithCount(
      ${WITH_COUNT_ARGUMENTS}
    ) {
      entries {
        ${LOCATION_CORE_FIELDS}
      }
      count
    }
  }
`;

export const LOCATION = gql`
  ${LOCATION_CORE_FIELDS}
  query Location($id: ID!) {
    location(id: $id) {
      ...LocationCoreFields
    }
  }
`;
