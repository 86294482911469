import React, { useContext } from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';

import AuthContext from 'src/contexts/AuthContext';
import { Loading } from 'src/components/DataState';
import { Notice } from 'src/elements';

const BuyerUpdated = () => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser?.buyer) { return <Loading />; }

  return (
    <View>
      <Notice type="success">
        {'Review' === currentUser.buyer.status ?
          'Your Account is now being reviewed. Thank you!' :
          'Your Billing Information has been updated.'
        }
      </Notice>

      <View
        style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 100}}
      >
        <Link to="/account/billing">Return to Billing Information</Link>
      </View>
    </View>
  );
};

export default BuyerUpdated;
