import React from 'react';
import { ConditionContainer, Condition, ConditionLabel } from './elements';
import '../../styles/styles.css';

const ConditionDisplay = ({condition}) => {
  if(!condition || !condition.length) {
    return 'There is no condition information currently available.';
  }

  const keys = Object.keys(condition);

  return (
    <ConditionContainer>
      <table id='conditions-table'>
        <thead>
          <th>Component</th>
          <th>Description</th>
        </thead>
        <tbody>
          {keys.map(key => {
            return (
              <tr>
                <td>{condition[key].name}</td>
                <td>{condition[key].description}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </ConditionContainer>
  )
}

export default ConditionDisplay;