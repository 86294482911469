import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const SELLER_FIELDS = `
  id
  name
  companyName
  street
  city
  state
  postalCode { formatted, value}
  feeType
  feeAmount
  reviewedAt
  status
  confirmationExpiration
  contacts {
    firstName
    lastName
    email
    phoneNumber { formatted, value}
  }
`;

const SELLER_VARIABLES = `
  $id: ID!
  $status: String!
`;

const SELLER_ARGUMENTS = `
  id: $id
  status: $status
`;

const SELLER_FIELDS_AND_ERRORS = `
  seller {
    ${SELLER_FIELDS}
  }
  ${ERRORS}
`;

export const UPDATE_SELLER = gql`
  mutation UpdateSeller(
    ${SELLER_VARIABLES}
  ) {
    updateSeller(
      ${SELLER_ARGUMENTS}
    ) {
      ${SELLER_FIELDS_AND_ERRORS}
    }
  }
`;

export const SELLER = gql`
  query SELLER($id: ID!) {
    seller(id: $id) {
      ${SELLER_FIELDS}
    }
  }
`;