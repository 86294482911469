import React from 'react';
import styled from 'styled-components';
import { statesList } from 'src/elements/states';

const StatesSection = styled.div`
  max-width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CityRow = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StateList = ({groupedLocations, stateKeys}) => {
  const locations = state => {
    if (groupedLocations[state]) {
      if (groupedLocations[state].toSorted) {
        return (
          groupedLocations[state].toSorted((a, b) => a.city < b.city ? -1 : 1)
        );
      } else {
        return groupedLocations;
      }
    } else {
      return [];
    }
  };

  return (
    <StatesSection className='state-section'>
      {stateKeys.map(state => {
        return (
            <StateContainer key={`state-${state}`}>
              <span
                style={{
                  fontWeight: 'bold', paddingBottom: '10px', maxWidth: '100%',
                  textAlign: 'left'
                }}
              >
                {statesList[state]['label'].toUpperCase()}
              </span>
              <CityRow key={`state-city-row-${state}`}>
                {locations(state).map(location => {
                  return (
                    <div
                      style={{maxWidth: '100%', display: 'flex',
                        flexDirection: 'row', flexWrap: 'wrap'
                      }}
                      key={`location-city-row-${location.id}`}
                    >
                      <span style={{textWrap: 'nowrap'}}>
                        {location.city.toUpperCase()}
                      </span>
                      {location !== locations(state)[locations(state).length - 1] &&
                        <span
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px'
                          }}
                        >
                          |
                        </span>
                      }
                    </div>
                  )
                })}
              </CityRow>
            </StateContainer>
        )
      })}
    </StatesSection>
  )
}

export default StateList;
