import React from 'react';
import styled from 'styled-components';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  ImageContainer,
} from './elements';
import FinanceLink from '../FinanceLink';

const LeftColumn = styled.div`
  display: flex;
  width: 50%;
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  background-size: 21%;
`;

const SalesHeader = () => {
  return (
    <>
      <Container>
        <ColumnContainer>
          <LeftColumn>
            <TextContainer>
              <HeaderLabel>
                Auctions
              </HeaderLabel>
              <RedSeparator />
            </TextContainer>
          </LeftColumn>
          <ImageContainer />
        </ColumnContainer>
      </Container>
      <FinanceLink containerMargin='10px' />
    </>
  )
}

export default SalesHeader;