import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import AccountStatus from 'src/components/Account/Status';
import { Loading } from 'src/components/DataState';
import SaleHeader from '../Headers/SaleHeader';
import Inventory from '../Inventory';

import { SALE } from './queries';

const Container = styled.div`

`;

const SaleContainer = styled.div`
  padding: 20px 5% 20px 5%;
`;

const SaleTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

const SmallText = styled.div`
  font-size: 20px;
  line-height: 40px;
`;

const LotText = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 80px;
`;

const Sale = () => {
  const { id } = useParams();
  const { data } = useQuery(SALE, { variables: { id } });

  if (!data) { return <Loading />; }

  return (
    <Container>
      <AccountStatus />
      <SaleHeader />
      <SaleContainer>
        <SaleTitle>{data.sale.name}</SaleTitle>
        <SmallText style={{fontWeight: 600}}>
          LIVE bidding begins at
          {DateTime.fromISO(data.sale.startAt).toFormat(' h:mma')}.{' '}
          Pre bidding available now.
        </SmallText>
        <LotText>Lots</LotText>
        <Inventory saleId={data.sale.id} />
      </SaleContainer>
    </Container>
  );
};

export default Sale;
