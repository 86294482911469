import React, { useState } from 'react';
import { View, Pressable, Text } from 'react-native';
import { useMutation } from '@apollo/client';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import Modal from 'src/elements/NativeModal';
import * as colors from 'src/styles/colors';

import { Button, Title } from './elements';
import { CHOOSE_BID_GROUP } from './queries';
import SaleAssetDetail from './SaleAssetDetail';
import { Dimensions } from 'react-native-web';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1024;

const Container = styled(View)`
  margin-bottom: 10px;
  gap: 10px;
`;

const Choices = styled(View)`
  flex-direction: row;
  gap: 10px;
`;

const Choice = styled(View)`
  flex: 1;
`;

const BidGroupChoose = ({ sale, saleAssets }) => {
  const id = sale.bidGroupChoiceId;
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDetail, setShowDetail] = useState(false);

  const [chooseBidGroup] = useMutation(CHOOSE_BID_GROUP);

  const toggleSelectedId = id => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const options = saleAssets.
    filter(saleAsset => id === saleAsset.pendingBidGroupId);

  const isSelected = saleAsset => selectedIds.includes(saleAsset.id);

  const buildConfirmMessage = () => {
    const selectedOptions = options.filter(option => {
      if(selectedIds.includes(option.id)) return option;
    });

    const formattedOptions = selectedOptions.map(option => {
      return `\n${option.lotNumber} - ${option.asset.name}`
    })

    return (
        `Proceed with selection of: \n${formattedOptions.join('')}`
    )
  }

  const handleSubmit = () => {
    if(window.confirm(buildConfirmMessage())) {
      chooseBidGroup({ variables: { id, saleAssetIds: selectedIds } });
    }
  };

  return (
    <Container
      style={{minHeight: smallScreen ? 'fit-content' : 'none',
        alignItems: smallScreen ? 'center' : '',
        maxWidth: smallScreen ? '63.5%' : 'none'
      }}
    >
      <Choices
        style={{
          display: 'flex', flexDirection: smallScreen ? 'column' : 'row',
          gap: smallScreen ? '70px' : 'none'
        }}
      >
        {options.map(saleAsset => (
          <Choice key={saleAsset.id}>
            <View style={{ flexDirection: 'row', gap: 10 }}>
              <Title>{saleAsset.lotNumber}</Title>
              <Pressable onPress={() => setShowDetail(saleAsset)}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Pressable>
            </View>
            <Button
              title={isSelected(saleAsset) ? 'Selected ✓' : 'Select'}
              color={isSelected(saleAsset) ? colors.elGreen : 'darkgray'}
              onPress={() => toggleSelectedId(saleAsset.id)}
              style={{
                minHeight: 30
              }}
            />
          </Choice>
        ))}
      </Choices>

      <Button
        title="Submit Selections"
        disabled={0 === selectedIds.length}
        onPress={handleSubmit}
        style={{
          minHeight: 30,
          marginTop: smallScreen ? '100px' : 'none'
        }}
      />
      {showDetail && (
        <Modal
          showModal={showDetail}
          setShowModal={setShowDetail}
          modalType={showDetail.lotNumber}
        >
          <View
            style={{
              height: smallScreen ? 300 : 500,
              maxWidth: smallScreen ? '45%' : 600
            }}
          >
            <SaleAssetDetail saleAsset={showDetail} />
          </View>
        </Modal>
      )}
    </Container>
  );
};

export default BidGroupChoose;
