import styled from 'styled-components/native';
import { View, Text } from 'react-native';

import {
  elRed,
  elGray
} from '../../styles/colors';

export const MainBlogContainer = styled(View)`
  display: flex;
  flexDirection: ${props => props.flexDirection || 'row'};
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '100%'};
  flex-wrap: wrap;
`;

export const BlogContainer = styled(View)`
  display: flex;
  flexDirection: ${props => props.flexDirection || 'row'};

  gap: 20px;
  padding: 5%;
`;

export const CardContainer = styled(View)`
  width: ${props => props.width || '100%'};
  maxWidth: ${props => props.maxWidth || '100%'};
  border: 2px solid ${elGray};
  border-radius: 10px;
  padding: 2%;
`;

export const FeaturedCardContainer = styled(View)`
  width: ${props => props.width || '100%'};
  maxWidth: ${props => props.maxWidth || '100%'};
  padding: 2%;
`;

export const ContentContainer = styled(View)`
  width: ${props => props.width || '75%'};
  max-height: ${props => props.fromShow ? 'fit-content' : '250px'};
  overflow: hidden;
  border: 1px solid ${elGray};
  border-radius: 5px;
  padding: 20px;
`;

export const CardContentContainer = styled(View)`
  width: ${props => props.width || '100%'};
  max-height: ${props => props.fromShow ? 'fit-content' : '250px'};
  overflow: hidden;
`;

export const SideBar = styled(View)`
  width: ${props => props.width || '25%'};
  maxWidth: ${props => props.maxWidth || '100%'};
  height: 'fit-content';
  margin-top: ${props => props.fromShow ? '0px' : '20px'};
`;

export const CardTitle = styled(Text)`
  font-size: ${props => props.fontSize || '32px'};
  font-weight: 700;
  margin-bottom: 5px;
`;

export const CardDate = styled(Text)`
  font-size: ${props => props.fontSize || '20px'};
  margin-bottom: 20px;
`;

export const Tag = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 5px;
  background-color: ${elRed};
  width: fit-content;
  border-radius: 5px;
  margin: 5px;
`;

export const BlogButton = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  padding: 10px;
  background-color: ${elRed};
  width: fit-content;
  border-radius: 5px;
  margin: 40px 5px 5px 5px;
`;

export const MainPhotoContainer = styled(View)`
  width: ${props => props.width || '100%'};
  align-items: ${props => props.alignItems || 'center'};
`;

export const NavigatorContainer = styled(View)`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 100px;
`;

export const NavigatorButtonContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const NavigatorButton = styled(View)`
  display: flex;
  flex: 0 0 150px;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  background-color: ${ elRed };
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
  min-width: fit-content;
`;

export const NavigatorButtonText = styled(Text)`
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  margin-top: -2px;
`;

export const SeparatorDiv = styled(View)`
  margin-right: 10px;
  margin-left: 10px;
`;