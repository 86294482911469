import { gql } from '@apollo/client';

export const CONVERSATION_FIELDS = gql`
  fragment ConversationFields on Conversation {
    id
    status
    createdAt
    lastIncomingAt
    lastIncoming { body }
    isPendingResponse
    assignedTo {
      fullName
    }
  }
`;

const MESSAGE_FIELDS = gql`
  fragment MessageFields on Message {
    id
    body
    createdAt
    isIncoming
    userName
  }
`;

export const CONVERSATION = gql`
  ${CONVERSATION_FIELDS}
  ${MESSAGE_FIELDS}
  query Conversation($id: ID!) {
    conversation(id: $id) {
      ...ConversationFields
      messages {
        ...MessageFields
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation CreateMessage(
    $conversationId: ID!
    $body: String!
    $source: String!
    $fromPaddleService: Boolean
  ) {
    createMessage(
      conversationId: $conversationId
      body: $body
      source: $source
      fromPaddleService: $fromPaddleService
    ) {
      message {
        ...MessageFields
      }
      errors { path, message }
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  ${CONVERSATION_FIELDS}
  mutation CreateConversation(
    $buyerUserId: ID
  ) {
    createConversation(
      buyerUserId: $buyerUserId
    ) {
      conversation {
        ...ConversationFields
      }
      errors { path, message }
    }
  }
`;

export const CONVERSATION_UPDATED = gql`
  ${CONVERSATION_FIELDS}
  ${MESSAGE_FIELDS}
  subscription ConversationUpdated($conversationId: ID!) {
    conversationUpdated(conversationId: $conversationId) {
      conversation {
        ...ConversationFields
        messages {
          ...MessageFields
        }
      }
    }
  }
`;