import React, { createContext, useState, useEffect } from 'react';

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [allBlogTags, setAllBlogTags] = useState([]);
  const [currentBlogTag, setCurrentBlogTag] = useState();
  const [currentBlogs, setCurrentBlogs] = useState([]);
  const [currentViewedBlogId, setCurrentViewedBlogId] = useState();
  const [featuredLabel, setFeaturedLabel] = useState('Featured');
  const [featured, setFeatured] = useState([]);
  const [refetch, setRefetch] = useState();
  const [currentFilterPage, setCurrentFilterPage] = useState(-1);
  const [currentItemOffset, setCurrentItemOffset] = useState(-1);
  const [currentTerm, setCurrentTerm] = useState('');

  useEffect(() => {
    if(currentBlogs) {
      let newFeatured = currentBlogs.filter(blog => blog.featured);
      setFeatured(newFeatured);
    }
  }, [currentBlogs])

  const slug = blog => (
    blog.title.replace(/[^0-9a-z]+/gi, '-')
  );

  const getPreviousBlog = () => {
    if(!currentBlogs.length) return null;

    const currentIndex = currentBlogs.findIndex(
      blog => parseInt(blog.id) === parseInt(currentViewedBlogId)
    );
    let previousIndex = currentIndex === 0 ?
      currentBlogs.length - 1 : currentIndex - 1;

    const previousBlog = currentBlogs[previousIndex];

    if(previousBlog) {
      setCurrentViewedBlogId(previousBlog.id);
      return `${previousBlog.id}/${slug(previousBlog)}`
    }
  }

  const getNextBlog = () => {
    if(!currentBlogs.length) return null;

    const currentIndex = currentBlogs.findIndex(
      blog => parseInt(blog.id) === parseInt(currentViewedBlogId)
    );
    let nextIndex = currentIndex === currentBlogs.length - 1 ?
      0 : currentIndex + 1;

    const nextBlog = currentBlogs[nextIndex];

    if(nextBlog) {
      setCurrentViewedBlogId(nextBlog.id);
      return `${nextBlog.id}/${slug(nextBlog)}`
    }
  }

  const clearBlogs = () => {
    setCurrentBlogs([]);
    setCurrentViewedBlogId();
    setCurrentItemOffset(-1);
    setCurrentFilterPage(-1);
    setCurrentBlogTag();
    setCurrentTerm();
  }

  const value = {
    allBlogTags,
    setAllBlogTags,
    currentBlogTag,
    setCurrentBlogTag,
    currentBlogs,
    setCurrentBlogs,
    currentViewedBlogId,
    setCurrentViewedBlogId,
    getPreviousBlog,
    getNextBlog: getNextBlog,
    clearBlogs: clearBlogs,
    featuredLabel,
    setFeaturedLabel,
    featured,
    setFeatured,

    currentFilterPage,
    setCurrentFilterPage,
    currentItemOffset,
    setCurrentItemOffset,
    currentTerm,
    setCurrentTerm
  };

  return (
    <BlogContext.Provider value={value}>
      {children}
    </BlogContext.Provider>
  );
};

export default BlogContext;