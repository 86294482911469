import React, {useState} from 'react';
import { useQuery } from '@apollo/client';
import { TESTIMONIALS } from './queries';
import styled from 'styled-components';
import * as Colors from '../../styles/colors';
import Card from './Card';
import {
  TestimonialsContainer,
  TestimonialGridContainer,
  TestimonialHeader,
  RegularText
} from './elements';

const Testimonials = () => {
  const { data } = useQuery(TESTIMONIALS);

  return (
    <TestimonialsContainer>
      <TestimonialHeader>
        What People Say
        <RegularText>
          Here's what some of our customers have to say about us
        </RegularText>
      </TestimonialHeader>
      <TestimonialGridContainer>
        {data?.testimonials?.map(testimonial => {
          return <Card testimonial={testimonial} key={`t-${testimonial.id}`} />
        })}
      </TestimonialGridContainer>
    </TestimonialsContainer>
  )
}

export default Testimonials;