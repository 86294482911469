import React, { Suspense, lazy } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { elRed } from 'src/styles/colors';
import Menu from 'src/components/Menu';
import DataState from 'src/components/DataState';
import { Dimensions } from 'react-native-web';
import MobileMenu from '../Menu/MobileMenu';

const MainContainer = styled.div`
  position: relative;
  z-index: 99999;
  width: 100%;
  max-width: 100%;
`;

const DemographicContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #000;
  height: 25px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 5px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
  flex: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0.3
  justify-content: flex-end;
`;

const DetailContainer = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 500;
`;

const HeroContainer = styled.div`

`;

const heroImageStyle = {
  height: '75%'
}

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1180;


const Header = () => {
  return (
    <MainContainer>
      {!smallScreen && !mediumScreen &&
        <DemographicContainer>
          <>
            <LeftContainer>
              <DetailContainer>
                jbrinkley@equiplincauctions.com
              </DetailContainer>
              <DetailContainer>
                Live/Virtual Auctions Conducted - Commercial Assets Sold Nationwide
              </DetailContainer>
            </LeftContainer>
            <RightContainer>
              <FontAwesomeIcon
                icon={faPhone}
                size='sm'
                style={{color: elRed, marginTop: '4px'}}
              />
              <DetailContainer>
                (678) 788-0965
              </DetailContainer>
            </RightContainer>
          </>
        </DemographicContainer>
      }
      {smallScreen || mediumScreen ?
        <MobileMenu /> :
        <Menu />
      }
    </MainContainer>
  )
}

export default Header;
