import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';

import { Loading } from 'src/components/DataState';
import { Text } from 'src/styles';
import { Button, Notice } from 'src/elements';
import { FormContainer, Fields, defaultValues } from 'src/elements/forms';

import { UPDATE_CURRENT_BUYER_USER } from './queries';
import AuthContext from 'src/contexts/AuthContext';

export const FIELDS = {
  password: {
    rules: {
      required: true,
      minLength: { value: 8, message: 'Must be at least 8 characters' },
    },
    inputProps: { type: 'password' },
  },
  passwordConfirmation: {
    label: 'Confirm Password',
    rules: { required: true },
    inputProps: { type: 'password' },
  },
};

const UpdatePassword = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const [formStatus, setFormStatus] = useState();

  const {
    control, handleSubmit, reset, watch, formState: { errors }
  } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const watchPassword = watch('password');

  const fields = {...FIELDS};
  fields.passwordConfirmation.rules = {
    required: true,
    validate: value => {
      if (watchPassword !== value) {
        return 'Password does not match confirmation';
      }
    }
  };

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_BUYER_USER, {
    onCompleted: () => {
      setFormStatus('success');
      reset({ password: '', passwordConfirmation: '' });
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    updateCurrentUser({ variables: input });
  };

  if(!isLoggedIn) {
    window.location.replace('/login');
    return null;
  }

  return (
    <View>
      <FormContainer onEnter={handleSubmit(onSubmit)} style={{width: '100%'}}>
        {'success' === formStatus && (
          <Notice type="success">Your Password has been updated.</Notice>
        )}
        <Text>Update Password</Text>
        <Fields fields={fields} control={control} errors={errors} />
        {'submitting' === formStatus ? (
          <Loading />
        ) : (
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        )}
      </FormContainer>
    </View>
  );
};

export default UpdatePassword;
