import styled from 'styled-components';

import colors from 'src/styles/colors';

export {
  Label,
  Button,
  CancelButton,
  Input,
  ErrorMessage,
} from 'src/elements';

export const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 470px;
  max-width: 90%;
  height: 570px;
  background-color: white;

  border-radius: 8px;
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.12));
`;

export const InnerContainer = styled.div`
  display: block;

  margin-left: 56px;
  margin-right: 56px;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow-y: auto;

  @media (max-width: 480px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

export const Title = styled.h1`
  color: ${colors.titleColor};
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

export const Instructions = styled.div`
  color: ${colors.informationText};
  margin-bottom: 2rem;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

export const ActionLink = styled.span`
  color: ${colors.buttonBackground};
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
`;
