import React from 'react';
import { Link } from 'react-router-dom';
import { elRed } from 'src/styles/colors';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  height: 75px;
  align-items: center;
  padding-top: ${props => props.containerMargin};
  margin-top: 10px;
`;

const FinanceLink = ({containerMargin}) => {
  return (
    <Container containerMargin={containerMargin} >
      <a
        href="https://www.nationaltruckloans.com/equiplinc-auctions"
        target="_blank"
        style={{
          padding: '10px 60px', backgroundColor: elRed, borderRadius: '5px',
          color: '#fff', textDecoration: 'none', cursor: 'pointer',
          fontWeight: 'bold', fontSize: '20px', border: '1px solid #fff',
          boxShadow: '1px 2px 10px #000', lineHeight: '25px'
        }}
      >
        Need Auction Financing?
        <br />
        Get PRE-QUALIFIED Now!
      </a>
    </Container>
  )
}

export default FinanceLink;