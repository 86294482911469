import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import styled from 'styled-components';
import AuthContext from '../../contexts/AuthContext';

import Login from './new';
import AcceptInvite from './AcceptInvite';
import ResetPassword from './ResetPassword';

import Main from '../../Main';

const Screen = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 100px;
  animation: fadeIn 1s;
`;

const Auth = () => {
  const {
    apolloClient,
  } = useContext(AuthContext);

  const params = new URLSearchParams(window.location.search);
  const inviteToken = params.get('inviteToken');
  const resetToken = params.get('resetToken');

  let content = <Main />;

  if (resetToken) {
    content = <ResetPassword token={resetToken} />;
  } else if (inviteToken) {
    content = <AcceptInvite token={inviteToken} />;
  } else if ('/login' === window.location.pathname) {
    content = <Login />
  } else {
    // If there are no special situations, assume that user wishes to remain a
    // guest
    return content;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Screen>
        <Content>
          {content}
        </Content>
      </Screen>
    </ApolloProvider>
  );
};

export default Auth;
