import React from 'react';
import { useQuery} from '@apollo/client';
import { ASSET } from '../Inventory/queries';
import {
  ContactHeader,
  HR,
  RedDetail,
  AssetDetails
} from './elements';
import { LeftColumn, RightColumn } from 'src/elements';
import currencyFormatter from 'src/utils/currencyFormatter';

const BuyNowAssetDetails = ({assetId}) => {
  const { data } = useQuery(ASSET, {variables: {id: assetId}});

  return (
    <>
      <ContactHeader>
        {`Contact Us About ${data?.asset?.name}`}
      </ContactHeader>
      <HR />
      {data?.asset && (
        <div id='test' style={{display: 'flex', flexDirection: 'column'}}>
          <b>Asset Details</b>
          <div
            style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}
          >
            <LeftColumn>
              <img
                src={data?.asset.primaryPhotoUrl}
                alt={data?.asset.name}
                style={{maxWidth: '25%'}}
              />
              <RedDetail>
                {`BUY NOW PRICE: ${
                  currencyFormatter(parseFloat(data?.asset.listPrice))
                }`}
              </RedDetail>
            </LeftColumn>
            <RightColumn>
              <AssetDetails id='asset-details'>
                <b>VIN:</b>{` ${data?.asset.vin}`}
                <br />
                <b>STOCK#:</b>{` ${data?.asset.stockNumber}`}
              </AssetDetails>
            </RightColumn>
          </div>
        </div>
      )}
    </>
  )
}

export default BuyNowAssetDetails;
