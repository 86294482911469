import React, { useCallback, useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Input from '../../elements/inputs';
import { CONTACT_SUBMISSION } from './queries';
import { Button } from '../../elements/index';
import {
  ContactContainer,
  LeftColumn,
  RightColumn,
  ContactHeader,
  HR,
  FormGrid,
  Label,
  Field,
  ContactSubHeader,
  RedDetail
} from './elements';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreen, faLocationDot, faPlay } from '@fortawesome/free-solid-svg-icons';
import { elGreen, elRed } from 'src/styles/colors';
import fbIcon from './icon-facebook-48.png';
import { useParams } from 'react-router-dom';
import BuyNowAssetDetails from './BuyNowAssetDetails';
import Recaptcha from './Recaptcha';
import { Dimensions } from 'react-native-web';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1024;

const Contact = () => {
  const { assetId, sellerId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);

  const [sendContact] = useMutation(CONTACT_SUBMISSION,
    {
      onCompleted(response) {
        setShowSubmitMessage(true);
      }
    }
  );

  const onSubmit = useCallback(input => {

    if(validated) {
      setSubmitting(true);
      sendContact({ variables: input });
    }
  });

  const {
    control, register, handleSubmit, formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      body: '',
      assetId: assetId,
      sellerId: sellerId
    }
  });

  const loadRecaptcha = useMemo(() => {
    return <Recaptcha validated={validated} setValidated={setValidated} />
  }, [])

  return (
      <ContactContainer>
        <>
          <LeftColumn>
            {!assetId &&
              <>
                {/* {getVideoHeader()} */}
                <ContactHeader>
                  Contact Us
                </ContactHeader>
              </>
            }
            {assetId &&
              <BuyNowAssetDetails assetId={assetId} />
            }
            <HR />
            {showSubmitMessage ?
              <div
                style={{fontSize: '20px', fontWeight: 400, color: elGreen}}
              >
                Contact Submitted. <br/><br/>
                A Representative from Equiplinc Auction Group
                will get back with you quickly.
              </div> :
              <>

                <Label>Full Name</Label>
                <Field>
                  <Input.Text
                    label=""
                    name="name"
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Label>Email</Label>
                <Field>
                  <Input.Text
                    label=""
                    name="email"
                    control={control}
                    errors={errors}
                    {...register("email", {
                      required: "Email is required",
                      validate: {
                        maxLength: (v) =>
                          v.length <= 50 || "The email should have at most 50 characters",
                        matchPattern: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                          "Email address must be a valid address",
                      },
                    })}
                  />
                </Field>
                <Label>Phone#</Label>
                <Field>
                  <Input.Text
                    label=""
                    name="phoneNumber"
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Label>Questions / Comments</Label>
                <Field>
                  <Input.TextArea
                    label=""
                    name="body"
                    rows="20"
                    control={control}
                    errors={errors}
                    width={'100%'}
                  />
                </Field>
                {loadRecaptcha}

                <Button
                  onClick={validated ? handleSubmit(onSubmit) : undefined}
                  style={{
                    width: '30%',
                    backgroundColor: validated ? 'green' : elRed,
                    opacity: validated ? 1 : 0.5
                  }}
                >
                  Send
                </Button>
              </>
            }
          </LeftColumn>
        </>
        <RightColumn>
          <ContactHeader>Other Contact Methods</ContactHeader>
          <HR />
          <ContactSubHeader>Contact Jim Manka</ContactSubHeader>
          <RedDetail>
            <FontAwesomeIcon
              icon={faMobileScreen}
              size='lg'
              style={{color: elRed, marginLeft: '5px', marginRight: '5px'}}
            />
            {`(912) 695 9406`}
          </RedDetail>
          <ContactSubHeader>Social Media</ContactSubHeader>
          <RedDetail
            onClick={() => window.open(
              'https://www.facebook.com/EquipLinc-Auction-Group-104605094707020',
              '_blank'
            )}
            style={{cursor: 'pointer'}}
          >
            <img src={fbIcon}
              style={{width: '20px', marginRight: '5px', marginTop: '5px'}}
            />
            Follow us on Facebook
          </RedDetail>
          <ContactSubHeader>Mailing Address</ContactSubHeader>
          2641 Highway 431<br />
          Roanoke, AL 36274
          <RedDetail
            onClick={() => window.open(
              'https://www.google.com/search?rls=en&q=2641+Highway+431+Roanoke,+AL+36274&ie=UTF-8&oe=UTF-8#',
              '_blank'
            )}
            style={{cursor: 'pointer'}}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              size='lg'
              style={{color: elRed, marginLeft: '5px', marginRight: '5px'}}
            />
            Directions
          </RedDetail>
        </RightColumn>
      </ContactContainer>
    // </GoogleReCaptchaProvider>
  )

  function getVideoHeader() {
    if(!showVideo) {
      return (
        <div style={{width: '100%', marginBottom: smallScreen || mediumScreen  ? '70px' : '200px'}}>
          <img
            src="https://video.covideo.com/thumbnails/122310_39975_q8f1n5u1e3_0001.png"
            style={{width: '100%'}}
          />
          <div id='video-overlay'
            style={{
              position: 'relative', color: elRed, marginLeft: '47%', marginTop: '-33%'
            }}
            onClick={() => setShowVideo(true)}
          >
            <FontAwesomeIcon
              icon={faPlay}
              size='2x'
            />
          </div>
        </div>
      )
    }
    if(showVideo) {
      return (
        <div
          onClick={() => setShowVideo(false)}
          className='react-player-wrapper'
        >
          <ReactPlayer
            url='https://videos.covideo.com/videos/122310_39975_q8f1n5u1e3.mp4'
            playing={showVideo}
            className='react-player'
            width='100%'
            height='0%'
          />
        </div>
      )
    }
  }
}

export default Contact;