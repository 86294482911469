import { useContext } from 'react';
import { useSubscription } from '@apollo/client';

import AuthContext from 'src/contexts/AuthContext';

import {
  CONVERSATION, CONVERSATION_UPDATED
} from './queries';

const useSubscriptions = ({ id }) => {
  const { apolloClient: client } = useContext(AuthContext);

  useSubscription(CONVERSATION_UPDATED, {
    variables: { conversationId: id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.conversationUpdated) return;

      client.writeQuery({
        query: CONVERSATION,
        variables: { id },
        data: { converasation: data.conversationUpdated.conversation },
      });
    }
  })
}

export default useSubscriptions;