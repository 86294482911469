import styled from "styled-components";
import * as Colors from '../../styles/colors';
import dotGrid from '../Headers/dot-grid.png';

export const CardsContainer = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CardContainer = styled.div`
  background-color: ${Colors.elLightGray};
  margin: 20px;
  border-radius: 10px;
`;

export const CardTitle = styled.div`
  font-weight: bold;
  font-size: 1em;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CardDetails = styled.div`
  padding: 10px;
  font-size: 0.9em;
  color: #A0A0A0;
`;

export const StockNumber = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LotNumber = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AuctionDate = styled.div`
  display: flex;
  background-color: ${Colors.elRed};
  padding: 10px;
  margin-top: 10px;
  color: #fff;
  font-weight: bold;
  width: 50%;
`;

export const BuyNowPrice = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AssetAuctionDate = styled.div`
  display: flex;

  @media(min-width: 768px) {
    font-size: 18px
  }
`;

export const AssetBuyNowPrice = styled.div`
  display: flex;

  @media(min-width: 768px) {
    font-size: 18px;
  }
`;

export const MediaContainer = styled.div`
  background-color: elGray;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpecsContainer = styled.div`
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const SpecLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
`;

export const EmptySpecLine = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const AssetContainer = styled.div`
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  padding-top: 20px;
  margin-top: 40px;

  @media(max-width: 768px) {
    background-size: 20%;
  }
`;

export const AssetContent = styled.div`
  margin: 5%;
  display: flex;
  flex-direction: column;
`;

export const AssetTop = styled.div`
  min-width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 50px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 40% 60%);
  }
`;

export const AssetBottom = styled.div`
  min-width: 100%;
`;

export const AssetImage = styled.div`
  max-width: 100%;

  @media(max-width: 768px) {
    margin-top: -30px;
  }

  @media(min-width: 768px) {
    max-width: 100%;
    min-height: fit-content;
  }
`;

export const AssetDetails = styled.div`
  margin-right: 10%;
`;

export const AssetSpecifications = styled.div`

`;

export const AssetButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AssetLinkContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AssetOverview = styled.div`
  ${AssetLinkContainer};
`;

export const AssetLocationDetails = styled.div`

`;

export const AssetLocationContainer = styled.div`

`;

export const AssetLink = styled.div`
  font-weight: 500;
  color: ${Colors.iconGray}
`;

export const AssetSubDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

export const AssetSidebarDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`;

export const AssetDetailRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
  border-top: 1px solid black;
`;

export const AssetDetailCloseRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const AssetDetailLabel = styled.div`
  font-weight: 700;
  flex: 0.4;
`;

export const AssetDetail = styled.div`
  flex: 0.6;
`;

export const TireSection = styled.div`

`;

export const BrakeSection = styled.div`

`;

export const TiresContainer = styled.div`
  padding: 20px;
  display: grid;
  justify-content: center;
`;

export const BrakesContainer = styled.div`
  padding: 20px;
  display: grid;
  justify-content: center;
  border: 1px solid ${Colors.elGray};
`;

export const ConditionContainer = styled.div`
  padding: 20px;
  border: 1px solid ${Colors.elGray};
`;

export const AxleContainer = styled.div`
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
`;

export const TireContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TiresGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: 70%;
  }
`;

export const Tire = styled.div`
  border-radius: 7.5px;
  background-color: ${Colors.elGray};
  width: 40px;
  height: 70px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 5px;
  font-weight: 700;
`;

export const BlankTire = styled.div`
  width: 40px;
  height: 70px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 5px;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: bottom;
`;

export const LegendItem = styled.div`
  margin: 5px;
`;

export const BrakeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Brake = styled.div`
  border: 1px solid black;
  border-radius: 7.5px;
  background-color: ${Colors.elLightGray};
  width: 40px;
  height: 70px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 5px;
  font-weight: 700;
`;

export const Condition = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ConditionLabel = styled.div`
  margin-left: 10px;
`;

export const LocationDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2%;
  gap: 10px;
`;

export const NavigatorButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const NavigatorButton = styled.div`
  display: flex;
  flex: 0 0 150px;
  padding: 10px 20px 10px 20px;
  background-color: ${ Colors.elRed };
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
  min-width: fit-content;
`;

export const SeparatorDiv = styled.div`
  margin-right: 10px;
  margin-left: 10px;
`;


