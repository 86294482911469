import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import webStyled from 'styled-components';

import { Text } from 'src/styles';
import { elGreen, elRed } from 'src/styles/colors';

const EmptyContainer = webStyled.div`
  display: flex;
  height: 40px;
`;

const Container = webStyled(EmptyContainer)`
  justify-content: center;
  align-items: center;
  background-color: ${elRed};

  @keyframes blink-then-fade {
    8% { opacity: 1; }
    16% { opacity: 0; }
    24% { opacity: 1; }
    '84% { opacity: 1; }'
    '100% { opacity: 0; }'
  }

  animation-duration: 6s;
  animation-name: blink-then-fade;
  animation-iteration-count: 999;

  opacity: 1;
`;

const Message = styled(Text)`
  font-size: 20px;
  color: #fff;
  font-weight: 600;
`;

const Flash = ({ message }) => {
  if (!message) {
    return <EmptyContainer />;
  }

  return (
    <Container>
      <Message>
        {message}
      </Message>
    </Container>
  );
};

export default Flash;
