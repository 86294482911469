import React, { useContext } from 'react';
import { useQuery, useSubscription } from '@apollo/client';

import AuthContext from 'src/contexts/AuthContext';

import {
  USER_ACTIVITIES,
  USER_ACTIVITY_RECORDED,
} from './queries';

const useSubscriptions = () => {
  const { data } = useQuery(USER_ACTIVITIES);

  const { apolloClient: client } = useContext(AuthContext);

  useSubscription(USER_ACTIVITY_RECORDED, {
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data || !data.userActivityRecorded) return;

      const newActivity = data.userActivityRecorded.userActivity;
      const { userActivities } = client.readQuery({ query: USER_ACTIVITIES });

      let updatedActivities = [];

      if (userActivities) {
        updatedActivities = [
          ...userActivities.filter(activity => (
            activity.id !== newActivity.id
          ))
        ];
      }

      if (!newActivity.isDestroyed) {
        updatedActivities.push(newActivity);
      }

      client.writeQuery({
        query: USER_ACTIVITIES,
        data: { userActivities: updatedActivities },
      });
    }
  });
};

export default useSubscriptions;
