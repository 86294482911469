import React, { createContext, useRef, useState, useEffect } from 'react';

const MessageInputContext = createContext();

export const MessageInputProvider = ({ client, children }) => {
  const currentMessageInputRef = useRef();
  // localStorage.removeItem('conversationId')
  const [currentConversationId, setCurrentConversationId] = useState(
    localStorage.getItem('conversationId')
  );
  const [body, setBody] = useState('');

  useEffect(() => {
    setBody('');
  }, [currentConversationId])

  const value = {
    currentMessageInputRef: currentMessageInputRef,
    body: body,
    setBody: setBody,
    currentConversationId: currentConversationId,
    setCurrentConversationId: setCurrentConversationId
  };

  return (
    <MessageInputContext.Provider value={value}>
      {children}
    </MessageInputContext.Provider>
  );
};

export default MessageInputContext;
