import { gql } from '@apollo/client';

import {
  WITH_COUNT_VARIABLES,
  WITH_COUNT_ARGUMENTS
} from '../queries/withCount';

export const ASSET_CORE_FIELDS = gql`
  fragment AssetCoreFields on Asset {
    id
    name
    category
    subCategory
    vin
    year
    make
    model
    lotNumber
    stockNumber
    miles
    engineType
    listPrice
    primaryPhotoUrl
    soarrPhotoUrls
    soarrVideoUrls
    street
    city
    state
    postalCode { formatted, value }
    segment
    notes
    inspectionUrl
    nisInspectionUrl
    activeSaleAsset {
      id
      sale {
        id
        saleOn
        startAt
        endAt
      }
    }
    photos {
      id
      filename
      contentType
      url
    }
    location {
      id
      name
      street
      city
      state
      postalCode { formatted, value }
      phoneNumber { formatted, value }
    }
  }
`;

export const ASSETS = gql`
  ${ASSET_CORE_FIELDS}
  query Assets {
    assets {
      ...AssetCoreFields
    }
  }
`;

export const ASSET = gql`
  ${ASSET_CORE_FIELDS}
  query InventoryAsset($id: ID, $soarrId: ID) {
    asset: inventoryAsset(id: $id, soarrId: $soarrId) {
      ...AssetCoreFields
      generalDetails
      tireDetails
      brakeDetails
      conditionDetails
      preBidData: activeSaleAsset {
        highPreBidPrice
        highPreBid { id, price, type }
        myPreBid { id, price, type }
        preBidStartPrice
        preBidDefaultStep
        preBidMinStep
      }
      buyerUserAssetView {
        id
        watching
        emailAlert
        textAlert
      }
    }
  }
`;

const ASSET_FIELDS = `
  id
  name
  category
  year
  make
  model
  stockNumber
  miles
  engineType
  category
  listPrice
`

export const ASSETS_WITH_COUNT = gql`
  ${ASSET_CORE_FIELDS}
  query (
    ${WITH_COUNT_VARIABLES}
    $saleId: ID
  ) {
    assetsWithCount(
      ${WITH_COUNT_ARGUMENTS}
      saleId: $saleId
    ) {
      entries {
        ...AssetCoreFields
      }
      count
    }
  }
`;

export const CREATE_PRE_BID = gql`
  mutation CreatePreBid(
    $saleAssetId: ID!
    $type: String!
    $price: Int!
  ) {
    createPreBid(
      saleAssetId: $saleAssetId
      type: $type
      price: $price
    ) {
      preBid {
        id
      }
      errors { path, message }
    }
  }
`;

export const BUYER_USER_ASSET_WATCH = gql`
  mutation BuyerUserAssetWatch(
    $assetId: ID!
    $watching: Boolean!
    $emailAlert: Boolean!
    $textAlert: Boolean
  ) {
    buyerUserAssetWatch(
      assetId: $assetId
      watching: $watching
      emailAlert: $emailAlert
      textAlert: $textAlert
    ) {
      buyerUserAssetView {
        id
        watching
        emailAlert
        textAlert
      }
    }
  }
`;
