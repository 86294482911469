import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png';
import logoWhite from './logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleUser, faChevronDown, faChevronUp
} from '@fortawesome/pro-solid-svg-icons';
import AccountMenu from './AccountMenu';
import AuctionMenu from './AuctionMenu';
import AuthContext from 'src/contexts/AuthContext';
import { headerGray, elRed } from 'src/styles/colors';
import ClickOutside from 'src/elements/ClickListener';
import { Dimensions } from 'react-native-web';
import WebContacts from './WebContacts';
import { WebContactsModal } from 'src/elements/Modal';
import ResourcesContext from 'src/contexts/ResourcesContext';
import BlogContext from 'src/contexts/BlogContext';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 10%;
`;

const logoStyle = {
  height: '75%'
}

const LinkContainer = styled.div`
  gap: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  margin-top: -18px;
  font-weight: 500;
  font-size: 18px;
`;

const RegisterButtonContainer = styled.div`
  width: fit-content;
  justify-content: flex-end;
  margin-left: 1%;
`;

const RegisterButton = styled(Link)`
  background-color: ${elRed};
  border-radius: 8px;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const Menu = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const [showAuctionMenu, setShowAuctionMenu] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showWebContacts, setShowWebContacts] = useState(false);
  const [currentExploded, setCurrentExploded] = useState();
  const {
    setInventoryFilterValues,
    setCurrentFilterPage,
    setCurrentItemOffset,
  } = useContext(ResourcesContext);
  const {
    clearBlogs
  } = useContext(BlogContext);

  const resetFilterValues = () => {
    setInventoryFilterValues({});
    setCurrentFilterPage(-1);
    setCurrentItemOffset(-1);
  }

  const salePath = useLocation().pathname.includes("/bidding/");

  const Container = styled.div`
    display: flex;
    height: 78px;
    padding-top: 10px;
    z-index: 999999 !important;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: ${salePath ? '#000' : headerGray};
    color: ${salePath ? '#fff' : '#000'};
    @media (max-width: 480px) {
      > img {
        width: 90%;
      }
    }
  `;

  const linkStyle = {
    textDecoration: 'none',
    color: salePath ? '#fff' : '#000',
    marginRight: '20px',
    fontSize: dimensions.width < 1600 ? '17px' : '18px'
  }

  return (
    <ClickOutside
      onClick={() => {
        setCurrentExploded('');
        setShowAccountMenu(false);
        setShowAuctionMenu(false);
        setShowWebContacts(false);
      }}
      className={'header-navigation-links'}
    >
      <Container>
        <LogoContainer>
          <Link to="/" style={linkStyle} onClick={() => resetFilterValues()}>
            <img src={salePath ? logoWhite : logo} alt="Equiplinc" style={logoStyle} />
          </Link>
        </LogoContainer>
        <LinkContainer>
          <Link to="/" style={linkStyle} onClick={() => resetFilterValues()}>
            Home
          </Link>
          <Link to="/about" style={linkStyle}>About</Link>
          <div
            onClick={() => setShowAuctionMenu(!showAuctionMenu)}
            style={{
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              fontSize: '16px'
            }}
          >
            Auction
            {showAuctionMenu ?
              <FontAwesomeIcon
                icon={faChevronUp}
                size='sm'
                style={{color: elRed, marginLeft: '15px', marginTop: '2px',
                  marginRight: '10px', cursor: 'pointer'
                }}

              /> :
              <FontAwesomeIcon
                icon={faChevronDown}
                size='sm'
                style={{color: elRed, marginLeft: '5px', marginTop: '2px',
                  marginRight: '10px', cursor: 'pointer'
                }}
              />
            }
            {showAuctionMenu &&
              <AuctionMenu
                showMenu={showAuctionMenu}
                setShowMenu={setShowAuctionMenu}
                isLoggedIn={isLoggedIn}
              />
            }
          </div>
          <Link to="/inventory" style={linkStyle}>Inventory</Link>
          <Link to="/buyNow" style={linkStyle}>Buy Now</Link>
          <Link to="/sell" style={linkStyle}>Sell Your Asset</Link>
          <Link to="/locations" style={linkStyle}>Locations</Link>
          <Link to="/blog" style={linkStyle} onClick={() => clearBlogs()}>
            Blog
          </Link>
          {!isLoggedIn && <a href="/login" style={linkStyle}>Login</a>}
          <WebContacts
            showWebContacts={showWebContacts}
            setShowWebContacts={setShowWebContacts}
          />
          <RegisterButtonContainer>
            {isLoggedIn ?
              <>
                <FontAwesomeIcon
                  icon={faCircleUser}
                  size='2xl'
                  style={{color: elRed, cursor: 'pointer'}}
                  onClick={() => setShowAccountMenu(!showAccountMenu)}
                />
                <AccountMenu showMenu={showAccountMenu} setShowMenu={setShowAccountMenu} />
              </> :
              <>
                <RegisterButton
                  to="/login"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontSize: dimensions.width < 1600 ? '16px' : '18px'
                  }}
                >
                  Register To Bid In Next Auction
                </RegisterButton>
              </>
            }
          </RegisterButtonContainer>
        </LinkContainer>
      </Container>
    </ClickOutside>
  );
};

export default Menu;
