import React, { useState, useContext, useEffect } from 'react';
import BlogContext from 'src/contexts/BlogContext';
import {
  NavigatorButtonContainer, NavigatorButton, NavigatorButtonText, SeparatorDiv
} from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faCircleLeft} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { BLOGS_WITH_COUNT } from './queries';

const Navigator = ({ }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentBlogTag, currentTerm } = useContext(BlogContext);
  const {
    currentBlogs,
    setCurrentBlogs,
    currentViewedBlogId,
    setCurrentViewedBlogId,
    getPreviousBlog,
    getNextBlog,
    clearBlogs,
    setFeaturedLabel,
    setAllBlogTags
  } = useContext(BlogContext);

  const [queryVariables, setQueryVariables] = useState(
    {
      filterColumns: ['tag'],
      filterNames: [currentBlogTag],
      scopes: ['ordered'],
      search: currentTerm || ''
    }
  );

  const [loadBlogs, { data }] = useLazyQuery(BLOGS_WITH_COUNT,
    {variables: queryVariables}
  );
  useEffect(() => {
    if(!currentViewedBlogId) {
      setCurrentViewedBlogId(parseInt(id))
    }
    if(!currentBlogs?.length) {
      loadBlogs().then((response) => {
        setFeaturedLabel(response?.data?.blogsWithCount?.featuredLabel);
        setCurrentBlogs(response?.data?.blogsWithCount?.entries);
        setAllBlogTags(response?.data?.blogsWithCount?.tags);
      })
    }
  }, [])

  return (
    <NavigatorButtonContainer>
      <NavigatorButton
        onClick={() => {
          const relativePath = getPreviousBlog();
          if(relativePath) {
            navigate(`/blog/${relativePath}`)
          }
        }}
      >
        <FontAwesomeIcon
          icon={faCircleLeft}
          title="Stop Audio"
          color="white"
          style={{marginRight: '15px'}}
        />
        <NavigatorButtonText>
          Previous
        </NavigatorButtonText>
      </NavigatorButton>
      <SeparatorDiv />
      <NavigatorButton
        onClick={() => {
          const path = `/blog`;
          clearBlogs();
          navigate(path);
        }}
      >
        <NavigatorButtonText>
          Blog
        </NavigatorButtonText>
      </NavigatorButton>
      <SeparatorDiv />
      <NavigatorButton
        onClick={() => {
          const relativePath = getNextBlog();
          if(relativePath) {
            navigate(`/blog/${relativePath}`)
          }
        }}
      >
        <NavigatorButtonText>
          Next
        </NavigatorButtonText>
        <FontAwesomeIcon
          icon={faCircleRight}
          title="Stop Audio"
          color="white"
          style={{marginLeft: '15px'}}
        />
      </NavigatorButton>
    </NavigatorButtonContainer>
  )
}

export default Navigator;