import React, { useEffect, useState } from 'react';
import { View, ScrollView, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import styled from 'styled-components/native';
import webStyled from 'styled-components';

import DataState from 'src/components/DataState';
import { Text } from 'src/styles';
import { numberWithDelimiter } from 'src/utils/stringHelpers';
import * as colors from 'src/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import AnnouncementIcon from '../Sales/Announcements/AnnouncementIcon';

import {
  BorderedContainer,
  DetailTitle,
  DetailSubtitle,
  Subtitle,
  Field,
  Label,
  Value,
  Going,
  GoingText,
  goingColor,
  Column,
} from './elements';
import { BIDDING_ASSET } from './queries';

import { Dimensions } from 'react-native-web';
import { ColumnContainer } from '../Headers/elements';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const photoUrl = photo => {
  if ('object' === typeof photo) {
    return photo.url;
  } else {
    return photo;
  }
};

const DetailsContainer = styled(ScrollView)`
  margin-left: 10px;
`;

const Details = styled(View)`
  flex-direction: column;
`;

const MainPhoto = styled(Pressable)`
  height: 260px;
  align-items: center;
  text-align: center;
`;

const CarouselContainer = styled(View)`
  flex-direction: row;
  min-width: 50%;
`;

const Carousel = styled(View)`
  flex-direction: column;
  max-height: 80px;
  gap: 5px;
`;

const Notes = styled(View)`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: black;
`;

const ChoiceContainer = styled(View)`
  flex-direction: row;
  gap: 10px;
`;

const ChoiceTab = styled(Pressable)`
  ${props => props.active ? `background-color: ${colors.elRed};` : ''}
  padding: 5px;
  min-width: 25%;
  border-radius: 5px;
`;

const ChoiceLabel = styled(Text)`
  ${props => props.active ? 'color: white;' : ''}
  font-weight: 600;
`;

export const AttributeGrid = webStyled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(1, 1fr);
  line-height: 22px;
  font-size: 16px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SaleAssetDetail = ({ saleAsset, groupSaleAssets}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [activeSaleAsset, setActiveSaleAsset] = useState(saleAsset);

  const { data } = useQuery(BIDDING_ASSET, {
    variables: { id: activeSaleAsset.asset.id }
  });

  useEffect(() => {
    if (saleAsset && saleAsset.id !== activeSaleAsset.id) {
      setActiveSaleAsset(saleAsset);
      setPhotoIndex(0);
    }
  }, [saleAsset, setActiveSaleAsset]);

  if (!data) { return <DataState.Loading />; }
  const { asset } = data;

  const allPhotos = [...asset.photos, ...asset.soarrPhotoUrls];

  const goToNextPhoto = () => {
    if (photoIndex >= allPhotos.length - 1) {
      setPhotoIndex(0);
    } else {
      setPhotoIndex(photoIndex + 1);
    }
  };

  const photo = allPhotos[photoIndex];

  const displayNotes = (asset.notes || '').split('\n').filter(note => {
    if (!note) { return false; }
    if (note.includes('proxibid')) { return false; }
    if (note.includes('Click on link')) { return false; }
    return true;
  });

  let attributes = asset.generalDetails?.unit?.attributes
  if (!attributes) {
    attributes = {
      'Engine Make': asset.engineMfr,
      'Engine Type': asset.engineType,
      'Engine HP': asset.horsepower,
      'Transmission': asset.transmission,
      'Axel': asset.axel,
      'Sleeper': asset.sleeper,
      'Suspension Type': asset.suspension,
      'Ratio': asset.ratio,
      'Wheels Rear': asset.wheelsRear,
      'Wheels Front': asset.wheelsFront,
      'Wheels Base': asset.wheelsBase,
    }
  }

  return (
    <>
      {groupSaleAssets && (
        <ChoiceContainer>
          {groupSaleAssets.map(entry => (
            <ChoiceTab
              key={entry.id}
              onPress={() => setActiveSaleAsset(entry)}
              active={activeSaleAsset.id === entry.id}
            >
              <ChoiceLabel active={activeSaleAsset.id === entry.id}>
                {entry.lotNumber}
              </ChoiceLabel>
            </ChoiceTab>
          ))}
        </ChoiceContainer>
      )}
      <Details style={{flexDirection: smallScreen ? 'column' : 'row'}}>
        {/* {smallScreen && */}
          <Column
            style={{
              maxWidth: smallScreen ? '100%' : '50%',
              marginRight: smallScreen ? 0 : '5px'
            }}
          >
            <DetailsContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: smallScreen ? 'column' : 'row',
                  justifyContent: smallScreen ? null : 'space-between',
                }}
              >
                <View style={{display: 'flex', flexDirection: 'row'}}>
                  <DetailTitle>{activeSaleAsset.lotNumber}</DetailTitle>
                  <AnnouncementIcon
                    saleAsset={activeSaleAsset}
                  />
                </View>
                <div>
                  <DetailSubtitle
                    style={{fontSize: smallScreen ? '15px' : '25px', fontWeight: 400}}
                  >
                    {asset.name}
                  </DetailSubtitle><br />
                  <DetailSubtitle>{asset.vin}</DetailSubtitle>
                </div>
              </div>
              <Notes>
                <ul>
                  <AttributeGrid>
                    {displayNotes.map(note => {
                      return (
                        <li style={{listStyle: 'disc', fontSize: '16px'}}>
                          {note}
                        </li>
                      )
                    })}
                  </AttributeGrid>
                </ul>
                {asset.hours ? (
                  <Text>HOURS {numberWithDelimiter(asset.hours)}</Text>
                ) : (
                  <Text>
                    ODOMETER: {asset.miles ?
                      numberWithDelimiter(asset.miles) :
                      'No odometer info entered'
                    }
                  </Text>
                )}
              </Notes>

              <AttributeGrid>
                {Object.keys(attributes).map(label => (
                  <React.Fragment key={label}>
                      {attributes[label] ? (
                        <Field>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            size='xs'
                            style={{color: colors.elRed, marginRight: '5px',
                              marginTop: '5px'
                            }}
                          />
                          <Label>{label}</Label>
                          <Value>{attributes[label]}</Value>
                        </Field>
                      ) : null}
                  </React.Fragment>
                ))}
              </AttributeGrid>

              {goingColor(saleAsset) && (
                <Going style={{ backgroundColor: goingColor(saleAsset) }}>
                  <GoingText>{saleAsset.biddingStatus}</GoingText>
                </Going>
              )}
            </DetailsContainer>
          </Column>
        {/* } */}
        <ColumnContainer style={{display: 'flex', flexDirection: 'row'}}>
          <div
            style={{
              display: 'flex',
              justifyContent: smallScreen ? 'center' : 'flex-start',
              flexDirection: 'row',
            }}
          >
            <Column style={{maxWidth: smallScreen ? '25%' : '10%', justifyContent: 'center'}}>
              {/* <CarouselContainer> */}
                <ScrollView vertical style={{maxWidth: '100%'}}>
                  <Carousel>
                    {allPhotos.map((photo, index) => (
                      <Pressable key={index} onPress={() => setPhotoIndex(index)}>
                        <img
                          src={photoUrl(photo)}
                          alt={asset.name}
                          style={{
                            maxWidth: '100%', maxHeight: '100%', borderRadius: '10px'
                          }}
                        />
                      </Pressable>
                    ))}
                  </Carousel>
                </ScrollView>
            </Column>
            <Column style={{maxWidth: smallScreen ? '50%' : '40%', alignContent: 'center'}}>
                {photoUrl(photo) && (
                  <MainPhoto
                    onPress={goToNextPhoto}
                    style={{maxWidth: '100%', justifyContent: 'center'}}
                  >
                    <img
                      src={photoUrl(photo)}
                      alt={asset.name}
                      style={{
                        maxWidth: '100%', maxHeight: '100%', borderRadius: '10px',
                        paddingLeft: '20px', justifySelf: 'flex-start'
                      }}
                    />
                  </MainPhoto>
                )}
              {/* </CarouselContainer> */}
            </Column>
          </div>
          {/* {!smallScreen &&
            <Column style={{maxWidth: smallScreen ? '100%' : '50%',
              left: smallScreen ? 0 : '50%'
            }}>
              <DetailsContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: smallScreen ? 'column' : 'row',
                    justifyContent: smallScreen ? null : 'space-between',
                  }}
                >
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <DetailTitle>{activeSaleAsset.lotNumber}</DetailTitle>
                    <AnnouncementIcon
                      saleAsset={activeSaleAsset}
                    />
                  </View>
                  <div>
                    <DetailSubtitle
                      style={{fontSize: smallScreen ? '15px' : '25px', fontWeight: 400}}
                    >
                      {asset.name}
                    </DetailSubtitle><br />
                    <DetailSubtitle>{asset.vin}</DetailSubtitle>
                  </div>
                </div>
                <Notes>
                  <ul>
                    <AttributeGrid>
                      {displayNotes.map(note => {
                        return (
                          <li style={{listStyle: 'disc', fontSize: '16px'}}>
                            {note}
                          </li>
                        )
                      })}
                    </AttributeGrid>
                  </ul>
                  {asset.hours ? (
                    <Text>HOURS {numberWithDelimiter(asset.hours)}</Text>
                  ) : (
                    <Text>
                      ODOMETER: {asset.miles ?
                        numberWithDelimiter(asset.miles) :
                        'No odometer info entered'
                      }
                    </Text>
                  )}
                </Notes>

                <AttributeGrid>
                  {Object.keys(attributes).map(label => (
                    <React.Fragment key={label}>
                        {attributes[label] ? (
                          <Field>
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              size='xs'
                              style={{color: colors.elRed, marginRight: '5px',
                                marginTop: '5px'
                              }}
                            />
                            <Label>{label}</Label>
                            <Value>{attributes[label]}</Value>
                          </Field>
                        ) : null}
                    </React.Fragment>
                  ))}
                </AttributeGrid>

                {goingColor(saleAsset) && (
                  <Going style={{ backgroundColor: goingColor(saleAsset) }}>
                    <GoingText>{saleAsset.biddingStatus}</GoingText>
                  </Going>
                )}
              </DetailsContainer>
            </Column>
          } */}
        </ColumnContainer>
      </Details>
    </>
  )
};

export default SaleAssetDetail;
