import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../components/Auth/queries';

const USER_ACTIVITY_FIELDS = gql`
  fragment UserActivityFields on UserActivity {
    id
    conversationId
    userId
    activity
    text
    userName
    time
    isDestroyed
  }
`;

export const USER_ACTIVITIES = gql`
  ${USER_ACTIVITY_FIELDS}
  query UserActivities {
    userActivities {
      ...UserActivityFields
    }
  }
`;

export const RECORD_USER_ACTIVITY = gql`
  mutation RecordUserActivity(
    $conversationId: ID!
    $activity: String!
    $text: String
  ) {
    recordUserActivity(
      conversationId: $conversationId
      activity: $activity
      text: $text
    ) {
      errors { path, message }
    }
  }
`;

export const USER_ACTIVITY_RECORDED = gql`
  ${USER_ACTIVITY_FIELDS}
  subscription UserActivityRecorded {
    userActivityRecorded {
      userActivity {
        ...UserActivityFields
      }
    }
  }
`;

export const USER = gql`
  query User {
    user {
      id
      email
      fullName
      phoneNumber
      phoneStatus
      active
      available
    }
  }
`;

export const AGENTS_AVAILABLE = gql`
  query AgentsAvailable {
    agentsAvailable {
      id
      email
      fullName
      phoneNumber
      phoneStatus
      active
      available
    }
  }
`;

export const AGENTS_AVAILABLE_SUBSCRIPTION = gql`
  subscription AgentsAvailable {
    agentsAvailable {
      id
      email
      fullName
      phoneNumber
      phoneStatus
      active
      available
    }
  }
`;
