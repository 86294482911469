import { gql } from '@apollo/client';


const ERRORS = `errors { path, message }`;

const ANNOUNCEMENT_ACCEPTANCE_FIELDS = `
  id
  buyerUser {
    id
    fullName
    buyer {
      id
      name
    }
  }
`;

const ANNOUNCEMENT_ACCEPTANCE_VARIABLES = `
  $announcementIds: [ID!]!
  $buyerUserId: ID!
`;

const ANNOUNCEMENT_ACCEPTANCE_ARGUMENTS = `
  announcementIds: $announcementIds
  buyerUserId: $buyerUserId
`;

const ANNOUNCEMENT_ACCEPTANCE_FIELDS_AND_ERRORS = `
  announcements {
    id
    body
    acceptances {
      id
      buyerUser {
        id
      }
    }
  }
  ${ERRORS}
`;

export const ACCEPT = gql`
  mutation AcceptAnnouncement(
    ${ANNOUNCEMENT_ACCEPTANCE_VARIABLES}
  ) {
    acceptAnnouncement(
      ${ANNOUNCEMENT_ACCEPTANCE_ARGUMENTS}
    ) {
      ${ANNOUNCEMENT_ACCEPTANCE_FIELDS_AND_ERRORS}
    }
  }
`;