import React, { useState } from 'react';
import {
  TiresContainer,
  TireContainer,
  TiresGroup,
  Tire,
  BlankTire,
  AxleContainer,
  LegendContainer,
  LegendItem
} from './elements';

const TireDisplay = ({ tires }) => {
  if(!tires) return 'There is no tire information currently available';

  const axleGroups = tires.reduce((axleGroups, item) => {
    const group = (axleGroups[item.axle] || []);
    group.push(item);
    axleGroups[item.axle] = group;
    return axleGroups;
  }, {});

  return (
    <TiresGroup>
      <TiresContainer>
        {Object.keys(axleGroups).map(axleNumber => {
          return (
            <AxleContainer key={`axle${axleNumber}`}>
              <TireContainer>
                {axleGroups[parseInt(axleNumber)].length === 2 &&
                  <><BlankTire /><BlankTire /></>
                }
                {axleGroups[parseInt(axleNumber)].length === 4 &&
                  <><BlankTire /></>
                }
                {axleGroups[parseInt(axleNumber)].map((tire, index) => {
                  return (
                      <React.Fragment key={index}>
                        {axleGroups[parseInt(axleNumber)].length / 2 === index &&
                          <BlankTire key={`blank-tire${index}`} />
                        }
                        <Tire
                          key={`tire${index}`}
                          style={
                            getTireStyle(
                              axleNumber,
                              axleGroups[parseInt(axleNumber)].length,
                              tire.positionOnAxle
                            )
                          }
                        >
                          {tire.treadDepth}
                          <br />
                          {tire.treadOriginAndType}
                        </Tire>
                      </React.Fragment>
                  )
                })}
                {axleGroups[parseInt(axleNumber)].length === 2 &&
                  <><BlankTire /><BlankTire /></>
                }
                {axleGroups[parseInt(axleNumber)].length === 4 &&
                  <><BlankTire /></>
                }
              </TireContainer>
            </AxleContainer>
          )
        })}
      </TiresContainer>
      <LegendContainer>
        <LegendItem>
          O = Original
        </LegendItem>
        <LegendItem>
          R = Recap
        </LegendItem>
        <LegendItem>
          RA = Radial
        </LegendItem>
        <LegendItem>
          B = Bias
        </LegendItem>
      </LegendContainer>
    </TiresGroup>
  )

  function getTireStyle(axleNum, axleLength, posOnAxle) {

    switch(axleLength) {
      case 2:
        return {
          marginLeft: posOnAxle === 2 ? '70px' : '-25px',
          marginBottom: parseInt(axleNum) === 1 ? '40px' : '0px'
        }
      case 4:
        return {

        }
      case 6:
        return {

        }
    }
  }
}

export default TireDisplay;
