import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { View, Pressable, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import * as colors from 'src/styles/colors';
import AnnouncementModal from 'src/elements/Modal/AnnouncementModal';

const AnnouncementIcon = ({saleAsset}) => {
  const [saleAssetAnnouncements, setSaleAssetAnnouncements] = useState(false);
  const [announcementAssetDetails, setAnnouncementAssetDetails] = useState(false);

  return (
    saleAsset.asset.announcements?.length > 0 &&
      <>
        <Pressable
          onPress={() => {
            setSaleAssetAnnouncements(saleAsset.asset.announcements)
            setAnnouncementAssetDetails(
              `${saleAsset.lotNumber} | ${saleAsset.asset.name}`
            )
          }}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            size={'1x'}
            color={colors.elRed}
            style={{marginLeft: '10px'}}
          />
        </Pressable>

        {saleAssetAnnouncements && createPortal(
          <AnnouncementModal
            showModal={saleAssetAnnouncements}
            setShowModal={setSaleAssetAnnouncements}
            modalType={`${announcementAssetDetails} Announcements`}
          >
            <View>
              {
                saleAssetAnnouncements.map(announcement => {
                  return <View key={announcement.id} style={{margin: '10px'}}>
                    <Text>{announcement.body}</Text>

                  </View>
                })
              }
            </View>
          </AnnouncementModal>, document.body
        )}
      </>
  )
}

export default AnnouncementIcon;
