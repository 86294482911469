import { DateTime } from 'luxon';

export const upcomingSale = sale => (
  sale && !['Processing', 'Closed'].includes(sale.status) &&
    (!sale.endAt || Date.parse(sale.endAt) > new Date().getTime())
);

export const preBidSale = sale => (
  sale && DateTime.fromISO(sale.startAt) > DateTime.now()
);

export const activeSale = sale => (
 upcomingSale(sale) && DateTime.fromISO(sale.startAt) <= DateTime.now() &&
   DateTime.fromISO(sale.startAt).plus({ days: 1 }) > DateTime.now()
);
