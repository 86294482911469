import React, { useContext, useEffect } from 'react';
import { View, Pressable } from 'react-native';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import AuthContext from 'src/contexts/AuthContext';
import { Button, Notice } from 'src/elements';
import Input from 'src/elements/inputs';
import { Text } from 'src/styles';
import { dollars } from 'src/utils/stringHelpers';

import { BUYER_USER_ASSET_WATCH } from './queries';

import {
  Container,
  TitleContainer,
  Title,
  Warning,
} from './PreBid';

const Content = ({ asset, currentUser, refetch }) => {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      assetId: asset.id,
      watching: true,
      emailAlert: (asset.buyerUserAssetView?.emailAlert || false),
    }
  });

  const watchAll = watch();

  const [buyerUserAssetWatch] = useMutation(BUYER_USER_ASSET_WATCH);

  useEffect(() => {
    if (currentUser?.buyer) {
      buyerUserAssetWatch({ variables: watchAll });
    }
  }, [watchAll.watching, watchAll.emailAlert]);

  if (!currentUser?.buyer) {
    return (
      <Link to="/login">
        <Warning>
          Click here to log in or register in order to watch asset.
        </Warning>
      </Link>
    );
  }

  return (
    <View>
      <Input.Switch
        label={`Watch ${asset.name}`}
        name="watching"
        control={control}
      />
      {watchAll.watching && (
        <Input.Switch
          label="Send me email alerts about this asset"
          name="emailAlert"
          control={control}
        />
      )}
    </View>
  );
};

const Watch = ({ asset, setShow, refetch }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Container>
      <TitleContainer>
        <Title>Watch This Asset</Title>
        <Pressable onPress={() => setShow(false)}>
          <Title>X</Title>
        </Pressable>
      </TitleContainer>

      <Content asset={asset} currentUser={currentUser} refetch={refetch} />
    </Container>
  );
};

export default Watch;
