import React, {useState} from 'react';
import { Pressable, SafeAreaView, ScrollView, View, Dimensions } from 'react-native'
import {
  DefaultModal,
  CenteredView,
  ModalView,
  ModalContainer,
  ModalHeader,
  HeaderText,
  ModalContent,
  CancelX,
} from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';

const Modal = props => {
  const {
    showModal,
    setShowModal,
    modalType,
    children,
    objectName,
  } = props;
  const screenHeight = Dimensions.get('window').height - 100
  return (
    <ModalContainer
      // onPress={() => setShowModal(false)}
    >
      <DefaultModal
        onRequestClose={() => setShowModal(false)}
        visible={showModal}
        transparent={true}
      >
        <CenteredView>
          <ModalView
            onPress={() => false}
          >
            <ModalHeader>
              <HeaderText>
                {`${modalType}`}{`${objectName ? ' - ' + objectName : ''}`}
              </HeaderText>
              {setShowModal && (
                <Pressable>
                  <CancelX>
                    <FontAwesomeIcon
                      icon={faSquareXmark}
                      size='lg'
                      style={{color: '#fff'}}
                      onClick={() => setShowModal(false)}
                    />
                  </CancelX>
                </Pressable>
              )}
            </ModalHeader>
            <ModalContent>
              {/* {getContent()} */}
              <View style={{ height: "auto", maxHeight: screenHeight, flex: 1 }}>
                <SafeAreaView>
                  <ScrollView contentContainerStyle={{flexGrow: 1}} nestedScrollEnabled={true}>
                    { children }
                  </ScrollView>
                </SafeAreaView>
              </View>
            </ModalContent>
          </ModalView>
        </CenteredView>
      </DefaultModal>
    </ModalContainer>
  )
}

export default Modal;
