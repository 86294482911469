import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';
import styled from 'styled-components';
import * as Colors from '../../styles/colors';

import { Dimensions } from 'react-native-web';
const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1180;

const CounterStyle = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.elRed};
  color: #fff;
  padding: 10px;
  justify-content: center;

  @media (max-width: 768px) {

  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    width: fit-content;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:landscape) {
    /* For portrait layouts only */
    width: 120%;
  }
`;

const Divider = styled.div`
  margin-top: 7.5px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    margin-top: 2px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:landscape) {
    /* For portrait layouts only */
    margin-top: 6px;
  }
`;

const NowContainer = styled.div`
  font-size: 16px;
  display: flex;
  font-weight: 600;
  width: 20%;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 90%;
  }

`;

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <CounterStyle>
      <DateTimeDisplay value={days} type={'Days'} />
      <Divider>:</Divider>
      <DateTimeDisplay value={hours} type={'Hours'} />
      <Divider>:</Divider>
      <DateTimeDisplay value={minutes} type={'Minutes'} />
      <Divider>:</Divider>
      <DateTimeDisplay value={seconds} type={'Seconds'} />
    </CounterStyle>
  );
};

const getPadding = () => {
  if(smallScreen) return '18px 135px 18px 135px';
  if(mediumScreen) return '14px 105px 14px 105px';
  return '18px 90px 18px 90px'
}

const Timer = ({ startDate }) => {
  const now = new Date() > new Date(startDate);
  const [days, hours, minutes, seconds] = useCountdown(startDate);

  if(now) {
    return (
      <NowContainer>
        <CounterStyle
          style={{
            padding: getPadding()
          }}
        >
          Now!
        </CounterStyle>
      </NowContainer>
    )
  }else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    )
  }
};

export default Timer;