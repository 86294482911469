import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Link, useNavigate } from 'react-router-dom';
import currencyFormatter from 'src/utils/currencyFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faImage
} from '@fortawesome/free-solid-svg-icons';

import {
  CardContainer,
  CardTitle,
  CardDetails,
  StockNumber,
  AuctionDate,
  BuyNowPrice,
  MediaContainer,
  SpecsContainer,
  SpecLine,
  LocationDetails
} from './elements';
import {
  Button
} from '../../elements/index';
import odometer from './odometer.png';
import engine from './engine.png';
import truck from './truck.png';
import { elRed, elLightGray } from 'src/styles/colors';
import styled from 'styled-components';
import VideoPlayer from './VideoPlayer';
import { numberWithDelimiter as delimited } from 'src/utils/stringHelpers';

const imageStyle = {
  // width: '100%',
  height: '350px',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '5px'
}

const iconStyle = {
  width: '30px',
  height: '20px'
}

const GalleryIconContainerLeft = styled.div`
  margin-right: -35px;
  z-index: 1;
`;

const GalleryIconContainerRight = styled.div`
  margin-left: -35px;
  z-index: 1;
`;

const galleryIconStyle = {
  display: 'flex',
  color: '#fff',
  backgroundColor: elRed,
  minWidth: '15px',
  minHeight: '15px',
  borderRadius: '50%/50%',
  padding: '5px',
  fontWeight: 'bold',
  cursor: 'pointer'
}

const disabledGalleryIconStyle = {
  display: 'flex',
  color: '#fff',
  backgroundColor: elRed,
  minWidth: '15px',
  minHeight: '15px',
  borderRadius: '50%/50%',
  padding: '5px',
  fontWeight: 'bold',
  opacity: 0.5,
  cursor: 'pointer'
}

const NullImage = styled.div`
  height: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InventoryCard = ({asset, buyNow, saleId}) => {
  const slug = asset => (
    asset.name.replace(/[^0-9a-z]+/gi, '-')
  );
  const navigate = useNavigate();
  const [mediaIndex, setmediaIndex] = useState(0);
  let allMedia = [...asset?.photos, ...asset?.soarrPhotoUrls];
  if(asset?.soarrVideoUrls?.length > 0) {
    allMedia = [...allMedia, ...asset?.soarrVideoUrls]
  }
  const media = allMedia[mediaIndex];

  const dateFormat = {...DateTime.DATETIME_MED, month: ''}

  const mediaUrl = photo => {
    if ('object' === typeof photo) {
      return photo.url;
    } else {
      return photo;
    }
  };

  const videoOrPhoto = () => {
    if('object' === typeof media &&
      media['type'] && ['youtube'].includes(media['type'])
    ) {
      return <VideoPlayer video={media} />
    } else if(mediaUrl(media)){
      return <img src={ mediaUrl(media) } style={imageStyle} />
    }else {
      return (
        <NullImage>
          <FontAwesomeIcon
            icon={faImage}
            size='2xl'
            style={{color: elRed}}
          />
        </NullImage>
      )
    }
  }

  // Don't display past sale info
  let saleOn = asset.activeSaleAsset?.sale?.saleOn ?
    DateTime.fromISO(asset.activeSaleAsset.sale.saleOn) : null;

  let saleStartAt = asset.activeSaleAsset?.sale?.startAt ?
    DateTime.fromISO(asset.activeSaleAsset.sale.startAt) : null;

  if (saleOn && saleOn < DateTime.now().minus({ days: 2 })) {
    saleOn = null;
    saleStartAt = null;
  }

  let path = saleId ?
    `/sales/${saleId}/inventory/${asset.id}/${slug(asset)}` :
    `/inventory/${asset.id}/${slug(asset)}`;
  if (buyNow) { path = path + '/true'; }

  return(
    <CardContainer>
      <MediaContainer>
        <GalleryIconContainerLeft>
          {allMedia.length ?
            <FontAwesomeIcon
              icon={faChevronLeft}
              size='sm'
              onClick={() => {
                if(mediaIndex > 0) {
                  let newmediaIndex = mediaIndex;
                  newmediaIndex -= 1;
                  setmediaIndex(newmediaIndex)
                }
              }}
              style={
                mediaIndex > 0 ? galleryIconStyle : disabledGalleryIconStyle
              }
            /> :
            null
          }
        </GalleryIconContainerLeft>
        {videoOrPhoto()}
        <GalleryIconContainerRight>
          {allMedia.length ?
            <FontAwesomeIcon
              icon={faChevronRight}
              size='sm'
              onClick={() => {
                if(mediaIndex < allMedia?.length - 1) {
                  let newmediaIndex = mediaIndex;
                  newmediaIndex += 1;
                  setmediaIndex(newmediaIndex)
                }
              }}
              style={
                mediaIndex < allMedia?.length - 1 ?
                  galleryIconStyle :
                  disabledGalleryIconStyle
              }
            /> :
            null
          }
        </GalleryIconContainerRight>
      </MediaContainer>
      <AuctionDate>
        Starts:
        <span
          style={{
            marginLeft: '5px',
          }}
        >
          {saleStartAt ? saleStartAt.toFormat('LLL dd, h:mma') : 'TBA'}
        </span>
      </AuctionDate>
      <CardTitle>
        <Link
          to={path}
          style={{
            textDecoration: 'none',
            color: '#000',
            fontWeight: 600
          }}
        >
          {asset.name}
        </Link>
        <BuyNowPrice>
          <span
            style={{
              color: 'red',
              marginLeft: '5px',
              fontWeight: 'bold'
            }}
          >
            {`SALE PRICE: ${
              asset.listPrice && asset.listPrice > 0 ?
                currencyFormatter(parseFloat(asset.listPrice)) :
                'CALL'
            }`}
          </span>
        </BuyNowPrice>
      </CardTitle>
      <CardDetails>
        <StockNumber>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {saleOn && asset.lotNumber ?
              `Lot Number: ${asset.lotNumber}` :
              `Stock Number: ${asset.stockNumber}`
            }
          </div>
        </StockNumber>
      </CardDetails>
      <LocationDetails>
        <div style={{fontWeight: 'bold'}}>
          {`Asset Location: `}
        </div>
        <div>
          {getAddress()}
        </div>
      </LocationDetails>
      <SpecsContainer>
        {asset.miles ?
          <SpecLine>
            <img src={odometer} style={iconStyle} />
            <br />
            Odometer: {`${delimited(asset.miles)} mi`}
          </SpecLine> :
          null
        }
        {asset.engineType ?
          <SpecLine>
            <img src={engine} style={iconStyle} />
            <br />
            Engine: {asset.engineType}
          </SpecLine> :
          null
        }
        {(asset.segement || asset.subCategory) && asset.category ?
          <SpecLine>
            <img src={truck} style={iconStyle} />
            <br />
            Type: {asset.category}
          </SpecLine> :
          null
        }
        {!asset.miles && !asset.engineType &&
          !(asset.segement || asset.subCategory || asset.category) &&
          <div style={{height: '50px'}}></div>
        }
      </SpecsContainer>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Button
          onClick={() => navigate(path)}
          style={{
            width: '90%',
            alignSelf: 'center',
            border: '1px solid black',
            marginBottom: '0px',
            backgroundColor: elLightGray,
            color: '#000',
            cursor: 'pointer'
          }}
        >
          View Details
        </Button>
        {buyNow ?
          <Button
            onClick={() => window.location.replace(path)}
            style={{
              width: '90%',
              alignSelf: 'center',
              marginBottom: '10px',
              backgroundColor: elRed,
              color: '#fff',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
          >
            Buy Now
          </Button> :
          <div style={{height: '37px'}}></div>
        }
      </div>
    </CardContainer>
  )

  function getAddress() {
    if (asset.details && asset.details['physicalLocation']) {
      return asset.details['physicalLocation'].toUpperCase();
    } else if (asset.location?.city) {
      return `${asset.location.city}, ${asset.location.state}`.toUpperCase();
    } else if (asset.city) {
      return `${asset.city}, ${asset.state}`.toUpperCase();
    } else {
      return '';
    }
  }
}

export default InventoryCard;
