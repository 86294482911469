const config = {
  api: {
    baseUri: process.env.REACT_APP_BASE_URI,
  },
  pti: {
    token: 'vjqczhfP7Ig90Rh5TzwyT8q4R6FaRRvp',
    issueUrl: 'https://preflighttech.com/api/1/issues'
  },
  app: {
    name: 'el-web',
  }
};

export default config;
