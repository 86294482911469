import React from 'react';
import styled from 'styled-components';
import hero from './locationsHero.png';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  ImageContainer,
  RegularText
} from './elements';
import FinanceLink from '../FinanceLink';

const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  background-size: 25%;
`;

const LocationsHeader = () => {
  return (
    <>
      <Container>
        <ColumnContainer>
          <LeftColumn>
            {/* <DotGrid>
              <img src={dotGrid} style={{}} />
            </DotGrid> */}
            <TextContainer>
              <HeaderLabel>
                Our Locations
              </HeaderLabel>
              <RedSeparator />
              <RegularText>
                EquipLinc Auction Group has locations across the United States...
              </RegularText>
            </TextContainer>
          </LeftColumn>
          <ImageContainer>
            {/* <TintOverlay>

            </TintOverlay> */}
            <img src={hero} alt='Equiplinc Locations'
              style={{
                width: '100%',
              }}
            />
          </ImageContainer>
        </ColumnContainer>
      </Container>
      <FinanceLink containerMargin='10px' />
    </>
  )
}

export default LocationsHeader;