import React, { useRef, useEffect, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Polyline, Marker } from '@react-google-maps/api';
import { Dimensions } from 'react-native-web';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

function Map({locations}) {
  const center = {lat: 39.0473, lng: -95.6752};
  const containerStyle = {
    width: '100%',
    minHeight: '400px'
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
  })

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
  return isLoaded && locations ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={smallScreen ? 3.25 : 4.5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {locations?.map(location => {
          return (
            <Marker
              position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)}}
            />
          )
        })}
      </GoogleMap>
  ) : <></>
}

export default Map