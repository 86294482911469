import { gql } from '@apollo/client';

import {
  BUYER_CORE_FIELDS,
  BUYER_USER_CORE_FIELDS
} from 'src/components/Auth/queries';

export const UPDATE_CURRENT_BUYER_USER = gql`
  ${BUYER_USER_CORE_FIELDS}
  mutation UpdateCurrentBuyerUser(
    $firstName: String
    $lastName: String
    $password: String
    $passwordConfirmation: String
  ) {
    updateCurrentBuyerUser(
      firstName: $firstName
      lastName: $lastName
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      buyerUser {
        ...BuyerUserCoreFields
      }
      errors { path, message }
    }
  }
`;

export const UPDATE_CURRENT_BUYER = gql`
  ${BUYER_CORE_FIELDS}
  mutation UpdateCurrentBuyer(
    $name: String!
    $companyName: String
    $street: String!
    $city: String!
    $state: String!
    $postalCode: String!
    $phoneNumber: String!
  ) {
    updateCurrentBuyer(
      name: $name
      companyName: $companyName
      street: $street
      city: $city
      state: $state
      postalCode: $postalCode
      phoneNumber: $phoneNumber
    ) {
      buyer {
        ...BuyerCoreFields
      }
      errors { path, message }
    }
  }
`;
