import styled from 'styled-components/native';
import { useField } from 'formik';
import { View, Text } from 'react-native';
import webStyled from 'styled-components';

import colors, { elGray } from 'src/styles/colors';
import { Link } from 'react-router-dom';

const { elRed } = colors;

export const ErrorMessage = styled(View)`
  color: red;
  border: 2px solid red;
  padding: 4px;
  margin-bottom: 6px;
`;

export const Label = styled(Text)`
  display: block;
  color: ${elRed};
  font-weight: 500;
  margin-top: 12px;
`;

export const Button = webStyled.button`
  background-color: ${elRed};
  color: white;
  border: 0px;
  border-radius: 4px;
  height: 37px;
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
`;

export const NarrowButton = webStyled(Link)`
  background-color: ${props => props.contact ? '#000' : '#fff'};
  color: ${props => props.contact ? '#fff' : '#000'};
  border: 1px solid black;
  border-radius: 4px;
  height: 37px;
  font-size: 0.9rem;
  font-weight: 800;
  width: 35%;
  margin-top: 16px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelButton = webStyled.div`
  background-color: white;
  color: ${elRed};
  margin-top: 16px;
  border: 1px solid ${elRed};
  cursor: pointer;
`;

const StyledInput = webStyled.input`
  display: block;
  width: 100%;
  padding: 0px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1rem;

  .gray-input & {
    background-color: #EFEFEF;
    border: none;
  }
`;

const FieldError = styled(Text)`
  color: red;
`;

export const LinkSpace = webStyled.div`
  width: 5%;
`;

export const Input = props => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledInput
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <FieldError>{meta.error}</FieldError>
      )}
    </>
  );
};

export const HR = webStyled.div`
  width: 100%;
  height: 30px;
  border-bottom: 1px solid ${elGray};
`;

export const BoldTitle = webStyled.div`
  font-size: 1.2em;
  font-weight: 700;
`;

export const LeftColumn = webStyled.div`

`;

export const RightColumn = webStyled.div`

`;

export const SectionHeader = webStyled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;

  @media(min-width: 768px) {
    font-size: 50px;
    line-height: 69px;
  }
`;

export const SectionSubHeader = webStyled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  color: black;
  font-weight: 600;
  font-size: 25px;
`;

export const Section = webStyled.div`
  min-width: 100%;
  display: grid;
  grid-gap: 10px;
  margin-bottom: 30px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

export const FeaturesSection = webStyled.div`
  min-width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Feature = webStyled.div`

`;

export const SectionDetails = webStyled.div`
  min-width: 95%;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  border-bottom: 1px solid ${elGray};
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 49.75% 50%);
  }
`;

export const UnevenSectionDetails = webStyled.div`
  min-width: 100%;
  display: grid;
  grid-gap: 10px;
  border-bottom: 1px solid ${elGray};
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 49.75% 50%);
  }
`;

export const SectionLabel = webStyled.div`
  font-weight: bold;
`;

export const SectionDetail = webStyled.div`

`;

const NoticeContainer = styled(View)`
  border-width: 2px;
  border-style: sold;
  padding: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentContainer = styled(View)`
  padding: 40px;
`;

export const PageTitle = styled(Text)`
  font-weight: bold;
  color: ${elRed};
  font-size: 24px;
`;

export const MainContainer = styled(View)`
  @media (max-width: 600px) {
    margin: 5px;
  }
`;

export const Notice = ({ type, children }) => {
  let borderColor = 'green';
  if ('warning' === type) {
    borderColor = 'orange';
  } else if ('error' === type) {
    borderColor = 'red';
  }

  return (
    <NoticeContainer style={{borderColor}}>
      <Text style={{fontWeight: 600}}>{children}</Text>
    </NoticeContainer>
  );
};
