import React from 'react';
import styled from 'styled-components';
import hero from './aboutUsHero.png';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  ImageContainer,
  RegularText
} from './elements';
import FinanceLink from '../FinanceLink';

const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  background-size: 25%;
`;

const AboutUsHeader = () => {
  return (
    <>

      <Container>
        <ColumnContainer>
          <LeftColumn>
            <TextContainer>
              <HeaderLabel>
                About Us
              </HeaderLabel>
              <RedSeparator />
              <RegularText>
                EquipLinc Auction Group provides a wide range of Remarketing
                Services as it relates to the Heavy Truck, Equipment, & Commercial
                Asset businesses respectively. With over 85 years of experience by
                our management team in the Heavy Truck & Equipment Industry our
                primary function is to serve you, the customer in all aspects.
              </RegularText>
            </TextContainer>
          </LeftColumn>
          <ImageContainer>
            {/* <TintOverlay>

            </TintOverlay> */}
            <img src={hero} alt='Equiplinc Locations'
              style={{
                width: '100%',
              }}
            />
          </ImageContainer>
        </ColumnContainer>
      </Container>
      <FinanceLink containerMargin='10px' />
    </>
  )
}

export default AboutUsHeader;