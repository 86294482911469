import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AuthContext from 'src/contexts/AuthContext';
import { Loading } from 'src/components/DataState';
import { Text } from 'src/styles';
import { Button, Notice } from 'src/elements';
import { FormContainer, Fields, defaultValues } from 'src/elements/forms';

import AccountStatus from './Status';
import { UPDATE_CURRENT_BUYER } from './queries';

export const FIELDS = {
  name: {
    label: 'Individual or Company Name',
    rules: { required: true }
  },
  companyName: { label: 'Company Name (if different)' },
  street: { rules: { required: true } },
  city: { rules: { required: true } },
  state: {
    rules: {
      required: true,
      maxLength: { value: 2, message: 'Use 2-character abbreviation' }
    }
  },
  postalCode: { rules: { required: true } },
  phoneNumber: { rules: { required: true } },
  email: { label: 'Billing Email', rules: { required: true } },
};

const UpdateBuyer = () => {
  const navigate = useNavigate();

  const {
    currentUser,
    isLoggedIn,
    refetchCurrentUser,
    checkedCredentials,
  } = useContext(AuthContext);

  const [formStatus, setFormStatus] = useState();

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  useEffect(() => {
    if (currentUser) {
      reset(defaultValues({ fields: FIELDS, resource: currentUser.buyer }));
    }
  }, [currentUser]);

  const [updateCurrentBuyer] = useMutation(UPDATE_CURRENT_BUYER, {
    onCompleted: () => {
      refetchCurrentUser();
      navigate('/account/billing-updated');
    }
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    updateCurrentBuyer({ variables: input });
  };

  if (!checkedCredentials) { return <Loading />; }

  if (!isLoggedIn && !currentUser) {
    navigate('/login');
    return null;
  }

  return (
    <View>
      <AccountStatus onBilling />
      <FormContainer onEnter={handleSubmit(onSubmit)} style={{width: '100%'}}>
        {'success' === formStatus && (
          <Notice type="success">
            Your Billing Information has been updated.
          </Notice>
        )}
        <Text>Billing Information</Text>
        <Fields fields={FIELDS} control={control} errors={errors} />
        {'submitting' === formStatus ? (
          <Loading />
        ) : (
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        )}
      </FormContainer>
    </View>
  );
};

export default UpdateBuyer;
