import React, { useContext } from 'react';
import {
  CardDate, CardTitle, CardContentContainer, MainPhotoContainer
} from './elements';
import moment from 'moment';
import { Tag, BlogButton } from './elements';
import { View, Text, Pressable } from 'react-native';
import BlogContext from 'src/contexts/BlogContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { elGray } from 'src/styles/colors';
import logo from '../Menu/logo.png';

const slug = blog => {
  return (
    blog.title.replace(/[^0-9a-z]+/gi, '-')
  );
}

const FeaturedCard = ({blog, refetch, fromShow}) => {
  const { setCurrentBlogTag, featuredLabel } = useContext(BlogContext);
  const navigate = useNavigate();

  const handleTagPress = (name) => {
    setCurrentBlogTag(name);
    navigate('/blog');
  }

  return (
    <Pressable
      onPress={() => navigate(`/blog/${blog.id}/${slug(blog)}`)}
    >
      <View
        style={{
          display: 'flex', flexDirection: 'row', maxWidth: '100%',
          borderBottom: `3px solid ${elGray}`, paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <MainPhotoContainer
          fromShow={fromShow}
          width='50%'
          alignItems='flex-start'
        >
          <img src={blog?.mainPhoto?.url || logo}
            style={{
              maxWidth: '100%',
              maxHeight: '50vh',
              objectFit: 'contain',
              borderRadius: '10px'
            }}
          />
        </MainPhotoContainer>
        <View
          style={{
            display: 'flex', flex: 1, flexDirection: 'column', marginTop: '10px',
            paddingLeft: '10px', paddingRight: '10px'
          }}
        >
          <CardTitle fontSize='24px'>{blog.title}</CardTitle>
          {/* <CardDate fontSize='16px'>
            <FontAwesomeIcon
              icon={faCalendarAlt}
              title="Created On"
              color="black"
              style={{marginRight: '10px'}}
            />
            {moment(blog.createdAt).format('MMMM Do YYYY')}
          </CardDate> */}
        </View>
      </View>
    </Pressable>
  )
}

export default FeaturedCard;