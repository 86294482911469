import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import * as Colors from 'src/styles/colors';
import { Dimensions } from 'react-native-web';
import ResourcesContext from 'src/contexts/ResourcesContext';
import { useNavigate } from 'react-router-dom';
import './carouselStyles.css';
import { Pressable } from 'react-native-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight, faChevronCircleLeft} from '@fortawesome/pro-solid-svg-icons';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1180;
const picWidth = smallScreen ? 100 : 150;

const Container = styled.div`
  width: 100%;
  color: #fff;
  padding: 0 1% 5% 1%;
  background-repeat: no-repeat;
  max-height: 150px;
  max-width: 100%;

  @media (max-width: 768px) {

    margin-top: 15px;
    padding-bottom: 0;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1180px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 14px;
    background-color: ${Colors.elRed};

  }
`;

const iconStyle = {
  width: '30px',
  height: '20px',
  color: '#fff'
};

const imageStyle = {
  // height: smallScreen ? '175px' : '350px',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: '20px'
}

const unSelectedPhoto = {
  flex: 1,
  maxWidth: '150px',
  maxHeight: '150px',
  height: picWidth + 'px',
  width: picWidth + 'px',
  marginTop: '10px',
  padding: '0 10px'
}

const HeaderLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  padding-left: 5%;

  @media (min-width: 1030px) {
    font-size: 30px;
  }
`;

const PrimaryCarousel = styled.div`
  display: flex;
  max-width: 100%;
  min-width: fit-content;
  position: relative;
  gap: ${props => `${props.gap}px`};
  animation: scroll-horizontal 30s linear infinite;
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  animation-timing-function: linear;
  @keyframes scroll-horizontal {
    0%{
      left: 0%;
      transform: translateX(30px);
    }

    100% {
      left: ${props => `-${props.offset + (props.gap + smallScreen || mediumScreen ? picWidth + (props.gap/2) :
        props.full ? (picWidth / 2) - ((picWidth/2) + props.gap) : 0)}px`};
      transform: translateX(30px);
    }
  }
`;

const SecondaryCarousel = styled.div`
  display: flex;
  max-width: 100%;
  min-width: fit-content;
  position: relative;
  gap: ${props => `${props.gap}px`};
  animation: scroll-horizontal 30s linear infinite;
  margin-left: ${props => `${props.gap + (smallScreen || mediumScreen ? picWidth + (props.gap/2) :
    props.full ? (picWidth / 2) - ((picWidth/2) + props.gap) : 0)}px`};
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  animation-timing-function: linear;
`;

const buttonStyle = {
  marginTop: '25px', zIndex: 10
}

const NewArrivals = ({assets}) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef();
  const primary = useRef();
  const secondary = useRef();
  const { clearAssets } = useContext(ResourcesContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [secondaryStyle, setSecondaryStyle] = useState({});
  const [manualDirection, setManualDirection] = useState();
  const [gap, setGap] = useState(0);

  let asset = assets[currentIndex];

  const goToNextMedia = () => {
    if (mediaIndex >= assets.length - 1) {
      setMediaIndex(0);
    } else {
      setMediaIndex(mediaIndex + 1);
    }
  };

  useEffect(() => {
    if(assets) {
      let intervalId = setInterval(() => {
        setCurrentIndex(currentIndex < assets.length - 1 ? currentIndex + 1 : 0);
      }, 7500);

      return () => clearInterval(intervalId);
    }
  }, [currentIndex]);

  useEffect(() => {
    if(secondary) {
      setSecondaryStyle({
        // "--left-offset": smallScreen || mediumScreen ? 90 : 33.5,
        // "--offset": smallScreen || mediumScreen ? 175 : 133.5
      })
    }
  }, [secondary])

  useEffect(() => {
    if(manualDirection !== null) {
      // setManualDirection();
    }
  }, [manualDirection])

  useEffect(() => {
    if(scrollContainerRef?.current) setGap(getGap());
  }, [scrollContainerRef]);

  if(!asset) return null

  const getSlug = asset => (
    asset.name.replace(/[^0-9a-z]+/gi, '-')
  );

  const getGap = () => {
    let totalPicWidth = assets.length * picWidth;
    return (scrollContainerRef?.current?.clientWidth - totalPicWidth) / assets.length;
  }

  const getOffset = () => {
    let scrollWidth = scrollContainerRef?.current?.clientWidth || 0;
    let offsetWidth = primary?.current?.clientWidth + (gap * 2);
    if(offsetWidth < scrollWidth) return 0;
    return offsetWidth;
  }

  return (
    <Container>
      <HeaderLabel>New Arrivals - Coming to an Auction Soon</HeaderLabel>
      <div className="scroll-container"
        ref={scrollContainerRef}
        // onMouseLeave={() => { if(manualDirection) setManualDirection() }}
      >
        {/* <Pressable
          style={buttonStyle}
          onPress={() => setManualDirection('go-back')}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            size='2x'
            style={{color: '#fff', marginTop: '10px' }}
          />
        </Pressable> */}
        {/* <div class={`carousel-primary ${manualDirection}`}> */}
        <PrimaryCarousel
          className='carousel-primary'
          offset={getOffset() - gap}
          ref={primary}
          gap={gap}
          full={picWidth * assets?.length > scrollContainerRef?.current?.clientWidth}
        >
          {assets?.map((a, index) => {
            return (
              <div
                key={index}
                onClick={() => navigate(`/inventory/${a.id}/${getSlug(a)}/false`)}
                className='image'
              >
                <img
                  src={a.primaryPhotoUrl}
                  alt={a.name}
                  style={unSelectedPhoto}
                />
              </div>
            )
          })}
        </PrimaryCarousel>
        <SecondaryCarousel
          className={`carousel-primary carousel-secondary`}
          style={secondaryStyle}
          ref={secondary}
          offset={(getOffset() * 2)}
          secondary={true}
          marginLeft={primary?.current?.clientWidth}
          gap={gap}
          full={picWidth * assets?.length > scrollContainerRef?.current?.clientWidth}
        >
          {assets?.map((a, index) => {
            return (
              <div
                key={index}
                onClick={() => navigate(`/inventory/${a.id}/${getSlug(a)}/false`)}
                className='image'
              >
                <img
                  src={a.primaryPhotoUrl}
                  alt={a.name}
                  style={unSelectedPhoto}
                />
              </div>
            )
          })}
        </SecondaryCarousel>
        {/* <Pressable
          style={{...buttonStyle, marginLeft: '-142%', marginTop: '32.5px'}}
          onPress={() => null}
        >
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            size='2x'
            style={{color: '#fff'}}
          />
        </Pressable> */}
      </div>
    </Container>
  )
}

export default NewArrivals;
