import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { BLOG } from './queries';
import {
  BlogContainer,
  ContentContainer,
  SideBar,
  NavigatorContainer
} from './elements';
import Card from './Card';
import DataState from '../DataState';
import { MainContainer} from 'src/elements';
import { Dimensions } from 'react-native';
import Navigator from './Navigator';
import Featured from './Featured';
import SearchInput from './SearchInput';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Blog = () => {
  const { id } = useParams();

  const { loading, data, refetch } = useQuery(
    BLOG, { variables: { id: id } }
  );

  if(loading) return <DataState.Loading />;

  return (
    <MainContainer>
      <NavigatorContainer>
        <Navigator />
      </NavigatorContainer>
      <BlogContainer flexDirection={smallScreen ? 'column' : 'row'}>
        <ContentContainer fromShow={true}
          style={{
            // flexDirection: smallScreen ? 'column' : 'row',
            width: smallScreen ? '100%' : '75%',
            maxWidth: '100%',
            flexWrap: 'wrap'
          }}
        >
          <Card blog={data?.blog} fromShow={true} />
        </ContentContainer>
        <SideBar fromShow={true}
          width={smallScreen ? '100%' : '50%'}
          maxWidth={smallScreen ? '100%' : '25%'}
        >
          <SearchInput />
          <Featured refetch={refetch} />
        </SideBar>
      </BlogContainer>
    </MainContainer>
  )
}

export default Blog;