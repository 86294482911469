import React, { useContext } from 'react';
import { View, Pressable } from 'react-native';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';

import AuthContext from 'src/contexts/AuthContext';
import { Button, Notice } from 'src/elements';
import Input from 'src/elements/inputs';
import { Text } from 'src/styles';
import { dollars } from 'src/utils/stringHelpers';

import { CREATE_PRE_BID } from './queries';

export const Container = styled(View)`
  margin-top: 20px;
  padding: 10px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  border-radius: 4px;
`;

export const TitleContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled(Text)`
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 600;
`;

export const Warning = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  color: red;
`;

const TYPE_OPTIONS = [
  { value: 'Normal', label: 'Pre-Bid' },
  { value: 'Max', label: 'Max Bid' },
];

const Content = ({ asset, currentUser, refetch }) => {
  const { preBidData } = asset;

  let defaultPrice = preBidData?.preBidStartPrice;
  let minPrice = preBidData?.preBidStartPrice;

  if (preBidData?.highPreBidPrice) {
    defaultPrice = preBidData?.highPreBidPrice + preBidData?.preBidDefaultStep;
    minPrice = preBidData?.highPreBidPrice + preBidData?.preBidMinStep;

    if (preBidData?.preBidStartPrice > minPrice) {
      defaultPrice = preBidData?.preBidStartPrice;
      minPrice = preBidData?.preBidStartPrice;
    }
  }

  const prices = [];
  let currentPrice = minPrice;

  while (currentPrice <= defaultPrice) {
    prices.push(currentPrice);
    currentPrice = currentPrice + preBidData.preBidMinStep;
  }

  const priceOptions = prices.reverse().map(price => (
    { value: price.toString(), label: dollars(price) }
  ));

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {
      saleAssetId: asset.activeSaleAsset.id,
      type: 'Normal',
      price: defaultPrice.toString(),
    }
  });

  const type = watch('type');

  const [createPreBid] = useMutation(CREATE_PRE_BID, {
    onCompleted: refetch
  });

  const onSubmit = input => {
    const confirmation =
      `Confirm ${'Normal' === input.type ? 'Pre-Bid' : 'Max Bid'} of ` +
      `${dollars(input.price)} for ${asset.name}`;

    if (window.confirm(confirmation)) {
      input.price = parseInt(input.price);
      createPreBid({ variables: input });
    }
  };

  if (!currentUser?.buyer) {
    return (
      <Link to="/login">
        <Warning>
          Click here to log in or register in order to bid.
        </Warning>
      </Link>
    );
  } else if ('Review' === currentUser.buyer.status) {
    return (
      <Warning>
        Your account is currently being reviewed for approval to bid.
      </Warning>
    );
  } else if ('New' === currentUser.buyer.status) {
    return (
      <>
        <Warning>
          You must provide billing information before we can review
          your account.
        </Warning>
        <Link to="/account/billing">
          <Warning>
            Click Here to finish setting up your account.
          </Warning>
        </Link>
      </>
    );
  } else if (currentUser.buyer.isApproved) {
    return (
      <View>
        {preBidData.myPreBid && (
          <Text style={{ fontWeight: 600 }}>
            Your current{' '}
            {'Normal' === preBidData.myPreBid ? 'Pre-Bid' : 'Max Bid'}:{' '}
            {dollars(preBidData.myPreBid.price)}
          </Text>
        )}
        {preBidData.highPreBid && (
          <Text>
            Current high pre-bid: {dollars(preBidData.highPreBidPrice)}
          </Text>
        )}
        <View style={{flexDirection: 'row', gap: 30, marginTop: 20 }}>
          <Input.Picker
            label="Bid Type:"
            name="type"
            control={control}
            errors={errors}
            options={TYPE_OPTIONS}
            rules={{ required: true }}
          />
          {'Normal' === type ? (
            <Input.Picker
              label="Price:"
              name="price"
              control={control}
              errors={errors}
              options={priceOptions}
              rules={{ required: true }}
            />
          ) : (
            <Input.Currency
              label="Price:"
              name="price"
              control={control}
              errors={errors}
              rules={{
                required: true,
                validate: value => (
                  (
                    parseInt(value) >= minPrice &&
                    0 === value % preBidData.preBidMinStep
                  ) || (
                    `Must be at least ${dollars(minPrice)} in steps of ` +
                    `${dollars(preBidData.preBidMinStep)}`
                  )
                )
              }}
            />
          )}
          <Button
            onClick={handleSubmit(onSubmit)}
            style={{maxWidth: 200}}
          >
            Submit Bid
          </Button>
        </View>
      </View>
    );
  }
};

const PreBid = ({ asset, setShow, refetch }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Container>
      <TitleContainer>
        <Title>Enter Pre-Bid</Title>
        <Pressable onPress={() => setShow(false)}>
          <Title>X</Title>
        </Pressable>
      </TitleContainer>

      <Content asset={asset} currentUser={currentUser} refetch={refetch} />
    </Container>
  );
};

export default PreBid;
