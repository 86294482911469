// https://benjaminbrooke.me/posts/react-form-validation-with-graphql-formik-and-yup/

export const normalizeErrors = errors => {
  return errors.reduce((accumulator, value) => {
    if ('attributes' === value.path[0]) {
      accumulator[value.path[1]] = value.message;
    } else {
      accumulator[value.path] = value.message;
    }

    return accumulator;
  }, {});
};

export const formatError = error => {
  return error && error[0].toUpperCase() + error.slice(1)
};

export const getServerSideErrors = values => {
  const key = Object.keys(values)[0];
  const errors = values[key].errors;
  if (errors.length) {
    let errorString = '';
    let errorName = '';
    let errorMessage = '';
    errors.forEach((error, index) => {
      errorName = error.path[1];
      errorMessage = error.message;
      const separator = index > 0 ? ', ' : '';
      errorString += `${separator} ${errorName} ${errorMessage}`;
    });

    return errorString;
  } else {
    return '';
  }
};

// export const getFlCodeServerSideErrors = values => {
//   const key = Object.keys(values)[0];
//   const errors = values[key].errors;
//   if (errors.length) {
//     let errorString = '';
//     let errorName = '';
//     let errorMessage = '';
//     errors.forEach((error, index) => {
//       errorName = error.path[1];
//       errorMessage = error.message;

//       const separator = index > 0 ? ', ' : '';
//       const fl = errorName.substring(0, 2)
//       const airport = errorName.substring(2, 9)
//       const code = errorName.substring(9, 13)

//       errorString += `
//         ${separator}
//         ${fl}
//         ${airport}
//         ${code}
//         ${errorMessage}
//       `
//     });

//     return errorString;
//   } else {
//     return '';
//   }
// };

export const formatAuthErrors = errors => {
  const messages = errors?.map(({ message, extensions }) => {
    if (extensions?.detailed_errors) {
      return message + '. ' + extensions.detailed_errors.join('. ')
    } else {
      return message;
    }
  });

  return messages.join(' ');
};
