import { gql } from '@apollo/client';

export const SALE_CORE_FIELDS = `
  fragment SaleCoreFields on Sale {
    id
    name
    saleOn
    preBidOpenAt
    startAt
    endAt
    status
    bidding
    biddingNotice
    bidGroupChoiceId
    bidGroupBuyerId
    biddingFlash
    biddingFlashBackgroundColor
    biddingFlashForegroundColor
    biddingFlashFades
  }
`;

export const SALES = gql`
  ${SALE_CORE_FIELDS}
  query Sales {
    sales {
      ...SaleCoreFields
      saleAssets { id }
    }
  }
`;

export const SALE = gql`
  ${SALE_CORE_FIELDS}
  query Sale($id: ID!) {
    sale(id: $id) {
      ...SaleCoreFields
    }
  }
`;

export const REGISTER_FOR_SALE = gql`
  mutation RegisterForSale(
    $id: ID!
  ) {
    createSaleBuyerUser(
      id: $id
    ) {
      saleBuyerUser { id }
      errors { path, message }
    }
  }
`;
