import React, { useContext, useState, useEffect } from 'react';
import { View, TextInput, Pressable, Switch } from 'react-native';
import { useMutation } from '@apollo/client';
import webStyled from 'styled-components';
import styled from 'styled-components/native';
import colors from '../../styles/colors';
import { RECORD_USER_ACTIVITY } from '../../hooks/useSubscriptions/queries';
import MessageInputContext from '../../contexts/MessageInputContext';
import { useLocation } from 'react-router-dom';

import { CREATE_MESSAGE } from './queries';

const Input = styled(TextInput)`
  background-color: white;
  outline-width: 0px;
  outline-color: white;
  width: 100%;
`;

const ButtonContainer = webStyled.div`
  background-color: white;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
  flex-direction: row;
`;

const Container = webStyled.div`
  margin: 10px;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

const SendButton = webStyled.div`
  background-color: ${colors.elRed};
  margin: 10px;
  align-items: center;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  shadow-offset: 2px 2px;
  shadow-opacity: 0.3;
  shadow-radius: 8px;
  float:right;
  cursor: pointer;
`;

const SendLabel = webStyled.div`
  color: white;
  font-size: 18px;
  font-weight: bold;
`;

const Form = webStyled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.border};
`;

const FormContainer = ({ onEnter, children, style }) => {
  useEffect(() => {
    const handleKeyUp = (e) => {
      if ((13 === e.keyCode || 'Enter' === e.key) && onEnter) {
        onEnter();
      }
    }

    window.document.addEventListener('keyup', handleKeyUp);

    return () => {
      window.document.removeEventListener('keyup', handleKeyUp);
    }
  }, [onEnter]);

  return (
    <Container>{children}</Container>
  )
};

const Compose = ({ conversation, refetch, minimal }) => {
  const [isImportant, setIsImportant] = useState(false);
  const conversationContext = useContext(MessageInputContext);
  const { body, setBody } = conversationContext;
  const location = useLocation();

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [recordUserActivity] = useMutation(RECORD_USER_ACTIVITY);

  const updateBody = value => {
    recordUserActivity({
      variables: {
        conversationId: conversation?.id,
        activity: 'typing',
        text: value
      }
    });

    setBody(value);
  };

  const handleSubmit = () => {
    const fromPaddleService = location['pathname'].match(/\/bidding\//);

    const variables = {
      body,
      conversationId: conversation?.id,
      source: 'Buyer',
      fromPaddleService: (fromPaddleService && fromPaddleService.length > 0) || false
    };

    if (isImportant) {
      variables.flags = 'important';
    }

    if(body && body.length && body !== '\n') {
      createMessage({
        variables,
        onCompleted: () => {
          setBody('');
          setIsImportant(false);
          if (refetch) { refetch();
        }}
      });
    }
  };


  return (
    <FormContainer onEnter={handleSubmit}>
      {
        <Form>
          <Input
            type="text"
            value={body}
            onChangeText={updateBody}
            multiline={true}
            numberOfLines={1}
          />

          <ButtonContainer>
            <SendButton onClick={handleSubmit}>
              <SendLabel>Send</SendLabel>
            </SendButton>
          </ButtonContainer>
        </Form>
      }
    </FormContainer>
  );
};

export default Compose;
