import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import DataState from 'src/components/DataState';
import AuthContext from 'src/contexts/AuthContext';
import { formatAuthErrors } from 'src/utils/errorHelpers';

import { ACCEPT_INVITATION } from './queries';
import { passwordSchema } from './schemas';
import {
  Container,
  InnerContainer,
  Title,
  Label,
  Input,
  Button,
  Instructions,
  ErrorMessage
} from './elements';

const AcceptInvite = ({ token }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { updateCredentials } = useContext(AuthContext);

  const [acceptInvitation] = useMutation(ACCEPT_INVITATION, {
    onCompleted: data => {
      updateCredentials(data.buyerUserAcceptInvitation.credentials);
      window.location.href = '/account/billing'
    },
    onError: error => {
      setErrorMessage(formatAuthErrors(error.graphQLErrors));
      setFormSubmitting(false);
    }
  });

  const handleSubmit = values => {
    setFormSubmitting(true);
    acceptInvitation({ variables: values });
  };

  return (
    <>
      <Helmet>
        <title>Setup Password</title>
      </Helmet>
      <Container>
        <InnerContainer>
          <Title>Sign Up</Title>
          <Instructions>
            Password must be a minimum of 8 characters
          </Instructions>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

          {formSubmitting ? <DataState.Loading /> : (
            <Formik
              initialValues={{
                invitationToken: token,
                password: '',
                passwordConfirmation: ''
              }}
              validationSchema={passwordSchema}
              onSubmit={handleSubmit}
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <Label>Create a Password</Label>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <Input
                    type="password"
                    name="passwordConfirmation"
                    placeholder="Confirm Password"
                  />
                  <Button type="submit">
                    Set Password &amp; Sign In
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </InnerContainer>
      </Container>
    </>
  )
}

export default AcceptInvite;
