import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from './logo.avif';

import AuthContext from 'src/contexts/AuthContext';
import { headerGray } from 'src/styles/colors';
import { elRed } from 'src/styles/colors';
import { Dimensions } from 'react-native-web';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Dropdown = styled.div`
  z-index: 9999;
  position: fixed;
  width: 269px;
  right: 10px;
  top: 100px;
  background-color: white;
  border: 1px solid ${elRed};
  border-radius: 6px;

  @media (max-width: 768px) {
    top: 180px;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 80px;
  z-index: 999999;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 480px) {
    > img {
      width: 90%;
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  min-height: 80px;
  z-index: 999999;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 480px) {
    > img {
      width: 90%;
    }
  }
`;

const MenuLinkContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 0.1;
`;

const logoStyle = {
  height: '75%'
}

const LinkContainer = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
`;

const MobileLinkContainer = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
`;

const RegisterButtonContainer = styled.div`
  flex: 0.25
`;

const RegisterButton = styled.div`
  background-color: red;
  border-radius: 8px;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const linkStyle = {
  textDecoration: 'none',
  color: smallScreen ? '#fff' : '#000'
}

const Menu = ({showMenu, setShowMenu}) => {
  const { logout } = useContext(AuthContext);

  return (
    (showMenu &&
      <>
        {smallScreen ?
          <MobileContainer>
            <MobileLinkContainer>
              {
                <>
                  <Link
                    to="/account/profile"
                    style={linkStyle}
                    className='menuLink'
                    onClick={() => setShowMenu(false)}
                  >
                      Profile
                  </Link>
                  <Link
                    to="/account/billing"
                    style={linkStyle}
                    className='menuLink'
                    onClick={() => setShowMenu(false)}
                  >
                    Billing
                  </Link>
                  <Link
                    to="/inventory/watch"
                    style={linkStyle}
                    className='menuLink'
                    onClick={() => setShowMenu(false)}
                  >
                    Watch List
                  </Link>
                  <Link
                    to="/account/password"
                    style={linkStyle}
                    className='menuLink'
                    onClick={() => setShowMenu(false)}
                  >
                    Password
                  </Link>
                  <Link
                    to="/"
                    style={linkStyle}
                    className='menuLink'
                    onClick={() => logout()}
                  >
                    Logout
                  </Link>
                </>
              }
            </MobileLinkContainer>
          </MobileContainer> :
          <Dropdown>
            <Container>
              <LinkContainer>
                {
                  <>
                    <Link
                      to="/account/profile"
                      style={linkStyle}
                      className='menuLink'
                      onClick={() => setShowMenu(false)}
                    >
                        Profile
                    </Link>
                    <Link
                      to="/account/billing"
                      style={linkStyle}
                      className='menuLink'
                      onClick={() => setShowMenu(false)}
                    >
                      Billing
                    </Link>
                    <Link
                      to="/inventory/watch"
                      style={linkStyle}
                      className='menuLink'
                      onClick={() => setShowMenu(false)}
                    >
                      Watch List
                    </Link>
                    <Link
                      to="/account/password"
                      style={linkStyle}
                      className='menuLink'
                      onClick={() => setShowMenu(false)}
                    >
                      Password
                    </Link>
                    <Link
                      to="/"
                      style={linkStyle}
                      className='menuLink'
                      onClick={() => logout()}
                    >
                      Logout
                    </Link>
                  </>
                }
              </LinkContainer>
            </Container>
          </Dropdown>
        }
      </>
    )
  );
};

export default Menu;
