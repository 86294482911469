import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25%;

  @media (max-width: 768px) {
    width: 10%;

  }
`;

const NumberStyle = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 16px;
    font-size: 20px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    font-size: 14px;
  }
`;

const WordStyle = styled.div`
  font-size: 10px;
  display: flex;
  justify-content: center;
  font-weight: 600;
`;

const DateTimeDisplay = ({ value, type }) => {
  if(value < 10) {
    value = `0${value}`;
  }
  return (
    <Container>
      <NumberStyle>{value}</NumberStyle>
      <WordStyle>{type}</WordStyle>
    </Container>
  );
};

export default DateTimeDisplay;