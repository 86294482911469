import React from 'react';
import styled from 'styled-components';
// import hero from './inventoryHero.png';
import truck from './truck-rounded.png';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  ImageContainer,
} from './elements';

export const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  background-size: 25%;
`;

const InventoryHeader = ({homeIndex}) => {
  return (
    <Container>
      {getContent()}
    </Container>
  )

  function getContent() {
    if(homeIndex) {
      return <></>
    }else {
      return (
        <ColumnContainer>
          <LeftColumn>
            <TextContainer>
              <HeaderLabel>
                Inventory
              </HeaderLabel>
              <RedSeparator />
            </TextContainer>
          </LeftColumn>
          <ImageContainer>
            <img src={truck} alt='Equiplinc Locations'
              style={{
                width: '100%',
              }}
            />
          </ImageContainer>
        </ColumnContainer>
      )
    }
  }
}

export default InventoryHeader;