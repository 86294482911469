import React, {useState} from 'react';
import {
  Pressable, SafeAreaView, ScrollView, View, Dimensions, Modal, Text
} from 'react-native'
// import {
//   DefaultModal,
//   CenteredView,
//   ModalView,
//   ModalContainer,
//   ModalHeader,
//   HeaderText,
//   ModalContent,
//   CancelX,
// } from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/native';
import { elRed, elGreen } from 'src/styles/colors';

export const DefaultModal = styled(View)`

`

export const CenteredView = styled(View)`
flex:1;
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,0.5);
`

export const ModalView = styled(Pressable)`
width: fit-content;
height: fit-content;
max-height: 99vh;

background-color: white;
border-radius: 5;
border: 2px solid black;
shadow-color: #000;
shadow-offset: {
  width: 0,
  height: 2,
}
shadow-opacity: 0.25;
shadow-radius: 4;
elevation: 5;
cursor: default;
max-width: 75%;
`;

export const ModalHeader = styled(View)`
width: 100%;
height: 25px;
flex-direction: row;
background-color: ${elRed};
padding-left: 15px;
padding-right: 10px;
margin-bottom: 20px;
justify-content: space-between;
`;

export const ModalContent = styled(View)`
margin-left: 20px;
margin-right: 20px;
min-width: 600px;
`;

export const CancelX = styled(Text)`
font-weight: bold;
font-size: 20px;
color: white;
`;

export const BoldText = styled(Text)`
font-weight: bold;
`;

export const HeaderText = styled(Text)`
color: white;
font-size: 20px;
font-weight: bold;
`;

export const ModalContainer = styled(Pressable)`
  width: ${props => props.width};
  position: fixed;
  top: 40vh;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
`;

const AnnouncementModal = props => {
  const {
    showModal,
    setShowModal,
    modalType,
    children,
    objectName,
  } = props;
  const screenHeight = Dimensions.get('window').height - 100

  return (
    <ModalContainer
      // onPress={() => setShowModal(false)}
    >
      <DefaultModal
        onRequestClose={() => setShowModal(false)}
        visible={showModal}
        transparent={true}
      >
        <CenteredView>
          <ModalView
            onPress={() => false}
          >
            <ModalHeader>
              <HeaderText>
                {`${modalType}`}{`${objectName ? ' - ' + objectName : ''}`}
              </HeaderText>
              {setShowModal && (
                <Pressable>
                  <CancelX>
                    <FontAwesomeIcon
                      icon={faSquareXmark}
                      size='lg'
                      style={{color: '#fff'}}
                      onClick={() => setShowModal(false)}
                    />
                  </CancelX>
                </Pressable>
              )}
            </ModalHeader>
            <ModalContent>
              {/* {getContent()} */}
              <View style={{ height: "auto", maxHeight: screenHeight, flex: 1 }}>
                <SafeAreaView>
                  <ScrollView contentContainerStyle={{flexGrow: 1}} nestedScrollEnabled={true}>
                    { children }
                  </ScrollView>
                </SafeAreaView>
              </View>
            </ModalContent>
          </ModalView>
        </CenteredView>
      </DefaultModal>
    </ModalContainer>
  )
}

export const AcceptModal = props => {
  const {
    showModal,
    setShowModal,
    modalType,
    children,
    objectName,
  } = props;
  const screenHeight = Dimensions.get('window').height - 100

  return (
    <ModalContainer
      // onPress={() => setShowModal(false)}
    >
      <DefaultModal
        onRequestClose={() => setShowModal(false)}
        visible={showModal}
        transparent={true}
      >
        <CenteredView>
          <ModalView
            onPress={() => false}
          >
            <ModalHeader>
              <HeaderText>
                {`${modalType}`}{`${objectName ? ' - ' + objectName : ''}`}
              </HeaderText>
            </ModalHeader>
            <ModalContent>
              {/* {getContent()} */}
              <View style={{ height: "auto", maxHeight: screenHeight, flex: 1 }}>
                <SafeAreaView>
                  <ScrollView contentContainerStyle={{flexGrow: 1}} nestedScrollEnabled={true}>
                    { children }
                  </ScrollView>
                </SafeAreaView>
              </View>
            </ModalContent>
          </ModalView>
        </CenteredView>
      </DefaultModal>
    </ModalContainer>
  )
}

export default AnnouncementModal;
