import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import AuthContext from 'src/contexts/AuthContext';
import { Text } from 'src/styles';
import { USER_ACTIVITIES } from 'src/hooks/useSubscriptions/queries';

const ActivityDetail = ({ activity, conversation }) => {
  const { currentUser } = useContext(AuthContext);
  const [time, setTime] = useState(Date.now());

  // Refresh timeAgo every second
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (conversation.id !== activity?.conversationId) { return null; }
  if (!currentUser) { return null; }
  if (currentUser.id === activity?.userId) { return null; }

  const description = 'typing' === activity?.activity ?
    `is typing...` :
    'has this conversation open';

  // const timeAgo = DateTime.fromISO(activity.time).toRelative();

  return (
    <Text>
      {activity?.userName || 'User'} {description}
    </Text>
  );
};

const UserActivity = ({ conversation }) => {
  const { data, ...queryStatus } = useQuery(USER_ACTIVITIES);

  return (
    <>
      <ActivityDetail
        key={data?.userActivities.length ? data?.userActivities[data?.userActivities.length - 1].id : 'user-activity'}
        activity={data?.userActivities[data?.userActivities.length - 1]}
        conversation={conversation}
      />
    </>
  );
};

export default UserActivity;
