import styled from "styled-components";
import * as Colors from '../../styles/colors';

export const MainContainer = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 40px;
  background-color: #000;

  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media(min-width: 768) {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 80px;
    height: 300px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 90px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 40% 10% 10% 40% );
  }
`;

export const Container1 = styled.div`

`;

export const Container2 = styled.div`

`;

export const Container3 = styled.div`

`;

export const Container4 = styled.div`

`;

export const ContainerLabel = styled.div`
  font-weight: bold;
  color: #fff;
  font-weigth: 1em;
  margin-bottom: 20px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RegularText = styled.div`
  color: #fff;
`;

export const SocialIcons = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-top: 25px;
`;

export const BottomContainer = styled.div`
  color: ${Colors.elLightGray};
  font-size: 14px;
  border-top: 1px solid ${Colors.elLightGray};
  margin-top: 40px;
`;