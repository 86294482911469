import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const SALE_FIELDS = `
  fragment SaleFields on Sale {
    id
    name
    saleOn
    startAt
    bidding
  }
`;

const ASSET_FIELDS = `
  fragment AssetFields on Asset {
    id
    category
    subCategory
    vin
    year
    make
    model
    lotNumber
    stockNumber
    miles
    engineType
    primaryPhotoUrl
    listPrice
    showListPrice
    horsepower
    segment
    name
    activeSaleAsset {
      id
      sale {
        id
        bidding
        saleOn
      }
    }
  }
`;

export const NEXT_SALES = gql`
  ${SALE_FIELDS}
  query Sales {
    sales(scopes: ["month_upcoming"]) {
      ...SaleFields
    }
  }
`;

export const FEATURED_ASSETS = gql`
  ${ASSET_FIELDS}
  query Assets {
    assets(scopes: ["featured"]) {
      ...AssetFields
    }
  }
`;

export const NEW_ARRIVALS = gql`
  query Assets {
    assets(scopes: ["new_arrivals"]) {
      id
      name
      primaryPhotoUrl
    }
  }
`;