import React, { useContext} from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { elRed } from 'src/styles/colors';
import styled from 'styled-components';
import truck from './truck.png';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  background-image: url(${truck});
  background-repeat: no-repeat;

  @media(max-width: 768px) {
    background-size: 160%;
  }

  @media(min-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    margin-top: 60px;
    margin-bottom: 60px;
    border-radius: 10px !important;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    background-size: 300%;
  }
`;

const LargeText = styled.div`
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  color: #fff;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;

  @media(min-width: 768px) {
    font-size: 70px;
    line-height: 80px;
  }
`;

const SmallText = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-left: 2%;
  margin-right: 2%;

  @media(min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    width: 45%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const ButtonContainer = styled(Link)`
  background-color: ${elRed};
  width: fit-content;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 30px;

  @media(min-width: 768px) {
    font-size: 18px;
    padding: 20px;
  }
`;

const CTA = ({}) => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Container id='cta-container'>
      <LargeText>
        Unlock Possibilities:<br/>
        Bid and Shine!
      </LargeText>
      <SmallText>
        EquipLinc Auction Group provides a wide range of Remarketing Services
        as it relates to the Heavy Truck, Equipment, & Commercial Asset
        businesses respectively.
      </SmallText>
      {isLoggedIn &&
        <ButtonContainer
          to='/account/profile'
          style={{cursor: 'pointer', textDecoration: 'none'}}
        >
          Register To Bid In Next Auction
        </ButtonContainer>
      }
    </Container>
  )
}

export default CTA;
