import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';
import webStyled from 'styled-components';

import { Text } from 'src/styles';
import * as colors from 'src/styles/colors';
import SoldBanner from './sold-banner.svg';

export const Container = webStyled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  height: 100vh;
  gap: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    min-height: 1200px;
    flex-direction: column;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    max-height: 800px;
    flex-direction: column;
  }
`;

export const ContainerWithFlash = styled(View)`
`;

export const Column = webStyled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0px;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0px;
  background-color: #fff;
  border-radius: 10px;

  @media (max-width: 768px) {
    min-height: 800px;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
    overflow: scroll;
    max-height: 505px;
  }
`;

export const SmallColumn = webStyled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0px;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0px;
  flex: 0.45;

  @media (max-width: 768px) {
    overflow: scroll;
    min-height: 400px;
  }

  @media (min-width: 800px) {
    min-height: 600px;
  }


`;

export const BorderedContainer = styled(View)`
  flex: 1;
  padding: 10px;
  border-color: ${colors.elGray};
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  overflow: auto;
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;

export const Subtitle = styled(Text)`
  font-size: 20px;
`;

export const NoticeContainer = webStyled.div`
  display: flex;
  background-color: ${colors.elRed};
  min-height: 40px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-around;

  @keyframes blink-then-fade {
    0% { opacity: 0; }
    8% { opacity: 1; }
    16% { opacity: 0; }
    24% { opacity: 1; }
  }

  animation-duration: 6s;
  animation-name: blink-then-fade;

  opacity: 1
`;

export const NoticeText = styled(Title)`
  color: white;

  margin-left: 10px;
  margin-right: 10px;
`;

export const Split = styled(View)`
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom-color: black;
  border-bottom-width: 2px;
  border-bottom-style: solid;
`;

export const SaleAssetContainer = styled(View)`
  min-height: 100px;
  flex-direction: row;
  background-color: ${props => props.active ?
    colors.elGreen :
    props.even ? colors.backgroundGray : '#fff'
  };
  background-image: ${props => props.sold ? `url(${SoldBanner})` : ''};
  background-repeat: no-repeat;
  background-position: right;
  padding: 20px 0px 20px 0px;
  border-style: solid;
  gap: 10px;
  border-color: ${props => (
    props.highlight || (props.groupPosition ? colors.elRed : 'white')
  )};
  ${props => 'first' === props.groupPosition && (
    'border-bottom-color: white;'
  )}
  ${props => 'last' === props.groupPosition && (
    'border-top-color: white; padding-bottom: 0px; margin-bottom: 10px;'
  )}
  ${props => 'middle' === props.groupPosition && (
    'border-top-color: white; border-bottom-color: white;'
  )}
`;

export const StyledTextInput = webStyled.input`
  flex: 1;
`;

export const Field = styled(View)`
  flex-direction: row;
`;

export const Label = styled(Text)`
  margin-right: 10px;
  align-self: center;
`;

export const Value = styled(Text)`
  font-weight: 600;
`;

export const Going = styled(View)`
  background-color: orange;
  padding: 10px;
`;

export const GoingText = styled(Subtitle)`
  font-weight: 900;
  color: white;
`;

export const GOING_BUTTONS = [
  { status: 'Going Once', color: 'darkorange' },
  { status: 'Going Twice', color: 'purple' },
];

export const goingColor = saleAsset => (
  GOING_BUTTONS.find(({ status }) => status === saleAsset.biddingStatus)?.color
);

const ButtonContainer = styled(Pressable)`
  background-color: ${colors.elGreen}
  flex: 1;
  padding: 10px;
  align-items: center;
  justify-content: space-around;
`;

const ButtonLabel = styled(Text)`
  color: white;
  font-weight: 700;
`;

export const Button = ({ title, onPress, color, style, disabled }) => {
  if (disabled) {
    onPress = () => {};
    color = colors.elGray;
  }

  return (
    <ButtonContainer
      onPress={onPress}
      style={{...style, backgroundColor: (color || colors.elGreen)}}
    >
      <ButtonLabel>{title}</ButtonLabel>
    </ButtonContainer>
  );
};

export const DetailContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;

  gap: 10px;
  margin-left: 5%;
  margin-right: 5%;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const DetailTitle = styled(Text)`
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  @media(min-width: 768px) {
    font-size: 70px;
    line-height: 69px;
  }
`;

export const DetailSubtitle = styled(Text)`

`;

export const BlackBackground = webStyled.div`
  background: black;
  background: -moz-linear-gradient(top, black 905px, white 20vh);
  background: -webkit-linear-gradient(left, black 905px, white 20vh);
  background: linear-gradient(to bottom, black 905px, white 20vh);

  @media (min-width: 768px) {
    background: black;
    background: -moz-linear-gradient(top, black 50vh, white 20vh);
    background: -webkit-linear-gradient(left, black 50vh, white 20vh);
    background: linear-gradient(to bottom, black 50vh, white 20vh);
  }
`;


