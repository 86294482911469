import React, { useEffect } from 'react';
import styled from 'styled-components';

import Input from 'src/elements/inputs';
import colors from 'src/styles/colors';

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  .form-field {
    flex: 1;
    margin-bottom: 20px;

    .form-field-inner {
      display: flex;
      flex-direction: column !important;
    }

    .field-label {
      display: flex;
      flex-direction: row;
      margin-bottom: 6px;
      gap: 20px;
    }

    .field-label > div {
      font-size: 14px;
      color: ${colors.elRed};
      font-weight: 500;
    }

    .field-error {
      padding-left: 0px !important;
      color: black !important;
      float: right;
    }

    input {
      display: block;
      margin-top: 0px !important;
      margin-left: 0px !important;
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 10px;
    }
  }
`;

export const FormContainer = ({ onEnter, children, style }) => {
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (13 === e.keyCode && onEnter) {
        onEnter();
      }
    }

    window.document.addEventListener('keyup', handleKeyUp);

    return () => {
      window.document.removeEventListener('keyup', handleKeyUp);
    }
  }, [onEnter]);

  return (
    <Container style={style}>{children}</Container>
  )
};

const nameToLabel = name => (
  name.match(/^[a-z]+|[A-Z][a-z]*/g).map(word => (
    word[0].toUpperCase() + word.substr(1).toLowerCase()
  )).join(' ')
);

export const defaultValues = ({ fields, resource }) => {
  const values = {};

  if (resource) {
    values.id = resource.id;
  }

  Object.keys(fields).forEach(name => {
    if (name.endsWith('Id')) {
      const associationName = name.substring(0, name.length - 2)
      values[name] = (resource || {})[associationName]?.id || '';
    } else if (resource?.[name] && 'object' === typeof resource[name]) {
      values[name] = resource[name].formatted;
    } else {
      values[name] = (resource || {})[name] || '';
    }
  });

  return values;
};

export const prepareInput = (input, fields) => {
  Object.keys(fields).forEach(name => {
    const field = fields[name];

    if ('Int' === field.type) {
      input[name] = '' === input[name] ? null : parseInt(input[name]);
    }

    if ('null' === field.blank && !input[name]) {
      input[name] = null
    }
  });

  return input;
};

const DEFAULT_OPTION = { value: '', label: 'Select' };

export const Fields = ({ fields, control, errors }) => {
  return (
    Object.keys(fields).map(name => {
      const field = fields[name];

      if (field.select) {
        let options = field.select;

        if (!options[0].value && !options[0].label) {
          options = [
            DEFAULT_OPTION,
            ...options.map(option => ({ value: option, label: option }))
          ];
        }

        return (
          <Input.Picker
            label={field.label || nameToLabel(name)}
            name={name}
            key={name}
            rules={field.rules}
            options={options || []}
            control={control}
            errors={errors}
          />
        );
      } else if ('date' === field.type) {
        return (
          <Input.Date
            label={field.label || nameToLabel(name)}
            name={name}
            key={name}
            rules={field.rules}
            control={control}
            errors={errors}
          />
        );
      } else if ('textarea' === field.type) {
        return (
          <Input.TextArea
            label={field.label || nameToLabel(name)}
            name={name}
            key={name}
            hint={field.hint}
            rules={field.rules}
            control={control}
            errors={errors}
            style={field.style}
            {...field.inputProps}
          />
        );
      } else {
        return (
          <Input.Text
            label={field.label || nameToLabel(name)}
            name={name}
            key={name}
            hint={field.hint}
            rules={field.rules}
            control={control}
            errors={errors}
            style={field.style}
            {...field.inputProps}
          />
        );
      }
    })
  );
};
