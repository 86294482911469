import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import '../../styles/styles.css';
import { Link } from 'react-router-dom';

import { Loading } from 'src/components/DataState';
import * as Colors from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { USERS_WITH_COUNT } from 'src/contexts/ResourcesContext';
import { Dimensions } from 'react-native-web';
const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1024;

const Dropdown = styled.div`
  z-index: 9999;
  position: absolute;
  width: fit-content;
  right: 10.5%;
  top: 120px;
  background-color: white;
  border: 1px solid ${Colors.elRed};
  border-radius: 6px;

  @media (max-width: 768px) {
    top: 180px;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 80px;
  z-index: 999999;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 480px) {

  }
`;

const MobileContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  z-index: 999999;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  overflow-y: scroll;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const UserRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: ${smallScreen ? "-10px" : "0px"}
`;

const WebContactsButton = styled.div`
  padding: 20px;
  color: #fff;
  background-color: ${Colors.elRed};
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  font-size: ${dimensions.width < 1600 ? '16px' : '18px'}
`;

const Contacts = ({users}) => {
  function formatContacts() {
    return (
      users.map(user => {
        return (
          <UserRow>
            <div>
              {`${user.fullName} - ${user.title}`}
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: smallScreen ? '0px' : '10px',
                }}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size={smallScreen ? 'sm' : 'lg'}
                  style={{color: Colors.elRed, marginRight: '10px'}}
                />
                <div>
                  <a href={`mailto:${user.email}`}
                    style={{color: smallScreen ? '#fff' : Colors.elRed,
                      fontWeight: 500
                    }}
                  >
                    {user.email}
                  </a>
                </div>
              </div>
            </div>
          </UserRow>
        )
      })
    )
  }
  if(smallScreen || mediumScreen) {
    return (
      <MobileContainer>
        {formatContacts()}
      </MobileContainer>
    )
  }else {
    return (
      <Dropdown>
        <Container>
          {formatContacts()}
        </Container>
      </Dropdown>
    )
  }
}

const WebContacts = ({showWebContacts, setShowWebContacts}) => {
  const [queryVariables, setQueryVariables] = useState({
    filterColumns: [],
    filterNames: [],
    scopes: ['web_contacts'],
  });

  const { data, loading } = useQuery(USERS_WITH_COUNT,
    {variables: queryVariables}
  );

  return (
    <>
      <WebContactsButton
        onClick={() => setShowWebContacts(!showWebContacts)}
      >
        Contacts
      </WebContactsButton>
      {showWebContacts && data &&
        <Contacts users={data?.usersWithCount?.entries} />
      }
    </>
  );


};

export default WebContacts;