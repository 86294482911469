import { Link } from 'react-router-dom';
import styled from 'styled-components/native';
import { useField, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { View, Text, TextInput, Modal, Pressable } from 'react-native';

import {
  elRed,
  elGray
} from '../../styles/colors';

export const DefaultModal = styled(Modal)`

`

export const CenteredView = styled(View)`
flex:1;
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,0.5);
`

export const ModalView = styled(Pressable)`
width: fit-content;
height: fit-content;
max-height: 99vh;
background-color: white;
border-radius: 5;
border: 2px solid black;
shadow-color: #000;
shadow-offset: {
  width: 0,
  height: 2,
}
shadow-opacity: 0.25;
shadow-radius: 4;
elevation: 5;
cursor: default;
max-width: 75%;
`;

export const ModalHeader = styled(View)`
width: 100%;
height: 25px;
flex-direction: row;
background-color: red;
padding-left: 15px;
padding-right: 10px;
margin-bottom: 20px;
justify-content: space-between;
`;

export const ModalContent = styled(View)`
margin-left: 20px;
margin-right: 20px;
min-width: 600px;
overflow-y: scroll;
`;

export const CancelX = styled(Text)`
font-weight: bold;
font-size: 20px;
color: white;
`;

export const BoldText = styled(Text)`
font-weight: bold;
`;

export const HeaderText = styled(Text)`
color: white;
font-size: 20px;
font-weight: bold;
`;

export const ModalContainer = styled(Pressable)`

`;

export const UsersModal = styled(Modal)`
  width: 200px;
`;