export const abbreviations = [
  'AK','AL','AR','AS','AZ','CA','CO','CT','DC','DE','FL','FM','GA','GU','HI',
  'IA','ID','IL','IN','KS','KY','LA','MA','MD','ME','MH','MI','MN','MO','MP',
  'MS','MT','NC','ND','NE','NH','NJ','NM','NV','NY','OH','OK','OR','PA','PR',
  'RI','SC','SD','TN','TX','UT','VA','VI','VT','WA','WI','WV','WY'
]

export const statesList = {
  'AK': { label: 'Alaska', region: 'West' },
  'AL': { label: 'Alabama', region: 'South' },
  'AR': { label: 'Arkansas', region: 'South' },
  'AZ': { label: 'Arizona', region: 'West' },
  'CA': { label: 'California', region: 'West' },
  'CO': { label: 'Colorado', region: 'West' },
  'CT': { label: 'Connecticut', region: 'Northeast' },
  'DC': { label: 'Washington DC', region: 'South' },
  'DE': { label: 'Delaware', region: 'South' },
  'FL': { label: 'Florida', region: 'South' },
  'GA': { label: 'Georgia', region: 'South' },
  'HI': { label: 'Hawaii', region: 'West' },
  'IA': { label: 'Iowa', region: 'Midwest' },
  'ID': { label: 'Idaho', region: 'West' },
  'IL': { label: 'Illinois', region: 'Midwest' },
  'IN': { label: 'Indiana', region: 'Midwest' },
  'KS': { label: 'Kansas', region: 'Midwest' },
  'KY': { label: 'Kentucky', region: 'South' },
  'LA': { label: 'Louisiana', region: 'South' },
  'MA': { label: 'Massachusetts', region: 'Northeast' },
  'MD': { label: 'Maryland', region: 'South' },
  'ME': { label: 'Maine', region: 'Northeast' },
  'MI': { label: 'Michigan', region: 'Midwest' },
  'MN': { label: 'Minnesota', region: 'Midwest' },
  'MO': { label: 'Missouri', region: 'Midwest' },
  'MS': { label: 'Mississippi', region: 'South' },
  'MT': { label: 'Montana', region: 'West' },
  'NC': { label: 'North Carolina', region: 'South' },
  'ND': { label: 'North Dakota', region: 'Midwest' },
  'NE': { label: 'Nebraska', region: 'Midwest' },
  'NH': { label: 'New Hampshire', region: 'Northeast' },
  'NJ': { label: 'New Jersey', region: 'Northeast' },
  'NM': { label: 'New Mexico', region: 'West' },
  'NV': { label: 'Nevada', region: 'West' },
  'NY': { label: 'New York', region: 'Northeast' },
  'OH': { label: 'Ohio', region: 'Midwest' },
  'OK': { label: 'Oklahoma', region: 'South' },
  'OR': { label: 'Oregon', region: 'West' },
  'PA': { label: 'Pennsylvania', region: 'Northeast' },
  'RI': { label: 'Rhode Island', region: 'Northeast' },
  'SC': { label: 'South Carolina', region: 'South' },
  'SD': { label: 'South Dakota', region: 'Midwest' },
  'TN': { label: 'Tennessee', region: 'South' },
  'TX': { label: 'Texas', region: 'South' },
  'UT': { label: 'Utah', region: 'West' },
  'VA': { label: 'Virginia', region: 'South' },
  'VT': { label: 'Vermont', region: 'Northeast' },
  'WA': { label: 'Washington', region: 'West' },
  'WI': { label: 'Wisconsin', region: 'Midwest' },
  'WV': { label: 'West Virginia', region: 'South' },
  'WY': { label: 'Wyoming', region: 'West' }
}