import React, { useContext } from 'react';
import BlogContext from 'src/contexts/BlogContext';
import {
  CardContainer,
  FeaturedCardContainer
} from './elements';
import FeaturedCard from './FeaturedCard';
import { Text } from 'react-native';
import { elRed, elGray } from 'src/styles/colors';
import { View, Pressable } from 'react-native';
import { useNavigate } from 'react-router-dom';

const Featured = ({ refetch }) => {
  const { featured, featuredLabel, allBlogTags, setCurrentBlogTag } = useContext(BlogContext);
  const navigate = useNavigate();
  const handleTagPress = (name) => {
    setCurrentBlogTag(name);
    navigate('/blog');
  }

  return (
    <View style={{border: `2px solid ${elGray}`, borderRadius: '10px'}}>
      <View
        style={{
          width: '100%',
          backgroundColor: elRed,
          alignItems: 'center',
          padding: '20px',
          borderTopLeftRadius: '7.5px',
          borderTopRightRadius: '7.5px'
        }}
      >
        <Text style={{fontSize: '30px', fontWeight: 700, color: '#fff'}}>
          {featuredLabel}
        </Text>
      </View>
      <FeaturedCardContainer>
        {featured?.length ?
          featured.map(blog =>
            <FeaturedCard blog={blog} refetch={refetch} />
          ) :
          null
        }
      </FeaturedCardContainer>
      <View
        style={{
          width: '100%',
          backgroundColor: elRed,
          alignItems: 'center',
          padding: '20px',
          borderBottomLeftRadius: '7.5px',
          borderBottomRightRadius: '7.5px'
        }}
      >
        <Text style={{fontSize: '20px', fontWeight: 700, color: '#fff'}}>
          Blog Categories
        </Text>

        <View
          style={{display: 'flex', gap: '10px', flexDirection: 'row',
            marginTop: '10px', flexWrap: 'wrap'
          }}
        >

          {allBlogTags?.map(tag => (
            <Pressable onPress={() => handleTagPress(tag.name)}>
              <Text
                style={{
                  fontSize: '20px', fontWeight: 700, backgroundColor: '#fff',
                  padding: '5px', borderRadius: '5px'
                }}
              >
                {tag.name}
              </Text>
            </Pressable>
          ))}
        </View>
      </View>
    </View>
  )
}

export default Featured;