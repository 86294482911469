import React from "react";
import AboutUsHeader from "../Headers/AboutUsHeader";
import styled from "styled-components";
import dotGrid from '../Headers/dot-grid.png';
import about from './about.png';
import { elRed } from "src/styles/colors";
import { Dimensions } from "react-native-web";

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`

  display: flex;
  max-width: 100%;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    background-image: url(${dotGrid});
    background-size: 20%;
    background-repeat: no-repeat;
    margin-top: 60px;
    margin-bottom: 60px;
    gap: 2.5%;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    background-image: url(${dotGrid});
    background-size: 20%;
    background-repeat: no-repeat;
    margin-top: 60px;
    margin-bottom: 60px;
    gap: 2.5%;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-end;
    padding-top: 50px;
    flex: 0.5;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;

  @media (min-width: 768px) {
    flex: 0.5;
    justify-content: flex-start;
    padding-top: 20px;
    padding-right: 40px;
  }
`;

const StyledHeader = styled.h2`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

const RegularText = styled.div`
  font-size: 16px;
`;

const StatGrid = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StatContainer = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 40px;
  }

`;

const RedText = styled.div`
  font-weight: 600;
  line-height: 30px;
  color: ${elRed};
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 50px;
  }

  @media (min-width: 1200px) {
    font-size: 50px;
    line-height: 80px;
  }
`;

const HomeAbout = () => {
  return (
    <Container>
      <ContentContainer>
        <LeftColumn>
          {/*
          <img src={about} alt='Equiplinc Locations'
            style={{
              maxWidth: '75%',
              maxHeight: '500px',
              width: 'auto',
              width: 'auto',
              objectFit: 'cover'
            }}
          />
          */}
        </LeftColumn>
        <RightColumn>
          <StyledHeader>About EquipLinc Auction Group</StyledHeader>
          <RegularText>
            EquipLinc Auction Group is a leading provider of remarketing
            services for heavy truck, equipment, and commercial asset
            businesses. With decades of experience in the Heavy Truck &
            Equipment Industry, our management team is dedicated to serving
            you, the customer, in all aspects.
            <br /><br />
            Our services include full remarketing of your commercial assets,
            transportation, storage, thorough inspection, title, mechanical,
            and reconditioning services, as well as asset acquisition and
            liquidation. We are committed to providing exceptional service and
            support to our customers, and we look forward to working with you.
          </RegularText>
          <StatGrid>
            <StatContainer>
              <RedText>
                85+
              </RedText>
              <RegularText>
                Years Of Experience
              </RegularText>
            </StatContainer>
            <StatContainer>
              <RedText>
                1000+
              </RedText>
              <RegularText>
                Inventory Items
              </RegularText>
            </StatContainer>
            <StatContainer>
              <RedText>
                100+
              </RedText>
              <RegularText>
                Successful Auctions
              </RegularText>
            </StatContainer>
            <StatContainer>
              <RedText>
                25+
              </RedText>
              <RegularText>
                Team Members
              </RegularText>
            </StatContainer>
          </StatGrid>
        </RightColumn>
      </ContentContainer>
    </Container>
  )
}

export default HomeAbout;


// const ContentContainer = styled.div`
//   display: flex;
//   max-width: 100%;
//   flex-direction: row;
//   background-image:
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}),
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}),
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}),
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}),
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}),
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}),
//     url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot}), url(${dot});
//   background-position:
//     5% 0%, 7% 0%, 9% 0%, 11% 0%, 13% 0%, 15% 0%, 17% 0%,
//     5% 3vh, 7% 3vh, 9% 3vh, 11% 3vh, 13% 3vh, 15% 3vh, 17% 3vh,
//     5% 6vh, 7% 6vh, 9% 6vh, 11% 6vh, 13% 6vh, 15% 6vh, 17% 6vh,
//     5% 9vh, 7% 9vh, 9% 9vh, 11% 9vh, 13% 9vh, 15% 9vh, 17% 9vh,
//     5% 12vh, 7% 12vh, 9% 12vh, 11% 12vh, 13% 12vh, 15% 12vh, 17% 12vh,
//     5% 15vh, 7% 15vh, 9% 15vh, 11% 15vh, 13% 15vh, 15% 15vh, 17% 15vh,
//     5% 18vh, 7% 18vh, 9% 18vh, 11% 18vh, 13% 18vh, 15% 18vh, 17% 18vh;
//   background-size: 5.6px 5.6px;
//   background-repeat: no-repeat;
//   margin-top: 60px;
//   margin-bottom: 60px;
//   gap: 2.5%;
// `;
