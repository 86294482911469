export default function formatAddress(street, city, state, zip) {
  let address = '';
  if(street) address += `${street}`;
  if(city || state || zip) address += "\n";
  if(city) address += `${city}`;
  if(state) address += `, ${state}`;
  if(zip) address += `, ${zip}`;
  return address;
}

export function formatAssetAddressLocationOr(asset) {
  let address = '';

  address += asset.location?.street || asset.street || '';
  if(address !== '') address += "\n";

  address += `${asset.location?.city || asset.city || ''}`;
  if((asset.location?.city || asset.city) && asset.location?.state || asset.state) {
    address += `, `
  }
  address += `${asset.location?.state || asset.state || ''} `;
  address += asset.location?.postalCode.formatted || asset.postalCode.formatted || '';

  return address;
}