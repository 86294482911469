import React, { useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';
import webStyled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark, faPlay } from '@fortawesome/free-solid-svg-icons';
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
  useParticipants,
  HMSNotificationTypes,
} from '@100mslive/react-sdk';

import { Text } from 'src/styles';
import * as colors from 'src/styles/colors';

import { Subtitle } from './elements';

export const Container = webStyled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const ButtonContainer = styled(Pressable)`
  background-color: ${colors.elGreen}
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  border-radius: 4px;
  min-width: 300px;
`;

function NewStream({ userName, roomCode, sale }) {
  const [connecting, setConnecting] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const hmsNotification = useHMSNotifications(HMSNotificationTypes.ERROR);
  const { participants: broadcasters } = useParticipants({
    role: 'broadcaster'
  });

  const activeStream = broadcasters.length > 0;

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions]);

  useEffect(() => {
    return () => {
      hmsActions.leave();
    }
  }, []);

  const unblock = async () => {
    setBlocked(true);
    await hmsActions.unblockAudio();
  };

  useEffect(() => {
    if (!hmsNotification) { return; }

    if (HMSNotificationTypes.ERROR === hmsNotification.type) {
      if ('AutoplayBlocked' === hmsNotification.data.name) {
        unblock();
      }
    };
  }, [hmsNotification]);

  const join = async (e) => {
    setConnecting(true);

    const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode })

    try {
      await hmsActions.join({ userName, authToken });
    } catch (e) {
      console.error(e)
    }
  };

  const leave = () => {
    setConnecting(false);
    hmsActions.leave();
  }

  let active = 'Audio Stream Not Active';
  let play = 'Click to Play Audio';
  let playing = 'Audio will play when Available';

  if (!isConnected) {
    active = '';
  } else if (activeStream) {
    active = 'Audio Stream Active';
    play = 'Click to Play Audio';
    playing = 'Playing Audio';
  }

  return (
    <Container>
      <Subtitle>{active}</Subtitle>
      {blocked ? (
        <>
          <Text>Audio has been blocked by your browser.</Text>
          <input
            type="button"
            value="Click to Unblock"
            onClick={() => {
              hmsActions.unblockAudio();
              setBlocked(false);
            }}
            style={{ fontSize: 20 }}
          />
        </>
      ) : (
        isConnected ? (
          <ButtonContainer onPress={leave}>
            <Text style={{ color: 'white' }}>{playing}</Text>
            <FontAwesomeIcon
              icon={faVolumeXmark}
              title="Stop Audio"
              color="white"
            />
          </ButtonContainer>
        ) : (
          <ButtonContainer
            onPress={join}
            style={{ backgroundColor: 'lightgray' }}
          >
            <Text>{connecting ? 'Connecting...' : play}</Text>
            <FontAwesomeIcon
              icon={faPlay}
              title="Play Audio"
            />
          </ButtonContainer>
        )
      )}
    </Container>
  );
}

export default NewStream;
