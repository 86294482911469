import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { LOCATIONS } from './queries';
import DataState from 'src/components/DataState';
import styled from 'styled-components';

import LocationsHeader from '../Headers/LocationsHeader';
import StateList from './StateList';
import Map from './Map';

const Container = styled.div`

`;

const ContentContainer = styled.div`
  min-width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

const MapContainer = styled.div`


`;

const StateListContainer = styled.div`

`;

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [groupedLocations, setGroupedLocations] = useState([]);
  const [stateKeys, setStateKeys] = useState([]);
  const { data, loading } = useQuery(LOCATIONS,
    {variables: { scopes: ['web_visible']}}
  );

  useMemo(() => {
    if(data && !stateKeys.length) {
      setLocations(data?.locations);
      let grouped = data?.locations.reduce((x, y) => {

        (x[y.state] = x[y.state] || []).push(y);

        return x;

    }, {});
      setGroupedLocations(grouped);
      let sorted = Object.keys(grouped).sort()
      setStateKeys(sorted);
    }
  }, [data])

  if(loading) return <DataState.Loading />

  return (
    <Container>
      <LocationsHeader />
      <ContentContainer>
        <MapContainer>
          <Map locations={locations} />
        </MapContainer>
        <StateListContainer>
          <StateList
            groupedLocations={groupedLocations}
            stateKeys={stateKeys}
          />
        </StateListContainer>
      </ContentContainer>
    </Container>
  )
}

export default Locations;
