import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from 'src/contexts/AuthContext';
import { Notice } from 'src/elements';

const AccountStatus = ({ onBilling }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) { return null; }
  if (!currentUser.buyer) { return null; }
  if (currentUser.buyer.isApproved) { return null; }

  if ('Review' === currentUser.buyer.status) {
    return (
      <Notice type="warning">
        Your account is currently being reviewed for approval to bid.
      </Notice>
    );
  } else {
    return (
      <Notice type="warning">
        You must provide billing information before we can review your account.
        {' '}
        {onBilling ? (
          'Please complete the form below.'
        ) : (
          <Link to="/account/billing">
            Click Here to finish setting up your account.
          </Link>
        )}
      </Notice>
    );
  }
};

export default AccountStatus;
