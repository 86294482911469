import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from './logo.avif';

import AuthContext from 'src/contexts/AuthContext';
import { headerGray } from 'src/styles/colors';
import { elRed } from 'src/styles/colors';
import { Dimensions } from 'react-native-web';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Dropdown = styled.div`
  z-index: 9999;
  position: absolute;
  width: 269px;
  left: -10px;
  top: 30px;
  background-color: white;
  border: 1px solid ${elRed};
  border-radius: 6px;
  padding-bottom: 0;

  @media (max-width: 768px) {
    top: 180px;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 80px;
  z-index: 999999;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 480px) {
    > img {
      width: 90%;
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  min-height: 80px;
  z-index: 999999;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  @media (max-width: 480px) {
    > img {
      width: 90%;
    }
  }
`;

const MenuLinkContainer = styled.div`
  display: flex;
  flex: 1;
`;

const LinkContainer = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
`;

const MobileLinkContainer = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
`;

const RegisterButtonContainer = styled.div`
  flex: 0.25
`;

const RegisterButton = styled.div`
  background-color: ${elRed};
  border-radius: 8px;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const linkStyle = {
  textDecoration: 'none',
  color: smallScreen ? '#fff' : '#000',
}

const AuctionMenu = ({showMenu, setShowMenu, isLoggedIn}) => {
  const { logout } = useContext(AuthContext);

  return (
    (showMenu &&
      <>
        {smallScreen ?
          <MobileContainer>
            <MobileLinkContainer>
              <Link
                to="/sales"
                style={linkStyle}
                className='menuLink'
                onClick={() => setShowMenu(false)}
              >
                Upcoming Auctions
              </Link>
              {!isLoggedIn &&
                <Link
                  to="/account/password"
                  style={linkStyle}
                  className='menuLink'
                  onClick={() => setShowMenu(false)}
                >
                  Register To Bid
                </Link>
              }
            </MobileLinkContainer>
          </MobileContainer> :
          <Dropdown>
            <Container>
              <LinkContainer>
                <Link
                  to="/sales"
                  style={linkStyle}
                  className='menuLink'
                  onClick={() => setShowMenu(false)}
                >
                  Upcoming Auctions
                </Link>
                {!isLoggedIn &&
                  <Link
                    to="/account/password"
                    style={linkStyle}
                    className='menuLink'
                    onClick={() => setShowMenu(false)}
                  >
                    Register To Bid
                  </Link>
                }
              </LinkContainer>
            </Container>
          </Dropdown>
        }
      </>
    )
  );
};

export default AuctionMenu;
